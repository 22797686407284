import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Booking,
    BookingStatus,
    DiscountType,
    memberToReferral,
    ProductOptionType,
    SourceType,
    useProduct,
    useUpdateBookingNotes,
    useUpdateBookingOptions,
    useUpdateBookingParticipants,
    useUpdateBookingSource,
} from '@travelity/api';
import { useSnackbar } from 'notistack';
import { useForm } from '@travelity/form';

import {
    type NoteVisibility,
    RouteItemType,
} from '@travelity/api/src/requests';
import ErrorDialog from '@travelity/ui/src/error-dialog/error-dialog';
import participantsSchema from '../booking-participants/booking-participants.schema';
import { BookingParticipantsForm } from '../booking-participants';
import financialInformationSchema from '../booking-financial-information/booking-financial-information.schema';
import { BookingFinancialInformationForm } from '../booking-financial-information';
import productSchema from '../booking-product/booking-product.schema';
import productOptionsSchema from '../booking-product-options/booking-product-options.schema';
import { BookingProductForm } from '../booking-product';
import scheduleSchema from '../booking-schedule/booking-schedule.schema';
import { BookingScheduleForm } from '../booking-schedule';
import notesSchema from '../booking-notes/booking-notes.schema';
import { BookingNotesForm } from '../booking-notes';
import { participantsToParticipantsForm } from '../booking-participants/booking-participants.converters';
import { useLayoutContext } from '../../layouts/main-layout/main-layout';
import { ActivityHistory } from '../activity-history';
import { BookingProductOptionsForm } from '../booking-product-options';
import { MoveBookingModal } from '../move-booking-modal';
import { SideFormAccordion } from '../side-form-accordion';
import { useSideFormAccordion } from '../side-form-accordion/side-form-accordion.hooks';
import { TravelerForm } from '../booking-participants/booking-participants.types';
import { useResourcePermissions } from '../../contexts/user';
import BookingSourceForm from '../booking-source-form/booking-source-form';
import sourceSchema from '../booking-source-form/booking-source.schema';

export interface BookingPreviewProps {
    booking: Booking;
    updateBooking?: () => void;
    closeForm?: () => void;

    // In case of booking under order
    siblingBookings: Booking[];
}

export const BookingPreview: React.FC<BookingPreviewProps> = React.memo(
    (props: BookingPreviewProps) => {
        const {
            booking,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            updateBooking,
            closeForm,
            siblingBookings,
        } = props;
        const { view, openTab, isEdit } = useSideFormAccordion();
        const permissions = useResourcePermissions('booking');
        const readonly =
            !permissions.update || booking.status === BookingStatus.CANCELLED;

        const [accessDeniedModal, setAccessDeniedModal] = useState(false);
        const { data: product } = useProduct(booking.product.id);

        // TODO handle siblings
        console.log(siblingBookings);
        const { enqueueSnackbar } = useSnackbar();
        const { mutate: updateParticipants, isLoading: isSavingParticipants } =
            useUpdateBookingParticipants(booking.participants.travelers || [], {
                onSuccess: () => {
                    view();
                },
                onError: (error: any) => {
                    if (error?.status === 409) {
                        enqueueSnackbar(
                            error.body.message || 'Failed to save',
                            {
                                variant: 'error',
                            }
                        );
                    } else if (error?.body) {
                        enqueueSnackbar(error.body, { variant: 'error' });
                    }
                },
            });

        const bookingId = booking.id;
        const onSubmit = useCallback(
            (data: any, e?: React.BaseSyntheticEvent) => {
                e?.preventDefault();
                const key = e?.target.id;
                if (key === 'participants') {
                    updateBooking?.();
                } else if (key === 'financialInformation') {
                    // update(
                    //     {
                    //         bookingId,
                    //         requestBody: {
                    //             financials: {
                    //                 price: {
                    //                     discount: data,
                    //                 },
                    //             },
                    //         },
                    //     },
                    //     {
                    //         onSuccess: () => {
                    //             updateBooking();
                    //         },
                    //     }
                    // );
                }
            },
            [bookingId]
        );
        const {
            Form: ScheduleForm,
            reset: resetSchedule,
            // formState: { errors: scheduleErrors },
        } = useForm({
            defaultValues: {
                start: booking.date.start,
            },
            onSubmit,
            mode: 'onChange',
            schema: scheduleSchema,
        });

        const startPickUp = useMemo(
            () =>
                booking.route?.items
                    .map(({ id, type, time, location }) => ({
                        id,
                        type,
                        name: location.name,
                        time,
                    }))
                    .find(item => item.type === RouteItemType.STARTPOINT),
            [booking.route]
        );

        const {
            Form: ParticipantsForm,
            reset: resetParticipants,
            submit: submitParticipants,
            formState: {
                errors: participantsErrors,
                isDirty: isParticipantsDirty,
            },
        } = useForm({
            defaultValues: participantsToParticipantsForm(booking.participants),
            onSubmit: useCallback(
                (data: any, e?: React.BaseSyntheticEvent) => {
                    e?.preventDefault();
                    const travelers = data.travelers.map(
                        (traveler: TravelerForm) => ({
                            ...traveler,
                            pickUp: traveler.pickUp || startPickUp,
                        })
                    );
                    updateParticipants({
                        bookingId,
                        pax: data.pax,
                        items: travelers,
                    });
                },
                [bookingId, startPickUp]
            ),
            mode: 'onChange',
            schema: participantsSchema,
        });
        const {
            Form: FinancialInformationForm,
            reset: resetFinancialInformation,
            formState: { errors: financialInformationErrors },
        } = useForm({
            defaultValues: booking.product.pricing.discount || {
                type: DiscountType.RELATIVE,
                amount: 0,
            },
            onSubmit,
            mode: 'onChange',
            schema: financialInformationSchema,
        });
        const {
            Form: ProductForm,
            reset: resetProduct,
            // formState: { errors: productErrors },
        } = useForm({
            defaultValues: { values: [] },
            onSubmit,
            mode: 'onChange',
            schema: productSchema,
        });

        // Options
        const { mutate: updateOptions, isLoading: isSavingOptions } =
            useUpdateBookingOptions(booking.product.options || [], {
                onSuccess: () => {
                    view();
                },
                onError: (error: any) => {
                    if (error?.status === 409) {
                        enqueueSnackbar('Nothing to save', {
                            variant: 'error',
                        });
                    } else if (error?.body) {
                        enqueueSnackbar(error.body, { variant: 'error' });
                    }
                },
            });
        const {
            Form: ProductOptionsForm,
            submit: submitProductOptions,
            reset: resetProductOptions,
            formState: { isDirty: isOptionsDirty },
        } = useForm({
            defaultValues: {
                options:
                    booking.product.options?.map(o => ({
                        uuid: o.id,
                        name: o.name as string,
                        type: o.type as ProductOptionType,
                        pax: o?.pax || {},
                        hasPrice: !!o.pricing,
                        pricing: o.pricing,
                        existing: true,
                    })) || [],
            },
            onSubmit: useCallback(
                (data: any, e?: React.BaseSyntheticEvent) => {
                    e?.preventDefault();
                    updateOptions({
                        bookingId,
                        items: data.options.map(o => ({
                            id: o.id || o.uuid,
                            pax: o.pax,
                            name: o.name,
                            type: o.type,
                            sync_pricing: !o.existing,
                        })),
                    });
                },
                [bookingId]
            ),
            mode: 'onChange',
            schema: productOptionsSchema,
        });

        // Notes
        const { mutate: updateNotes, isLoading: isSaving } =
            useUpdateBookingNotes(booking.notes, {
                onSuccess: () => {
                    view();
                },
                onError: (error: any) => {
                    if (error?.status === 409) {
                        enqueueSnackbar('Nothing to save', {
                            variant: 'error',
                        });
                    } else if (error?.body) {
                        enqueueSnackbar(error.body, { variant: 'error' });
                    }
                },
            });
        const {
            Form: NotesForm,
            reset: resetNotes,
            submit: notesSubmit,
            formState: { errors: notesErrors, isDirty: isNotesDirty },
        } = useForm({
            defaultValues: {
                notes:
                    booking.notes?.map(({ id, content, visibility }) => ({
                        id,
                        value: content,
                        type: visibility,
                    })) || [],
            },
            onSubmit: useCallback(
                (data: any, e?: React.BaseSyntheticEvent) => {
                    e?.preventDefault();
                    updateNotes({
                        bookingId,
                        notes: data.notes?.map(
                            ({
                                id,
                                value,
                                type,
                            }: {
                                id: string;
                                value: string;
                                type: NoteVisibility;
                            }) => ({
                                id,
                                content: value,
                                visibility: type,
                            })
                        ),
                    });
                },
                [bookingId]
            ),
            mode: 'onChange',
            schema: notesSchema,
        });

        // Source
        const { mutate: updateSource, isLoading: isSavingSource } =
            useUpdateBookingSource({
                onSuccess: () => {
                    view();
                },
                onError: (error: any) => {
                    if (error?.status === 403) {
                        setAccessDeniedModal(true);
                    } else if (error?.status === 409) {
                        enqueueSnackbar('Nothing to save', {
                            variant: 'error',
                        });
                    } else if (error?.body) {
                        enqueueSnackbar(error.body, { variant: 'error' });
                    }
                },
            });
        const {
            Form: SourceForm,
            reset: resetSource,
            submit: sourceSubmit,
            formState: { errors: sourceErrors, isDirty: isSourceDirty },
        } = useForm({
            defaultValues: {
                sourceType: booking.source.type,
                sourceName:
                    booking.source.type === SourceType.REFERRAL
                        ? ''
                        : booking.source.name,
                referral:
                    booking.source.type === SourceType.REFERRAL &&
                    booking.source.reference
                        ? memberToReferral(booking.source)
                        : {},
            },
            onSubmit: useCallback(
                (data: any, e?: React.BaseSyntheticEvent) => {
                    e?.preventDefault();
                    updateSource({
                        bookingId,
                        sourceType: data.sourceType,
                        sourceName: data.sourceName,
                        memberId: data.referral?.id,
                    });
                },
                [bookingId]
            ),
            mode: 'onChange',
            schema: sourceSchema,
        });
        // Source update

        const reset = useCallback(() => {
            resetSchedule({
                start: booking.date.start,
            });
            resetParticipants(
                participantsToParticipantsForm(booking.participants)
            );
            resetFinancialInformation(
                booking.product.pricing.discount || {
                    type: DiscountType.RELATIVE,
                    amount: 0,
                }
            );
            resetProduct({ values: [] });
            resetProductOptions({
                options:
                    booking.product.options?.map(o => ({
                        uuid: o.id,
                        name: o.name as string,
                        type: o.type as ProductOptionType,
                        pax: o.pax || {},
                        hasPrice: !!o.pricing,
                        pricing: o.pricing,
                        existing: true,
                    })) || [],
            });
            resetNotes({
                notes: booking.notes?.map(({ id, content, visibility }) => ({
                    id,
                    value: content,
                    type: visibility,
                })),
            });
            resetSource({
                sourceType: booking.source.type,
                sourceName:
                    booking.source.type === SourceType.REFERRAL
                        ? ''
                        : booking.source.name,
                referral:
                    booking.source.type === SourceType.REFERRAL &&
                    booking.source.reference
                        ? memberToReferral(booking.source)
                        : undefined,
            });
        }, [booking]);

        const onCancel = useCallback(() => {
            reset();
            view();
        }, [view, reset]);

        useEffect(() => {
            reset();
        }, [booking]);

        const { setMainIsLoading } = useLayoutContext();
        useEffect(() => {
            setMainIsLoading(isSaving);
        }, [isSaving]);

        const hasProductOption =
            !!product?.options?.items.length ||
            !!booking.product.options?.length;

        const [moveBooking, setMoveBooking] = useState(false);

        return (
            <>
                <SideFormAccordion
                    id="schedule"
                    title="Date and Time"
                    isEditable={!readonly && false}
                    onDone={() => setMoveBooking(true)}
                    handleEditClick={() => setMoveBooking(true)}
                >
                    <ScheduleForm id="schedule">
                        <BookingScheduleForm
                            date={booking.date}
                            preview={!isEdit || openTab !== 'schedule'}
                        />
                    </ScheduleForm>
                </SideFormAccordion>
                <SideFormAccordion
                    id="source"
                    title="Booking Source"
                    isEditable={!readonly}
                    errors={sourceErrors}
                    isSaving={isSavingSource}
                    notDirty={!isSourceDirty}
                    onSave={sourceSubmit}
                    onCancel={onCancel}
                >
                    <SourceForm id="source">
                        <BookingSourceForm
                            productId={booking.product.id}
                            preview={!isEdit || openTab !== 'source'}
                        />
                    </SourceForm>
                </SideFormAccordion>
                <SideFormAccordion
                    id="participants"
                    title="Participants"
                    isEditable={!readonly}
                    errors={participantsErrors}
                    isSaving={isSavingParticipants}
                    notDirty={!isParticipantsDirty}
                    onSave={submitParticipants}
                    onCancel={onCancel}
                >
                    <ParticipantsForm>
                        <BookingParticipantsForm
                            preview={!isEdit || openTab !== 'participants'}
                            route={booking.route}
                        />
                    </ParticipantsForm>
                </SideFormAccordion>
                <SideFormAccordion
                    id="financialInformation"
                    title="Pricing and Financials"
                    isEditable={!readonly && false}
                    errors={financialInformationErrors}
                    isSaving={isSaving}
                    onCancel={onCancel}
                >
                    <FinancialInformationForm id="financialInformation">
                        <BookingFinancialInformationForm
                            financials={booking.financials}
                            pricing={booking.product.pricing}
                            transactions={booking.transactions}
                            options={booking.product.options}
                            bookingId={booking.id}
                            eventStatus={booking.eventStatus}
                            readonly={readonly}
                            pax={booking.participants.pax}
                            preview={
                                !isEdit || openTab !== 'financialInformation'
                            }
                            refetch={updateBooking}
                        />
                    </FinancialInformationForm>
                </SideFormAccordion>
                <SideFormAccordion id="product" title="Product">
                    <ProductForm id="product">
                        <BookingProductForm
                            product={booking.product}
                            route={booking.route}
                            preview={!isEdit || openTab !== 'product'}
                        />
                    </ProductForm>
                </SideFormAccordion>
                <SideFormAccordion
                    id="product-options"
                    title="Product Options"
                    isEditable={!readonly}
                    isSaving={isSavingOptions}
                    onSave={submitProductOptions}
                    notDirty={!isOptionsDirty}
                    onCancel={onCancel}
                    disabled={product && !hasProductOption}
                >
                    <ProductOptionsForm id="product-options">
                        {product && (
                            <BookingProductOptionsForm
                                preview={
                                    !isEdit || openTab !== 'product-options'
                                }
                                product={product}
                                capacityId={booking.capacityId}
                                bookingPax={booking.participants.pax}
                            />
                        )}
                    </ProductOptionsForm>
                </SideFormAccordion>
                <SideFormAccordion
                    id="notes"
                    title="Notes"
                    isEditable={permissions.update}
                    onCancel={onCancel}
                    isSaving={isSaving}
                    notDirty={!isNotesDirty}
                    onSave={notesSubmit}
                    errors={notesErrors}
                >
                    <NotesForm id="notes">
                        <BookingNotesForm
                            preview={!isEdit || openTab !== 'notes'}
                        />
                    </NotesForm>
                </SideFormAccordion>
                <SideFormAccordion id="activity-log" title="Activity Log">
                    <ActivityHistory
                        entity="booking"
                        lifecycle={booking.lifecycle}
                    />
                </SideFormAccordion>

                <MoveBookingModal
                    handleCancel={() => setMoveBooking(false)}
                    handleConfirm={() => {
                        updateBooking?.();
                        setMoveBooking(false);
                        closeForm?.();
                    }}
                    booking={booking}
                    open={moveBooking}
                />
                <ErrorDialog
                    open={accessDeniedModal}
                    handleConfirm={() => setAccessDeniedModal(false)}
                    title="Access Denied"
                />
            </>
        );
    }
);
