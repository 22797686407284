import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem as MuiMenuItem,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
    Stack,
    Theme,
} from '@mui/material';
import _ from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SystemStyleObject } from '@mui/system';
import { Tag } from '@travelity/ui';

const CapacityItem: React.FC<{ option: any; selected: boolean }> = props => {
    const { option } = props;

    return (
        <Stack
            sx={{
                lineHeight: '12px',
                width: 1,
                flexWrap: 'nowrap',
            }}
            direction="row"
            justifyContent="space-between"
        >
            <Box
                sx={{
                    py: '8px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {option.label}
            </Box>
            <Stack direction="row" gap={1} sx={{ pointerEvents: 'none' }}>
                {option.isNew && (
                    <Tag
                        value="New"
                        valueProps={{ sx: { bgcolor: '#CDEFDB' } }}
                    />
                )}

                <Tag label="Remaining" value={option.vacant} />
            </Stack>
        </Stack>
    );
};

export interface SelectCapacityProps extends MuiSelectProps {
    width?: string;
    size?: 'small' | 'medium';
    name: string;
    onSelect?: (v: any) => void;
    label: string;
    placeholder?: string;
    required?: boolean;
    options: { value: string; label: string }[];
    showErrors?: boolean;
    MenuItem?: React.FC<any>;
    formControlSx?: SystemStyleObject<Theme>;
}

const SelectCapacity: React.FC<SelectCapacityProps> = ({
    width,
    size,
    options,
    name,
    required,
    label,
    onSelect,
    placeholder,
    showErrors,
    disabled,
    formControlSx = {},
    MenuItem = CapacityItem,
    ...selectProps
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);

    return (
        <FormControl
            sx={{ width: width || '388px', '&&': formControlSx }}
            error={!!error}
            size={size}
            disabled={disabled}
        >
            <Controller
                render={({ field }: { field: Record<string, any> }) => (
                    <MuiSelect
                        required={!!required}
                        inputProps={{ required: false }}
                        label={label}
                        displayEmpty
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                            ...(selectProps.sx ? selectProps.sx : {}),
                            '&&& legend': placeholder
                                ? {
                                      maxWidth: '100%',
                                  }
                                : {},
                            '&&& .MuiSelect-select': {
                                pr: '48px',
                                py: '11px',
                            },
                        }}
                        renderValue={selected => {
                            if (!selected && placeholder) {
                                return (
                                    <Box
                                        component="span"
                                        sx={{
                                            color: '#949BAC',
                                        }}
                                    >
                                        {placeholder}
                                    </Box>
                                );
                            }

                            const option = options.find(
                                ({ value }) => value === selected
                            );

                            return option ? (
                                <CapacityItem option={option} />
                            ) : undefined;
                        }}
                        disabled={disabled}
                        {...selectProps}
                        {...field}
                        defaultValue=""
                        value={field.value || ''}
                        MenuProps={{
                            PaperProps: {
                                sx: { maxHeight: 280, maxWidth: width },
                            },
                        }}
                    >
                        {options.map((option, i) => (
                            <MuiMenuItem
                                sx={{
                                    fontSize: '12px',
                                    color: '#2B395B',
                                    py: '8px',
                                    px: '20px',
                                    // mx: 2,
                                    my: 0,
                                    '&:hover': {
                                        backgroundColor: '#EAF2F4',
                                    },
                                }}
                                value={option.value}
                                selected={option.value === field.value}
                                key={option.value}
                                divider
                                onClick={() => {
                                    onSelect(option.value);
                                    field.onChange(option.value);
                                }}
                            >
                                <MenuItem
                                    option={option}
                                    selected={option.value === field.value}
                                />
                            </MuiMenuItem>
                        ))}
                    </MuiSelect>
                )}
                name={name}
                control={control}
            />
            <InputLabel required={!!required} shrink>
                {label}
            </InputLabel>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

export default SelectCapacity;
