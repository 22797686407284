/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CancelEventsResBookingsItem0ProductCustomersDto } from './CancelEventsResBookingsItem0ProductCustomersDto';
import type { CancelEventsResBookingsItem0ProductOptionsDto } from './CancelEventsResBookingsItem0ProductOptionsDto';
import type { CancelEventsResBookingsItem0ProductPricingDto } from './CancelEventsResBookingsItem0ProductPricingDto';
import type { UpdateProductResConfigurationDto } from './UpdateProductResConfigurationDto';

export type CancelEventsResBookingsItem0ProductDto = {
    /**
     * type of the product
     */
    type?: CancelEventsResBookingsItem0ProductDto.type;
    /**
     * Type of the capacity of the product
     */
    id?: string;
    /**
     * Name of the product.
     */
    name?: string;
    /**
     * Capacity option user selected
     */
    capacity_option_id?: string;
    /**
     * Itemized summary of travelers' age information.
     */
    customers?: CancelEventsResBookingsItem0ProductCustomersDto;
    /**
     * Booked Pricing option
     */
    pricing?: CancelEventsResBookingsItem0ProductPricingDto;
    /**
     * Optional items and services for this product
     */
    options?: CancelEventsResBookingsItem0ProductOptionsDto;
    /**
     * Defines the number and groups of people that can be served by a unit of product
     */
    configuration?: UpdateProductResConfigurationDto;
};

export namespace CancelEventsResBookingsItem0ProductDto {

    /**
     * type of the product
     */
    export enum type {
        TOUR = 'tour',
    }


}
