/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingStatus } from './BookingStatus';
import type { CancelEventsResBookingsItem0AccessDto } from './CancelEventsResBookingsItem0AccessDto';
import type { CancelEventsResBookingsItem0EventDto } from './CancelEventsResBookingsItem0EventDto';
import type { CancelEventsResBookingsItem0FinancialsDto } from './CancelEventsResBookingsItem0FinancialsDto';
import type { CancelEventsResBookingsItem0OrderDto } from './CancelEventsResBookingsItem0OrderDto';
import type { CancelEventsResBookingsItem0ProductDto } from './CancelEventsResBookingsItem0ProductDto';
import type { CancelEventsResBookingsItem0Source0Dto } from './CancelEventsResBookingsItem0Source0Dto';
import type { CancelEventsResBookingsItem0Source1Dto } from './CancelEventsResBookingsItem0Source1Dto';
import type { CancelEventsResBookingsItem0TransactionsDto } from './CancelEventsResBookingsItem0TransactionsDto';
import type { CancelEventsResNotesDto } from './CancelEventsResNotesDto';
import type { UpdateUserResCreatedDto } from './UpdateUserResCreatedDto';

export type CancelEventsResBookingsItem0Dto = {
    /**
     * Type of the resource
     */
    resource_type?: CancelEventsResBookingsItem0Dto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: UpdateUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: UpdateUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: UpdateUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: UpdateUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<UpdateUserResCreatedDto>;
    /**
     * Source of the booking
     */
    source?: (CancelEventsResBookingsItem0Source0Dto | CancelEventsResBookingsItem0Source1Dto);
    /**
     * Order reference
     */
    product?: CancelEventsResBookingsItem0ProductDto;
    /**
     * Booked event
     */
    event?: CancelEventsResBookingsItem0EventDto;
    /**
     * Order reference
     */
    order: CancelEventsResBookingsItem0OrderDto;
    /**
     * List of transactions
     */
    transactions?: CancelEventsResBookingsItem0TransactionsDto;
    /**
     * List of transactions
     */
    financials?: CancelEventsResBookingsItem0FinancialsDto;
    /**
     * List of notes of some importance to the booking
     */
    notes?: CancelEventsResNotesDto;
    /**
     * Automatically computed status of the booking.
     */
    status: BookingStatus;
    /**
     * Access to the shared product
     */
    access?: CancelEventsResBookingsItem0AccessDto;
};

export namespace CancelEventsResBookingsItem0Dto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        BOOKING = 'booking',
    }


}
