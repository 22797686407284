import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTeam } from '@travelity/api';

import { Divider } from '@mui/material';
import {
    List,
    ListItems,
    ListMain,
    ListSidebar,
} from '../../components/list-layout';
import { TeamDetails } from './components/team-details';
import { TeamMemberItem } from '../../components/team-member-item';
import TeamItemSkeleton from '../../components/team-item-skeleton/team-item-skeleton';
import MemberPreview from '../teams/components/member-preview';
import { Filters } from '../../components/filters';
import {
    FilterTypes,
    FilterValue,
} from '../../components/filters/filters.types';

const teamMemberFilters = [
    {
        name: 'searchText',
        label: 'Search',
        type: FilterTypes.SEARCH,
        selectText: 'Search member name or email',
    },
];

function TeamPage() {
    const { memberId, teamId, preview } = useParams() as {
        memberId?: string;
        teamId: string;
        preview?: string;
    };

    const [filters, setFilters] = useState<Record<string, FilterValue>>({
        searchText: '',
    });

    const { data: team, isLoading, isRefetching } = useTeam(teamId);
    const member = useMemo(() => {
        return memberId
            ? team?.members?.find(({ id }) => id === memberId)
            : undefined;
    }, [team, memberId]);

    const members = useMemo(() => {
        return filters.searchText
            ? team?.members.filter(({ name, email }) => {
                  const searchText = (
                      filters.searchText as string
                  ).toLowerCase();
                  return (
                      name?.first?.toLowerCase().includes(searchText) ||
                      name?.last?.toLowerCase().includes(searchText) ||
                      email?.toLowerCase().includes(searchText)
                  );
              })
            : team?.members;
    }, [team, filters.searchText]);

    return (
        <List>
            <ListMain
                isLoading={isLoading || isRefetching}
                SkeletonComponent={TeamItemSkeleton}
            >
                <TeamDetails
                    isLoading={isLoading || isRefetching}
                    team={team}
                />
                <Divider sx={{ mb: 0.25 }} />
                <Filters
                    values={filters}
                    setValues={setFilters}
                    options={teamMemberFilters}
                />
                <ListItems
                    noItemsText=""
                    items={members?.length}
                    isLoading={isLoading || isRefetching}
                    entityName="member"
                    entityNamePlural="members"
                    subtractHeight={191}
                >
                    {members?.map(item => (
                        <TeamMemberItem
                            key={item.id}
                            intend={false}
                            member={item}
                            teamId={teamId}
                            isSelected={item.id === memberId}
                        />
                    ))}
                </ListItems>
            </ListMain>
            <ListSidebar
                isEmpty={!memberId && !preview}
                placeholderText="No member is selected for preview"
            >
                {member && (
                    <MemberPreview
                        member={member}
                        teamId={teamId}
                        isEditable={!!team?.permissions.update}
                    />
                )}
            </ListSidebar>
        </List>
    );
}

export default TeamPage;
