import { useFieldArray, useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    TextFieldProps,
    Tooltip,
} from '@mui/material';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { TagListItem } from '@travelity/ui';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export interface CreatableTagsProps {
    chipDirection?: 'row' | 'column';
    leaveLast?: boolean;
    required?: boolean;
    name: string;
    regEx?: RegExp;
    showErrors?: boolean;
    chipSx?: Record<string, any>;
    inputProps?: Partial<TextFieldProps>;
    options?: { value: string; label: string }[];
    defaultOption?: string;
    renderIcon?: (
        v: Record<string, any>
    ) => React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
    > | null;
}

const CreatableTags: React.FC<CreatableTagsProps> = props => {
    const {
        leaveLast,
        required,
        inputProps = {},
        name,
        regEx,
        chipSx,
        showErrors,
        chipDirection = 'row',
        options,
        defaultOption,
        renderIcon,
    } = props;

    const [type, setType] = useState<string | undefined>(defaultOption);

    const {
        control,
        formState: { errors },
    } = useFormContext();

    const {
        fields: values,
        remove,
        append,
    } = useFieldArray<Record<string, any>, string, string>({
        name,
        control,
    });

    const [value, setValue] = useState('');
    const nonValid = regEx && value && !value?.match(regEx);

    const onSubmit = () => {
        if (!nonValid) {
            if (
                value.trim() &&
                !values.find(
                    ({ value: v }) =>
                        v.toLowerCase() === value.toLowerCase().trim()
                )
            ) {
                append({ value: value.trim(), type });
            }
            setValue('');
        }
    };

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);

    return (
        <Stack>
            <TextField
                onKeyDown={ev => {
                    if (ev.key === 'Enter') {
                        onSubmit();
                        // Do code here
                        ev.preventDefault();
                    }
                }}
                InputLabelProps={{
                    required: !!required,
                    shrink: !!inputProps.placeholder,
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={onSubmit} tabIndex={-1}>
                                <KeyboardReturnIcon sx={{ color: '#959CAD' }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                {...inputProps}
                sx={{
                    ...(!options ? { flexGrow: 1 } : undefined),
                    ...inputProps.sx,
                }}
                value={value}
                onChange={e => setValue(e.target.value)}
                error={!!error || !!nonValid}
                helperText={error}
            />
            {options && (
                <FormControl sx={{ mt: 2 }}>
                    <Select
                        label="Visibility"
                        IconComponent={KeyboardArrowDownIcon}
                        value={type}
                        sx={{ '.MuiSelect-select': { pr: 6 } }}
                        onChange={e => setType(e.target.value)}
                    >
                        {options.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                    <InputLabel required={!!required} shrink>
                        Visibility
                    </InputLabel>
                </FormControl>
            )}
            <Stack
                direction={chipDirection}
                flexWrap="wrap"
                sx={{ py: '3px', ml: '-2px' }}
            >
                {values.map((field, i) => (
                    <TagListItem
                        key={field.id}
                        label={field.value}
                        icon={renderIcon?.(field) || undefined}
                        deleteIcon={
                            <Tooltip title="Remove">
                                <CloseIcon />
                            </Tooltip>
                        }
                        onDelete={
                            !leaveLast || i !== 0 ? () => remove(i) : undefined
                        }
                        sx={chipSx}
                    />
                ))}
            </Stack>
        </Stack>
    );
};

export default CreatableTags;
