import React from 'react';
import {
    Box,
    Divider,
    IconButton,
    Skeleton,
    Stack,
    Theme,
    useMediaQuery,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CloseIcon from '@mui/icons-material/Close';

export interface SidebarContentProps {
    children?: React.ReactNode;
    title?: string;
    isLoading?: boolean;
    onClose?: () => void;
}

export const SidebarContent: React.FC<SidebarContentProps> = props => {
    const { children, title, isLoading, onClose } = props;
    const tablet = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    return (
        <Box
            sx={{
                height: 1,
                '& > div:last-child': {
                    height: title ? 'calc(100% - 38px)' : 1,
                },
            }}
        >
            {title && (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ position: 'relative' }}
                >
                    <Box
                        sx={{
                            display: 'inline-flex',
                            padding: '8px 18px',
                            marginBottom: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                            borderRadius: '0px 0px 12px 0px',
                            background: '#6B748C',
                            color: '#FFF',
                            fontFamily: 'Lexend',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '100%',
                            textTransform: 'capitalize',
                        }}
                    >
                        {title}
                    </Box>
                    {tablet && (
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: '6px',
                            }}
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </Stack>
            )}
            {!isLoading && <PerfectScrollbar>{children}</PerfectScrollbar>}
            {isLoading && (
                <Stack sx={{ py: 2 }} gap={1}>
                    <Skeleton sx={{ mx: 2 }} width={120} />
                    <Divider />
                    <Skeleton sx={{ mx: 2 }} width={100} />
                    <Divider />
                    <Skeleton sx={{ mx: 2 }} width={140} />
                    <Divider />
                    <Skeleton sx={{ mx: 2 }} width={80} />
                    <Divider />
                    <Skeleton sx={{ mx: 2 }} width={180} />
                    <Divider />
                    <Skeleton sx={{ mx: 2 }} width={120} />
                    <Divider />
                </Stack>
            )}
        </Box>
    );
};
