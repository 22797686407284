import { Tag, TagStatus } from '@travelity/ui';
import React, { useCallback } from 'react';
import { useNavigateAbsolute } from '../../../hooks';

export interface OperationStatusItemProps {
    name: string;
    count: number;
    type: string;
    value: string;
    eventId: string;
    disabled?: boolean;
    allowOperation?: boolean;
}

export const OperationStatusItem: React.FC<
    OperationStatusItemProps
> = props => {
    const { name, count, type, value, eventId, disabled, allowOperation } =
        props;
    const navigate = useNavigateAbsolute();
    const onClick = useCallback(
        () =>
            navigate(`/events/${eventId}/operations/edit`, {
                state:
                    count > 0
                        ? undefined
                        : {
                              action: 'add',
                              type,
                              value,
                          },
            }),
        [navigate, count, type, value, eventId]
    );

    return (
        <Tag
            label={name}
            status={
                !allowOperation
                    ? TagStatus.BLOCK
                    : count === 0
                    ? TagStatus.ERROR
                    : count === 1
                    ? TagStatus.SUCCESS
                    : undefined
            }
            value={count > 1 ? count : undefined}
            key={name}
            onClick={allowOperation && !disabled ? onClick : undefined}
            valueProps={{
                sx:
                    count > 1
                        ? {
                              bgcolor: 'success.main',
                              color: '#FFF',
                              fontWeight: 300,
                              minWidth: '23px',
                          }
                        : undefined,
            }}
        />
    );
};
