import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import hash from 'object-hash';
import { startOfDay } from 'date-fns';
import { useSuperUser } from '@travelity/web/src/contexts/user';
import {
    availabilityProductDtoToAvailabilityProduct,
    filterToRequestBodyConverter,
} from './availability.converters';

import Worker from './availability.worker';

import {
    Availability,
    AvailabilityFilterValues,
    AvailabilityProduct,
    AvailableProduct,
    AvailableProductOption,
} from './availability.types';
import {
    PaxData,
    ProductOptionType,
    ProductsService,
    TimezoneName,
} from '../../requests';
import { CustomQueryOptions } from '../common.types';

async function communicateWithWorker(data: any) {
    return new Promise<Availability[] | undefined>(resolve => {
        const worker = new Worker();

        worker.onmessage = function (event: any) {
            resolve(event.data);
            worker.terminate();
        };

        worker.onerror = function (error: any) {
            console.log(error);
            resolve(undefined);
            worker.terminate();
        };

        worker.postMessage(data);
    });
}

export const useAvailability = (
    params: {
        requestBody: AvailabilityFilterValues;
    },
    options?: CustomQueryOptions<{
        products: Record<string, AvailableProduct>;
        events?: Availability[];
    }>
) => {
    const { getAccessTokenSilently } = useAuth0();
    const superUser = useSuperUser();

    return useQuery({
        queryKey: [params?.requestBody],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;

            const products: Record<string, AvailableProduct> = {};

            const data = await ProductsService.computeAvailability(
                authorization,
                filterToRequestBodyConverter(params?.requestBody),
                superUser
            );

            const allEvents = data.items?.map(({ events, ...item }) => {
                const product =
                    availabilityProductDtoToAvailabilityProduct(item);
                const key = hash(product);
                products[key] = product;

                return {
                    key,
                    id: product.id,
                    name: product.name,
                    type: product.type,
                    shared: product.shared,
                    events: [
                        ...(events?.new || []),
                        ...(events?.shared.filter(n => n.optimal) || []),
                    ],
                };
            });

            console.log('products', products);
            console.log('events before', allEvents);
            const events = await communicateWithWorker(allEvents || []);
            console.log('events after', events);

            return {
                products,
                events,
            };
        },
        ...options,
    });
};

interface UseDayAvailabilityParams {
    time: number;
    capacityId?: string;
    pax: PaxData;
    productId: string;
    options?: AvailableProductOption[];
}

const useDayAvailabilityKey = 'useDayAvailabilityKey';

export const useDayAvailability = (
    params: UseDayAvailabilityParams,
    defaultValue: {
        products?: Record<string, AvailableProduct>;
        availableProduct: AvailabilityProduct;
    },
    options?: CustomQueryOptions<{
        products?: Record<string, AvailableProduct>;
        availableProduct: AvailabilityProduct;
    }>
) => {
    const { getAccessTokenSilently } = useAuth0();
    const guideOptionIds = params.options
        ?.filter(o => o.id && o.type === ProductOptionType.GUIDE)
        .map(o => o.id as string);

    return useQuery({
        queryKey: [
            useDayAvailabilityKey,
            params.pax,
            params.time,
            params.productId,
            params.capacityId,
            guideOptionIds,
        ],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;

            const products: Record<string, AvailableProduct> = {};

            if (!guideOptionIds?.length) return defaultValue;

            const day = startOfDay(new Date(params.time));
            const data = await ProductsService.computeAvailability(
                authorization,
                {
                    date: {
                        timezone: {
                            name: Intl.DateTimeFormat().resolvedOptions()
                                .timeZone as TimezoneName,
                        },
                        start: Math.round(day.getTime() / 1000),
                        end:
                            Math.round(day.getTime() / 1000) + 24 * 60 * 60 - 1,
                    },
                    pax: params.pax,
                    products: [
                        {
                            id: params.productId,
                            options: guideOptionIds?.map(id => ({ id })),
                            capacity: params.capacityId
                                ? { items: [params.capacityId] }
                                : undefined,
                        },
                    ],
                }
            );

            const allEvents = data.items?.map(({ events, ...item }) => {
                const product =
                    availabilityProductDtoToAvailabilityProduct(item);
                const key = hash(product);
                products[key] = product;

                return {
                    key,
                    id: product.id,
                    name: product.name,
                    type: product.type,
                    shared: product.shared,
                    events: [
                        ...(events?.new || []),
                        ...(events?.shared.filter(n => n.optimal) || []),
                    ],
                };
            });

            console.log('products', products);
            console.log('events before', allEvents);
            const events = await communicateWithWorker(allEvents || []);
            console.log('events after', events);

            return {
                products,
                availableProduct: events?.length
                    ? events[0].products[0]
                    : { ...defaultValue.availableProduct, events: [] },
            };
        },
        ...options,
    });
};
