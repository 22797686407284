import React, { useEffect, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
    AgebandName,
    Booking,
    BookingProductPricing,
    BookingStatus,
    DiscountType,
    OrderItem,
    PaxData,
    PricingType,
    PrintData,
    ProductType,
} from '@travelity/api';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
    AgebandPricingData,
    NoteVisibility,
    FlatPricingData,
} from '@travelity/api/src/requests';
import { durationToHumanReadable, formatMoney } from '@travelity/utils';
import { useUserContext } from '../../../contexts/user';
import { TravelerForm } from '../../../components/booking-participants/booking-participants.types';
import { paxTotal } from '../../../hooks';

export interface PrintOrderProps {
    order: OrderItem;
    printData: PrintData;
}

const ageBands = ['Adults', 'Children', 'Infants', 'Youth', 'Seniors'];

const toPlural = (name: string, value: number) => {
    if (value > 1) return `${value} ${name}`;
    if (name === 'children') return `1 child`;
    // TODO remove s
    return `1 ${name.substring(0, name.length - 1)}`;
};

const getUniqPickups = (booking: Booking) => {
    const result: { id?: string; time: string; location: string }[] = [];
    booking.participants.travelers?.forEach(traveler => {
        const { pickUp } = traveler;
        if (
            pickUp &&
            !result.find(p => p.id === pickUp.id && p.location === pickUp.name)
        ) {
            result.push({
                id: pickUp.id,
                time: format(pickUp.time, 'HH:mm'),
                location: pickUp.name,
            });
        }
    });
    return result;
};

export const formatPaxValue = (value: Record<string, number>) =>
    ageBands
        .map(name => name.toLowerCase())
        .filter(name => value[name])
        .map(name => toPlural(name, value[name]))
        .join(', ');

export const formatTravelersValue = (travelers: TravelerForm[]) => {
    return travelers
        .map(
            traveler =>
                `${traveler.fullName}${
                    traveler.guestCount ? ` (+${traveler.guestCount})` : ''
                }`
        )
        .join(', ');
};

export interface PriceValueProps {
    title: string;
    pricing: BookingProductPricing;
    pax: PaxData;
}

// const sources = directSources.reduce(
//     (obj, cur) => ({
//         ...obj,
//         [cur.value]: cur.label,
//     }),
//     {} as Record<string, string>
// );

const toPluralX = (name: string, value: number) => {
    if (value > 1) return `${value}x ${name}`;
    if (name === 'children') return `1x child`;
    // TODO remove s
    return `1x ${name.substring(0, name.length - 1)}`;
};

export const Separator = ({ fontSize }: { fontSize?: string }) => {
    return (
        <Box component="span" className="separator" sx={{ px: 0.25, fontSize }}>
            {' '}
            |{' '}
        </Box>
    );
};

export const Watermark: React.FC = () => {
    useEffect(() => {
        document.body.className = 'draft';
        return () => {
            document.body.className = '';
        };
    }, []);

    return null;
};

export const KeyValue: React.FC<{
    title: string;
    value?: string | number;
    hide?: boolean;
    title2?: string;
    value2?: string | number;
}> = props => {
    const { title, value, hide, title2, value2 } = props;
    return hide ? null : (
        <Typography
            variant="body2"
            className="gray-text"
            sx={{ fontSize: '14px', py: 1 }}
        >
            {title && (
                <Box component="span" sx={{ fontWeight: 700, color: '#000' }}>
                    {title}:{' '}
                </Box>
            )}
            <Box component="span" sx={{ color: '#000' }}>
                {value}
            </Box>
            {title2 && (
                <>
                    <Separator />
                    <Box
                        component="span"
                        sx={{ fontWeight: 700, color: '#000' }}
                    >
                        {title2}:
                    </Box>{' '}
                    <Box component="span" sx={{ color: '#000' }}>
                        {value2}
                    </Box>
                </>
            )}
        </Typography>
    );
};

export const Title: React.FC<{
    text: string;
}> = props => {
    const { text } = props;
    return (
        <Typography
            variant="h6"
            sx={{ color: '#000', mb: 1.5, fontSize: '18px' }}
        >
            {text}
        </Typography>
    );
};

export const PriceValue: React.FC<PriceValueProps> = props => {
    const { title, pricing, pax } = props;
    const base = pricing.price.base_amount || 0;

    const prices: { count: number; price: number; label: string }[] = [];
    if (pricing.type === PricingType.PER_PRODUCT) {
        prices.push({
            count: 1,
            price: (pricing.price as FlatPricingData).unit_amount,
            label: 'products',
        });
    } else if (pricing.type === PricingType.PER_PERSON) {
        prices.push({
            count: paxTotal(pax),
            price: (pricing.price as FlatPricingData).unit_amount,
            label: 'persons',
        });
    } else if (pricing.type === PricingType.PER_AGEBAND) {
        Object.values(AgebandName).forEach((k: AgebandName) => {
            const p = (pricing.price as AgebandPricingData)[k];
            if (p && pax[k]) {
                prices.push({
                    count: pax[k] || 0,
                    price: p,
                    label: k,
                });
            }
        });
    }

    const totalPrice =
        pricing.type === PricingType.PER_PRODUCT
            ? (pricing.price as FlatPricingData).unit_amount || 0
            : prices.reduce((s, p) => s + p.count * p.price, 0) + base;

    return (
        <>
            <Typography
                variant="body2"
                sx={{
                    pt: 1,
                    pb: pricing.inclusions?.length ? 0 : 1,
                    fontSize: '14px',
                }}
            >
                <Box
                    component="span"
                    sx={{
                        fontWeight: 700,
                        color: '#000',
                    }}
                >
                    {title}:
                </Box>{' '}
                <Box
                    component="span"
                    sx={{
                        fontWeight: 300,
                        color: '#000',
                    }}
                >
                    {prices
                        .map(({ label, count }) => toPluralX(label, count))
                        .join(', ')}
                </Box>{' '}
                <Box
                    component="span"
                    sx={{
                        fontWeight: 300,
                        color: '#000',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {formatMoney(totalPrice)}
                </Box>
            </Typography>
            {!!pricing.inclusions?.length && (
                <Typography
                    variant="body2"
                    sx={{ ml: 0.5, pt: 0.5, pb: 1, fontSize: '13px' }}
                >
                    <Box component="span"> • </Box>
                    <Box
                        component="span"
                        sx={{
                            fontWeight: 700,
                            color: '#000',
                        }}
                    >
                        Inclusions:
                    </Box>{' '}
                    <Box
                        component="span"
                        sx={{
                            fontWeight: 300,
                            color: '#000',
                        }}
                    >
                        {pricing.inclusions.join(', ')}
                    </Box>
                </Typography>
            )}
        </>
    );
};

export const PrintOrder: React.FC<PrintOrderProps> = props => {
    const { order, printData } = props;
    const { user } = useUserContext();
    const {
        companyName = user?.fullName,
        website,
        appendix,
        email = user?.email,
        phone = user?.numbers?.[0]?.value,
        address,
    } = printData;

    const { t } = useTranslation();

    const currency = user?.currency || '';

    const isDraft =
        !!order.bookings.find(b => b.status !== BookingStatus.CANCELLED) &&
        !!order.bookings.find(
            b =>
                b.status !== BookingStatus.CONFIRMED &&
                b.status !== BookingStatus.CANCELLED
        );
    const orderDetails = useMemo(
        () => ({
            bookings: order.bookings.filter(
                booking => booking.status !== BookingStatus.CANCELLED
            ).length,
            createdBy: order.createdBy,
            beforeDiscount:
                order.financials?.subtotal &&
                order.financials?.subtotal !== order.financials?.total
                    ? formatMoney(order.financials?.subtotal)
                    : '',
            discount: order.financials?.discount
                ? `${formatMoney(order.financials.discount.amount)}${
                      order.financials.discount.type === DiscountType.RELATIVE
                          ? ' %'
                          : ''
                  }`
                : '',
            total: order.financials ? formatMoney(order.financials?.total) : '',
            toPay: order.financials
                ? formatMoney(
                      order.bookings
                          .filter(
                              booking =>
                                  booking.status !== BookingStatus.CANCELLED
                          )
                          .reduce((sum, b) => sum + (b.financials.debt || 0), 0)
                  )
                : '',
        }),
        [order]
    );

    const bookings = useMemo(
        () =>
            order.bookings
                .filter(booking => booking.status !== BookingStatus.CANCELLED)
                .map(booking => ({
                    id: booking.id,
                    status:
                        booking.status === BookingStatus.DRAFT
                            ? 'DRAFT'
                            : booking.status === BookingStatus.HOLD
                            ? 'TEMPORARY RESERVE'
                            : 'CONFIRMED',
                    productType: `${
                        booking.product.type === ProductType.TOUR
                            ? booking.shared
                                ? 'Group '
                                : 'Private '
                            : ''
                    }${t(booking.product.type, { ns: 'product' })}`,
                    productName: booking.product.name,
                    route: booking.route?.items
                        .map(stop => stop.location.name)
                        .join(' - '),
                    start: format(booking.date.start, 'd MMM yyyy, HH:mm'),
                    duration: booking.date.duration
                        ? durationToHumanReadable(booking.date.duration)
                        : '',
                    pickUps: getUniqPickups(booking)
                        .map(pickUp => `${pickUp.time} at ${pickUp.location}`)
                        .join(', '),
                    pax: formatPaxValue(booking.participants.pax),
                    travelers: booking.participants.travelers
                        ? formatTravelersValue(booking.participants.travelers)
                        : '',
                    beforeDiscount:
                        booking.financials.total !==
                            booking.financials.subtotal &&
                        booking.product.pricing.discount
                            ? formatMoney(booking.financials.subtotal)
                            : '',
                    discount: booking.product.pricing.discount
                        ? `${formatMoney(
                              booking.product.pricing.discount.amount
                          )}${
                              booking.product.pricing.discount.type ===
                              DiscountType.RELATIVE
                                  ? ' %'
                                  : ''
                          }`
                        : '',
                    total: formatMoney(booking.financials.total),
                    toPay: formatMoney(booking.financials.debt),
                    notes: booking.notes
                        .filter(v => v.visibility === NoteVisibility.CUSTOMER)
                        .map(note => note.content),
                    paxData: booking.participants.pax,
                    pricing: booking.product.pricing,
                    options: booking.product.options || [],
                })),
        [order]
    );

    return (
        <Box
            className="show-on-print draft"
            sx={{ bgcolor: '#FFF', position: 'relative' }}
        >
            {/* {isDraft && <Watermark />} */}

            <Box sx={{ px: 0, margin: '0 auto', color: '#444' }}>
                <Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h4"
                            sx={{
                                pt: 0,
                                fontSize: '32px',
                                pb: 3,
                                mb: 0,
                                color: '#000',
                                lineHeight: 1,
                            }}
                        >
                            {isDraft ? 'ORDER DRAFT' : 'ORDER CONFIRMATION'}
                        </Typography>
                    </Box>
                    <Stack
                        sx={{ px: 2 }}
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Box>
                            <Typography
                                variant="h5"
                                sx={{
                                    mb: 0,
                                    fontSize: '13px',
                                    color: '#000',
                                    lineHeight: 1,
                                }}
                            >
                                Order #
                                <Box
                                    component="span"
                                    className="black-text order-number"
                                >
                                    {order.number}
                                </Box>
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className="subtitle"
                                sx={{
                                    lineHeight: 1,
                                    color: '#999',
                                    fontSize: '12px',
                                }}
                            >
                                Generated at{' '}
                                <Box component="span" className="generated-at">
                                    {format(new Date(), 'd MMM yyyy HH:mm')}
                                </Box>
                            </Typography>
                        </Box>
                        <Box
                            className="financial-details"
                            sx={{ textAlign: 'right' }}
                        >
                            {companyName && (
                                <Typography
                                    variant="body2"
                                    className="gray-text"
                                    sx={{ lineHeight: 1.3 }}
                                >
                                    <Box
                                        component="span"
                                        sx={{ fontWeight: 700, color: '#000' }}
                                        className="black-text"
                                    >
                                        {companyName}
                                    </Box>
                                </Typography>
                            )}
                            {!!website && (
                                <Typography
                                    variant="body2"
                                    className="gray-text"
                                    sx={{ lineHeight: 1.3 }}
                                >
                                    <Box
                                        component="a"
                                        target="_blank"
                                        href={website}
                                        sx={{ fontWeight: 700, color: '#000' }}
                                        className="black-text"
                                    >
                                        {website}
                                    </Box>
                                </Typography>
                            )}
                            {email && (
                                <Typography
                                    variant="body2"
                                    className="gray-text"
                                    sx={{ lineHeight: 1.3 }}
                                >
                                    <Box
                                        component="a"
                                        href={`mailto:${email}`}
                                        sx={{ fontWeight: 700, color: '#000' }}
                                        className="black-text"
                                    >
                                        {email}
                                    </Box>
                                </Typography>
                            )}
                            {phone && (
                                <Typography
                                    variant="body2"
                                    className="gray-text"
                                    sx={{ lineHeight: 1.3 }}
                                >
                                    <Box
                                        component="span"
                                        sx={{ fontWeight: 700, color: '#000' }}
                                        className="black-text"
                                    >
                                        {phone}
                                    </Box>
                                </Typography>
                            )}
                            {address && (
                                <Typography
                                    variant="body2"
                                    className="gray-text"
                                    sx={{ lineHeight: 1.3 }}
                                >
                                    <Box
                                        component="span"
                                        sx={{ fontWeight: 700, color: '#000' }}
                                        className="black-text currency"
                                    >
                                        {address}
                                    </Box>
                                </Typography>
                            )}
                        </Box>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        className="ordered-by"
                        gap={1}
                        sx={{
                            borderBottom: '2px solid #000',
                            paddingBottom: 1,
                            fontStyle: 'italic',
                            mt: 2,
                            px: 2,
                        }}
                    >
                        <Typography
                            variant="body1"
                            className="gray-text"
                            sx={{ fontSize: '20px' }}
                        >
                            Ordered by:
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 700,
                                color: '#000',
                                fontSize: '20px',
                            }}
                            className="customer-name"
                        >
                            {order.participants.customer.fullName}
                        </Typography>
                        {order.participants.customer.emails.length > 0 && (
                            <>
                                <Separator fontSize="20px" />
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: '20px',
                                        color: '#000',
                                        '& a': { color: '#000' },
                                    }}
                                    className="customer-email"
                                >
                                    {' '}
                                    <a
                                        href={`mailto:${order.participants.customer.emails[0].value}`}
                                    >
                                        {
                                            order.participants.customer
                                                .emails[0].value
                                        }
                                    </a>
                                </Typography>
                            </>
                        )}
                        {order.participants.customer.numbers.length > 0 && (
                            <>
                                <Separator fontSize="20px" />
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: '20px',
                                        color: '#000',
                                    }}
                                    className="customer-number"
                                >
                                    {
                                        order.participants.customer.numbers[0]
                                            .value
                                    }
                                </Typography>
                            </>
                        )}
                    </Stack>

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        className="details-container"
                        sx={{ mt: 3, px: 2 }}
                    >
                        <Box className="order-details" sx={{ width: '52%' }}>
                            <Title text="Order Details" />
                            <KeyValue
                                title="Created by"
                                value={orderDetails.createdBy}
                            />
                            <KeyValue
                                title="Bookings"
                                value={orderDetails.bookings}
                            />
                        </Box>

                        {order.financials && (
                            <Box
                                className="financial-details"
                                sx={{ width: '48%', pl: 1 }}
                            >
                                <Title text="Financial Details" />
                                <KeyValue title="Currency" value={currency} />
                                <KeyValue
                                    title="Total before discount"
                                    value={orderDetails.beforeDiscount}
                                    hide={!orderDetails.beforeDiscount}
                                />
                                <KeyValue
                                    title="Discount"
                                    value={orderDetails.discount}
                                    hide={!orderDetails.discount}
                                />
                                <KeyValue
                                    title={
                                        !orderDetails.beforeDiscount
                                            ? 'Total'
                                            : 'Total after discount'
                                    }
                                    value={orderDetails.total}
                                />
                                <KeyValue
                                    title="To pay"
                                    value={orderDetails.toPay}
                                />
                            </Box>
                        )}
                    </Stack>
                </Box>
                <Box className="bookings-summary" sx={{ mt: 1 }}>
                    {bookings.map(booking => (
                        <>
                            <Box
                                key={booking.id}
                                className="booking"
                                sx={{
                                    borderTop: '2px solid #000',
                                    pt: 2,
                                    pb: 0.5,
                                    px: 2,
                                    marginBottom: booking.notes.length
                                        ? 0.5
                                        : 2,
                                    display: 'grid',
                                }}
                            >
                                <Title text="Booking Status" />
                                <KeyValue title="" value={booking.status} />
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    className="details-container"
                                    sx={{ marginTop: 3 }}
                                >
                                    <Box
                                        sx={{
                                            width: '52%',
                                            ml: 0,
                                            pt: 0.5,
                                            pb: 0.75,
                                        }}
                                    >
                                        <Title text="Booking Summary" />
                                        <KeyValue
                                            title={booking.productType}
                                            value={booking.productName}
                                        />
                                        <KeyValue
                                            title="Route"
                                            value={booking.route}
                                        />
                                        <KeyValue
                                            title="Start Date/Time"
                                            value={booking.start}
                                            title2="Duration"
                                            value2={booking.duration}
                                        />
                                        <KeyValue
                                            title="Pick-ups"
                                            value={booking.pickUps}
                                        />
                                        <KeyValue
                                            title="PAX"
                                            value={booking.pax}
                                            title2={
                                                booking.travelers
                                                    ? 'Travelers'
                                                    : undefined
                                            }
                                            value2={booking.travelers}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '48%',
                                            ml: 0,
                                            pt: 0.5,
                                            pb: 0.75,
                                            pl: 1,
                                        }}
                                    >
                                        <Title text="Financial Information" />
                                        <PriceValue
                                            title="Main product price"
                                            pricing={booking.pricing}
                                            pax={booking.paxData}
                                        />
                                        {booking.options?.map(
                                            option =>
                                                option.pricing && (
                                                    <PriceValue
                                                        title={`${option.name} price`}
                                                        pricing={option.pricing}
                                                        pax={option.pax}
                                                    />
                                                )
                                        )}
                                        <KeyValue
                                            title="Total before discount"
                                            value={booking.beforeDiscount}
                                            hide={!booking.beforeDiscount}
                                            title2="Discount"
                                            value2={booking.discount}
                                        />
                                        <KeyValue
                                            title={
                                                !booking.beforeDiscount
                                                    ? 'Total'
                                                    : 'Total after discount'
                                            }
                                            value={booking.total}
                                            title2="To pay"
                                            value2={booking.toPay}
                                        />
                                    </Box>
                                </Stack>
                            </Box>

                            {!!booking.notes.length && (
                                <Box sx={{ mt: 2, px: 2 }}>
                                    <Title text="Notes" />
                                    {booking.notes.map(note => (
                                        <Box>{note}</Box>
                                    ))}
                                </Box>
                            )}
                        </>
                    ))}
                </Box>

                {appendix && (
                    // @ts-ignore
                    <Box
                        sx={{ px: 2 }}
                        className="pagebreak"
                        dangerouslySetInnerHTML={{ __html: appendix }}
                    />
                )}
            </Box>
        </Box>
    );
};
