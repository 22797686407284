import React from 'react';
import { Button, ButtonProps, Theme, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export interface AddButtonProps extends ButtonProps {
    text: string;
}

export const AddButton: React.FC<AddButtonProps> = props => {
    const { text, ...rest } = props;
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    return (
        <Button
            variant="contained"
            color="secondary"
            {...rest}
            sx={{
                px: isMobile ? '6px' : undefined,
                minWidth: { xs: '36px', sm: '64px' },
            }}
        >
            {isMobile ? <AddIcon /> : text}
        </Button>
    );
};
