import React, { useEffect, useMemo } from 'react';
import { TransactionMethod, TransactionType } from '@travelity/api';
import { CurrencyField, TagsList, useForm } from '@travelity/form';
import {
    Box,
    Paper,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
} from '@mui/material';
import { useController } from 'react-hook-form';
import { convertEnumToList } from '@travelity/utils';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog } from '@travelity/ui';
import ReplayIcon from '@mui/icons-material/Replay';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

export interface TransactionModalProps {
    currency: string;
    handleCancel: () => void;
    isLoading?: boolean;
    availableMethods?: TransactionMethod[];
    handleConfirm: (v: {
        method: TransactionMethod;
        amount: number;
        type: TransactionType;
    }) => void;
    open: boolean;
    title?: string;
    content?: string;
    confirmText?: string;
}

export const TransactionModal: React.FC<TransactionModalProps> = props => {
    const { handleConfirm, currency, availableMethods, ...other } = props;
    const { t } = useTranslation('common');

    const paymentMethodOptions = useMemo(
        () =>
            convertEnumToList<TransactionMethod>(TransactionMethod, t).filter(
                ({ value }) =>
                    !availableMethods || availableMethods.includes(value)
            ),
        [t, availableMethods]
    );

    const { Form, getValues, reset, control, watch } = useForm({
        isModal: true,
        mode: 'onChange',
        defaultValues: {
            method: undefined,
            amount: undefined,
            type: TransactionType.PAYMENT,
        },
    });

    const type = useController({ control, name: 'type' });

    const method = watch('method');
    const amount = watch('amount');

    useEffect(() => {
        reset({});
    }, [other.open]);

    return (
        <ConfirmationDialog
            {...other}
            disableConfirm={!method || !amount}
            handleConfirm={() => {
                const data = getValues();
                if (data.method && data.amount && data.type) {
                    handleConfirm(
                        data as unknown as {
                            method: TransactionMethod;
                            amount: number;
                            type: TransactionType;
                        }
                    );
                }
            }}
        >
            <Form>
                <Paper
                    sx={{
                        mb: 2,
                        py: 1,
                        px: 2,
                        bgColor: '#F4F6FA',
                        maxWidth: '388px',
                    }}
                >
                    <Box
                        sx={{
                            color: '#2B395B',
                            fontSize: '14px',
                            width: 1,
                            justifyContent: 'space-between',
                            margin: 0,
                        }}
                    >
                        Select method
                    </Box>

                    <TagsList
                        name="method"
                        selectAll={false}
                        options={paymentMethodOptions}
                    />
                </Paper>

                <Stack direction="row" gap={2} alignItems="center">
                    <ToggleButtonGroup
                        exclusive
                        size="small"
                        value={type.field.value}
                        onChange={(e, value) => {
                            if (value) type.field.onChange(value);
                        }}
                    >
                        <Tooltip title="Pay">
                            <ToggleButton
                                value={TransactionType.PAYMENT}
                                size="small"
                                sx={{ p: 1 }}
                                selected={
                                    type.field.value === TransactionType.PAYMENT
                                }
                            >
                                <PaymentsOutlinedIcon />
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip title="Refund">
                            <ToggleButton
                                value={TransactionType.REFUND}
                                size="small"
                                title="refund"
                                sx={{ p: 1 }}
                                selected={
                                    type.field.value === TransactionType.REFUND
                                }
                            >
                                <ReplayIcon />
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip title="Waive">
                            <ToggleButton
                                value={TransactionType.WAIVER}
                                size="small"
                                sx={{ p: 1 }}
                                selected={
                                    type.field.value === TransactionType.WAIVER
                                }
                            >
                                <MoneyOffIcon />
                            </ToggleButton>
                        </Tooltip>
                    </ToggleButtonGroup>
                    <CurrencyField
                        label="Amount"
                        placeholder="Type amount"
                        name="amount"
                        currency={currency}
                        sx={{ width: '250px' }}
                    />
                </Stack>
            </Form>
        </ConfirmationDialog>
    );
};
