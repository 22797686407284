import { format } from 'date-fns';
import {
    CreateManySchedulesReqItemDto,
    DayOfWeek,
    GetProductsResItem0ScheduleItemReferenceDto,
    GetSchedulesResItemDto,
    MonthlyRecurrenceData,
    ScheduleType,
    TimezoneName,
    UpdateCapacityProducts1ReqItemDto,
    type UpdateCapacityProducts1ReqItemDurationDto,
    WeeklyRecurrenceData,
    YearlyRecurrenceData,
} from '../../requests';
import {
    RecurrenceTypes,
    ScheduleItem,
    ScheduleOptionTypes,
    Weekdays,
} from './schedule.types';
import { ProductScheduleItem } from '../product/product.types';
import {
    convertTimeOffsetToMinutes,
    convertDateToTimestamp,
    convertTimestampToDate,
} from '../common.converters';

const dayOfWeekToWeekday: Record<DayOfWeek, Weekdays> = {
    [DayOfWeek.MONDAY]: Weekdays.MONDAY,
    [DayOfWeek.TUESDAY]: Weekdays.TUESDAY,
    [DayOfWeek.WEDNESDAY]: Weekdays.WEDNESDAY,
    [DayOfWeek.THURSDAY]: Weekdays.THURSDAY,
    [DayOfWeek.FRIDAY]: Weekdays.FRIDAY,
    [DayOfWeek.SATURDAY]: Weekdays.SATURDAY,
    [DayOfWeek.SUNDAY]: Weekdays.SUNDAY,
};

export const convertTimestampToDateSafe = (t: number | undefined): Date =>
    t ? convertTimestampToDate(t) : new Date();

const weekdayToDayOfWeek: Record<Weekdays, DayOfWeek> = {
    [Weekdays.MONDAY]: DayOfWeek.MONDAY,
    [Weekdays.TUESDAY]: DayOfWeek.TUESDAY,
    [Weekdays.WEDNESDAY]: DayOfWeek.WEDNESDAY,
    [Weekdays.THURSDAY]: DayOfWeek.THURSDAY,
    [Weekdays.FRIDAY]: DayOfWeek.FRIDAY,
    [Weekdays.SATURDAY]: DayOfWeek.SATURDAY,
    [Weekdays.SUNDAY]: DayOfWeek.SUNDAY,
};

export const scheduleItemToSchedule = (
    schedule: ScheduleItem
): CreateManySchedulesReqItemDto => ({
    name: schedule.name,
    timezone: {
        name: Intl.DateTimeFormat().resolvedOptions().timeZone as TimezoneName,
        offset: 0,
    },
    start_times: schedule.allDay
        ? undefined
        : schedule.times?.map(minutes => ({
              hours: Math.floor(minutes / 60),
              minutes: minutes % 60,
          })),
    any_time: schedule.allDay,
    ...(schedule.type === ScheduleOptionTypes.CUSTOM
        ? {
              type: ScheduleType.NON_RECURRING,
              dates: schedule.startDates.map(d => ({
                  start: convertDateToTimestamp(d, true),
                  timezone: {
                      name: Intl.DateTimeFormat().resolvedOptions()
                          .timeZone as TimezoneName,
                      offset: 0,
                  },
              })),
          }
        : {
              type: ScheduleType.RECURRING,
              dates: [
                  {
                      timezone: {
                          name: Intl.DateTimeFormat().resolvedOptions()
                              .timeZone as TimezoneName,
                          offset: 0,
                      },
                      start: convertDateToTimestamp(schedule.startDate, true),
                      end:
                          convertDateToTimestamp(schedule.endDate, true) +
                          24 * 60 * 60 -
                          1,
                  },
              ],
              recurrence:
                  schedule.recurrenceType === RecurrenceTypes.WEEKLY
                      ? {
                            type: WeeklyRecurrenceData.type.WEEKLY,
                            days_of_week: schedule.weekDays?.map(
                                d => weekdayToDayOfWeek[d]
                            ) as DayOfWeek[],
                        }
                      : schedule.recurrenceType === RecurrenceTypes.MONTHLY
                      ? {
                            type: MonthlyRecurrenceData.type.MONTHLY,
                            days_of_month: schedule.monthDays?.map(d =>
                                parseInt(format(d, 'd'), 10)
                            ) as number[],
                        }
                      : {
                            type: YearlyRecurrenceData.type.YEARLY,
                            dates: schedule.dates?.map(
                                convertDateToTimestamp
                            ) as number[],
                        },
          }),
});

export const scheduleDtoToScheduleItem = (
    schedule: GetSchedulesResItemDto
): ScheduleItem => ({
    scheduleId: schedule.id,
    timeZone: schedule.timezone.name,
    name: schedule.name,
    allDay: schedule.any_time,
    times: schedule.start_times?.map(convertTimeOffsetToMinutes) || [],
    ...(schedule.recurrence?.type
        ? {
              type: ScheduleOptionTypes.SEASON,
              startDate: convertTimestampToDate(schedule.dates[0].start),
              endDate: convertTimestampToDateSafe(schedule.dates[0].end),
              recurrenceType: RecurrenceTypes.WEEKLY,
              ...(schedule.recurrence.type ===
                  WeeklyRecurrenceData.type.WEEKLY && {
                  weekDays: schedule.recurrence.days_of_week.map(
                      w => dayOfWeekToWeekday[w]
                  ),
              }),
              ...(schedule.recurrence.type ===
                  MonthlyRecurrenceData.type.MONTHLY && {
                  recurrenceType: RecurrenceTypes.MONTHLY,
                  monthDays: schedule.recurrence.days_of_month.map(
                      day => new Date(2021, 0, day)
                  ),
              }),
              ...(schedule.recurrence.type ===
                  YearlyRecurrenceData.type.YEARLY && {
                  recurrenceType: RecurrenceTypes.YEARLY,
                  dates: schedule.recurrence.dates.map(
                      convertTimestampToDateSafe
                  ),
              }),
          }
        : {
              type: ScheduleOptionTypes.CUSTOM,
              startDates: schedule.dates.map(d =>
                  convertTimestampToDateSafe(d.start)
              ),
          }),
});

export const scheduleItemDtoToProductScheduleItem = (
    schedule: GetProductsResItem0ScheduleItemReferenceDto
): ProductScheduleItem => ({
    id: schedule.id,
    uuid: schedule.id,
    scheduleId: schedule.id,
    name: schedule.name,
    type:
        schedule.type === ScheduleType.RECURRING
            ? ScheduleType.RECURRING
            : ScheduleType.NON_RECURRING,
});

export const productScheduleToScheduleDto = (
    schedule: ProductScheduleItem,
    duration: UpdateCapacityProducts1ReqItemDurationDto
): Omit<UpdateCapacityProducts1ReqItemDto, 'action'> => {
    return {
        id: schedule.id || schedule.uuid,
        duration,
        reference: {
            type:
                schedule.type === ScheduleType.RECURRING
                    ? ScheduleType.RECURRING
                    : ScheduleType.NON_RECURRING,
            id: schedule.scheduleId,
            name: schedule.name,
        },
    };
};
