import React, { useMemo } from 'react';
import { Divider, Paper, Stack } from '@mui/material';
import { TextArea, TextField, TagsList, SearchSelect } from '@travelity/form';
import { useFormContext } from 'react-hook-form';
import { convertEnumToList } from '@travelity/utils';
import { Language, ProductType, useProductName } from '@travelity/api';
import { ReadonlyTextarea } from '../readonly-textarea';

const productCategories = [
    { value: 'Abseiling', label: 'Abseiling' },
    { value: 'Adventure Tours', label: 'Adventure Tours' },
    { value: 'Aerobatic', label: 'Aerobatic' },
    { value: 'Aquarium & Zoo', label: 'Aquarium & Zoo' },
    { value: 'Boat Day Trips', label: 'Boat Day Trips' },
    { value: 'Bus Tours', label: 'Bus Tours' },
    { value: 'Camping', label: 'Camping' },
    { value: 'Diving', label: 'Diving' },
    { value: 'Canoeing', label: 'Canoeing' },
    { value: 'Caving', label: 'Caving' },
];

const interests = [
    { value: 'Family', label: 'Family' },
    { value: 'Cultural', label: 'Cultural' },
    { value: 'Sightseeing', label: 'Sightseeing' },
    { value: 'Lifestyle', label: 'Lifestyle' },
    { value: 'Romance', label: 'Romance' },
    { value: 'Luxury', label: 'Luxury' },
    { value: 'Relaxation', label: 'Relaxation' },
    { value: 'Voluntourism', label: 'Voluntourism' },
    { value: 'Special Interest', label: 'Special Interest' },
    { value: 'Ecotourism', label: 'Ecotourism' },
];

const accessibility = [
    { value: 'Vision Impaired', label: 'Vision Impaired' },
    { value: 'Paraplegic', label: 'Paraplegic' },
    { value: 'Hearing Impaired', label: 'Hearing Impaired' },
    { value: 'Quadriplegic', label: 'Quadriplegic' },
    { value: 'Epileptic', label: 'Epileptic' },
    { value: 'Asthmatic', label: 'Asthmatic' },
];

export interface ProductDetailsFormProps {
    isNew?: boolean;
    preview?: boolean;
    type: ProductType;
    showErrors?: boolean;
}

const ProductDetailsForm: React.FC<ProductDetailsFormProps> = ({
    isNew,
    preview,
    type,
    showErrors,
}) => {
    const { watch } = useFormContext();
    const languages = useMemo(() => convertEnumToList<Language>(Language), []);
    const { data } = useProductName({ enabled: !preview });
    const enableTags = false;

    return (
        <Stack spacing={2}>
            <TextField
                placeholder="Type Product Name or Use Generated Name"
                name="name"
                label="Product Name"
                required
                showErrors={showErrors}
                hideHelperText
                disabled={preview || !isNew}
                autoGenValue={data?.name || ''}
            />
            {preview ? (
                <ReadonlyTextarea
                    sx={{ width: '388px', opacity: 0.6 }}
                    label="Description"
                    content={watch('description')}
                />
            ) : (
                <TextArea
                    placeholder="Type Description"
                    name="description"
                    showErrors={showErrors}
                    hideHelperText
                    label="Description"
                    required
                />
            )}
            {type !== ProductType.TRANSFER && (
                <SearchSelect
                    placeholder="Select Language"
                    name="languages"
                    label="Languages"
                    required
                    multiple
                    showErrors={showErrors}
                    disabled={preview}
                    options={languages}
                />
            )}
            {(!preview ||
                !!watch('productCategories')?.length ||
                !!watch('interests')?.length ||
                !!watch('accessibility')?.length) &&
                enableTags && (
                    <Paper sx={{ p: 2 }}>
                        {(!preview || !!watch('productCategories')?.length) && (
                            <Divider textAlign="left">
                                Product Categories
                            </Divider>
                        )}
                        <TagsList
                            hideUnselected={preview}
                            disabled={preview}
                            multiple
                            options={productCategories}
                            name="productCategories"
                        />
                        {(!preview || !!watch('interests')?.length) && (
                            <Divider textAlign="left" sx={{ mt: 1 }}>
                                Interests
                            </Divider>
                        )}
                        <TagsList
                            hideUnselected={preview}
                            disabled={preview}
                            multiple
                            options={interests}
                            name="interests"
                        />
                        {(!preview || !!watch('accessibility')?.length) && (
                            <Divider textAlign="left" sx={{ mt: 1 }}>
                                Accessibility
                            </Divider>
                        )}
                        <TagsList
                            hideUnselected={preview}
                            disabled={preview}
                            multiple
                            selectAll
                            options={accessibility}
                            name="accessibility"
                        />
                    </Paper>
                )}
        </Stack>
    );
};

export default React.memo(ProductDetailsForm);
