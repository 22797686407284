/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateBookingResProductCustomersDto } from './CreateBookingResProductCustomersDto';
import type { CreateBookingResProductOptionsDto } from './CreateBookingResProductOptionsDto';
import type { CreateBookingResProductPricingDto } from './CreateBookingResProductPricingDto';
import type { CreateProductResConfigurationDto } from './CreateProductResConfigurationDto';

export type CreateBookingResProductDto = {
    /**
     * type of the product
     */
    type: CreateBookingResProductDto.type;
    /**
     * Type of the capacity of the product
     */
    id: string;
    /**
     * Name of the product.
     */
    name: string;
    /**
     * Capacity option user selected
     */
    capacity_option_id?: string;
    /**
     * Itemized summary of travelers' age information.
     */
    customers: CreateBookingResProductCustomersDto;
    /**
     * Booked Pricing option
     */
    pricing: CreateBookingResProductPricingDto;
    /**
     * Optional items and services for this product
     */
    options?: CreateBookingResProductOptionsDto;
    /**
     * Defines the number and groups of people that can be served by a unit of product
     */
    configuration?: CreateProductResConfigurationDto;
};

export namespace CreateBookingResProductDto {

    /**
     * type of the product
     */
    export enum type {
        TRANSFER = 'transfer',
    }


}
