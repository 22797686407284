import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, ListItemText, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import { ReactComponent as Logo } from '../../../assets/travelity-logo.svg';

function LogoHeader() {
    const theme = useTheme();
    const { logout } = useAuth0();

    const handleLogout = async () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };

    return (
        <Box
            sx={{
                ml: '8px',
                display: 'flex',
                height: '60px',
                width: 1,
                justifyContent: 'center',
                [theme.breakpoints.down('md')]: {
                    width: 'auto',
                },
            }}
        >
            <Box
                sx={{
                    width: 180,
                    flexGrow: 2,
                    textAlign: 'center',
                    pl: '104px',
                }}
            >
                <Logo
                    style={{
                        height: '60px',
                        width: '140px',
                    }}
                />
            </Box>
            <Button
                variant="text"
                sx={{
                    margin: '4px 16px',
                    px: 2,
                    borderRadius: '8px',
                }}
                onClick={handleLogout}
            >
                <ListItemText
                    sx={{ color: '#7682A4' }}
                    primary={<Typography variant="body2">Logout</Typography>}
                />
            </Button>
        </Box>
    );
}

export default LogoHeader;
