/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateEventResAccessDto } from './CreateEventResAccessDto';
import type { CreateEventResNotesDto } from './CreateEventResNotesDto';
import type { CreateEventResOperationsDto } from './CreateEventResOperationsDto';
import type { CreateEventResProductDto } from './CreateEventResProductDto';
import type { EventStatus } from './EventStatus';
import type { PeriodData } from './PeriodData';
import type { RegisterUsersResCreatedDto } from './RegisterUsersResCreatedDto';

export type CreateEventResDto = {
    /**
     * Type of the resource
     */
    resource_type: CreateEventResDto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: RegisterUsersResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<RegisterUsersResCreatedDto>;
    /**
     * For shared events, indicates whether more bookings can be accepted
     */
    locked: boolean;
    /**
     * Product selected for the event
     */
    product: CreateEventResProductDto;
    /**
     * Timestamp which represents date and time of the event.
     */
    date: PeriodData;
    /**
     * Event operations
     */
    operations?: CreateEventResOperationsDto;
    /**
     * List of notes of some importance to the booking
     */
    notes?: CreateEventResNotesDto;
    /**
     * Automatically computed status of the booking.
     */
    status: EventStatus;
    /**
     * Access to the shared product
     */
    access: CreateEventResAccessDto;
};

export namespace CreateEventResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        EVENT = 'event',
    }


}
