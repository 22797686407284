/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingStatus } from './BookingStatus';
import type { CreateBookingResAccessDto } from './CreateBookingResAccessDto';
import type { CreateBookingResEventDto } from './CreateBookingResEventDto';
import type { CreateBookingResFinancialsDto } from './CreateBookingResFinancialsDto';
import type { CreateBookingResOrderDto } from './CreateBookingResOrderDto';
import type { CreateBookingResProductDto } from './CreateBookingResProductDto';
import type { CreateBookingResSource0Dto } from './CreateBookingResSource0Dto';
import type { CreateBookingResSource1Dto } from './CreateBookingResSource1Dto';
import type { CreateBookingResTransactionsDto } from './CreateBookingResTransactionsDto';
import type { CreateEventResNotesDto } from './CreateEventResNotesDto';
import type { RegisterUsersResCreatedDto } from './RegisterUsersResCreatedDto';

export type CreateBookingResDto = {
    /**
     * Type of the resource
     */
    resource_type: CreateBookingResDto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: RegisterUsersResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<RegisterUsersResCreatedDto>;
    /**
     * Source of the booking
     */
    source: (CreateBookingResSource0Dto | CreateBookingResSource1Dto);
    /**
     * Order reference
     */
    product: CreateBookingResProductDto;
    /**
     * Booked event
     */
    event?: CreateBookingResEventDto;
    /**
     * Order reference
     */
    order: CreateBookingResOrderDto;
    /**
     * List of transactions
     */
    transactions?: CreateBookingResTransactionsDto;
    /**
     * List of transactions
     */
    financials: CreateBookingResFinancialsDto;
    /**
     * List of notes of some importance to the booking
     */
    notes?: CreateEventResNotesDto;
    /**
     * Automatically computed status of the booking.
     */
    status: BookingStatus;
    /**
     * Access to the shared product
     */
    access: CreateBookingResAccessDto;
};

export namespace CreateBookingResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        BOOKING = 'booking',
    }


}
