import React from 'react';
import { Chip, Stack, Tooltip } from '@mui/material';
import { CreatableTags } from '@travelity/form';
import { useFormContext } from 'react-hook-form';
import { Lock } from '@mui/icons-material';

export interface BookingNotesFormProps {
    preview?: boolean;
}

const BookingNotesForm: React.FC<BookingNotesFormProps> = ({ preview }) => {
    const { watch } = useFormContext();

    return !preview ? (
        <Stack spacing={2}>
            <CreatableTags
                name="notes"
                renderIcon={({ type }) =>
                    type === 'internal' ? (
                        <Tooltip title="Internal">
                            <Lock />
                        </Tooltip>
                    ) : null
                }
                options={[
                    {
                        value: 'internal',
                        label: 'internal',
                    },
                    {
                        value: 'customer',
                        label: 'customer',
                    },
                ]}
                defaultOption="internal"
                inputProps={{
                    label: 'Notes',
                    placeholder: 'Type and press enter to add note',
                }}
                chipDirection="column"
                chipSx={{
                    borderRadius: '12px',
                    padding: '8px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '10px',
                    bgcolor: '#F4F6FA',
                    '& > .MuiChip-label': {
                        color: '#2B395B',
                        fontSize: '12px',
                        flexGrow: 2,
                        whiteSpace: 'wrap',
                    },
                    '& > .MuiChip-icon ': {
                        color: '#2B395B',
                    },
                }}
            />
        </Stack>
    ) : (
        <Stack sx={{ py: '3px', ml: '-2px' }} gap={1}>
            {watch('notes')?.map((field: any) => (
                <Chip
                    key={field.id}
                    label={field.value}
                    size="small"
                    icon={
                        field.type === 'internal' ? (
                            <Tooltip title="Internal">
                                <Lock />
                            </Tooltip>
                        ) : undefined
                    }
                    sx={{
                        borderRadius: '12px',
                        padding: '8px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '10px',
                        bgcolor: '#F4F6FA',
                        '& > .MuiChip-label': {
                            color: '#2B395B',
                            fontSize: '12px',
                            flexGrow: 2,
                            whiteSpace: 'wrap',
                        },
                        '& > .MuiChip-icon ': {
                            color: '#2B395B',
                        },
                    }}
                    color="primary"
                    variant="filled"
                />
            ))}
        </Stack>
    );
};

export default React.memo(BookingNotesForm);
