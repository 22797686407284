import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { User } from '@travelity/api';

import LogoSection from '../logo-section';
import ProfileSection from './profile-section';

import { BookingModal } from '../../../components/booking-modal';
import OpenOrders from './open-orders';
import { CurrencyTag } from './currency-tag';

// const dateEnd = Math.round(Date.now() / 1000) + 60 * 60 * 24 * 365;

interface HeaderProps {
    handleLeftDrawerToggle: () => void;
    user?: User;
    setSuperUser?: (v: boolean) => void;
}

function Header({ handleLeftDrawerToggle, user, setSuperUser }: HeaderProps) {
    const theme = useTheme();

    const [bookingOpen, setBookingOpen] = useState(false);
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const bookingVisible = user?.roleAccess.booking?.create && !mobile;
    const orderVisible = user?.roleAccess.order?.read;

    return (
        <>
            {/* logo & left side menu button */}
            <Box>
                <IconButton onClick={handleLeftDrawerToggle}>
                    <MenuIcon
                        color="primary"
                        sx={{ color: '#3B4D7D' }}
                        fontSize="medium"
                    />
                </IconButton>
            </Box>
            <Box
                sx={{
                    ml: '8px',
                    width: 180,
                    display: 'flex',
                    height: '60px',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto',
                    },
                }}
            >
                <Box
                    component="span"
                    sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
                >
                    <LogoSection />
                </Box>
            </Box>
            <CurrencyTag user={user} />
            {/* header search */}
            {/* <SearchSection /> */}
            {/* <Box sx={{ flexGrow: 1 }} /> */}
            <Box sx={{ flexGrow: 1 }} />

            {bookingVisible && (
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mr: '14px' }}
                    onClick={() => setBookingOpen(true)}
                >
                    Create Booking
                </Button>
            )}
            {bookingOpen && (
                <BookingModal
                    handleCancel={() => setBookingOpen(false)}
                    // handleConfirm={() => {}}
                    open={bookingOpen}
                    superUser={user?.superUser}
                />
            )}
            {/* <Button variant="contained" color="secondary"> */}
            {/*    New Booking */}
            {/* </Button> */}
            {/* notification & profile */}
            {orderVisible && <OpenOrders />}
            {/* <NotificationSection /> */}
            <ProfileSection user={user} setSuperUser={setSuperUser} />
        </>
    );
}

export default React.memo(Header);
