// import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
// import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import FormatBoldOutlinedIcon from '@mui/icons-material/FormatBoldOutlined';
import FormatItalicOutlinedIcon from '@mui/icons-material/FormatItalicOutlined';
// import FormatUnderlinedOutlinedIcon from '@mui/icons-material/FormatUnderlinedOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined';
import FormatAlignRightOutlinedIcon from '@mui/icons-material/FormatAlignRightOutlined';
import FormatAlignJustifyOutlinedIcon from '@mui/icons-material/FormatAlignJustifyOutlined';
// import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
// import CodeIcon from '@mui/icons-material/Code';
// import ImageIcon from '@mui/icons-material/Image';

// import FormatHeader1 from '@mui/icons-material/FormatQuote';
import FormatHeader2 from '@mui/icons-material/FormatQuote';
// import FormatText from '@mui/icons-material/FormatQuote';

export const eventTypes = {
    paragraph: 'paragraph',
    h1: 'h1',
    h2: 'h2',
    ul: 'ul',
    ol: 'ol',
    quote: 'quote',
    formatCode: 'formatCode',
    formatUndo: 'formatUndo',
    formatRedo: 'formatRedo',
    formatBold: 'formatBold',
    formatItalic: 'formatItalic',
    formatUnderline: 'formatUnderline',
    formatStrike: 'formatStrike',
    formatInsertLink: 'formatInsertLink',
    formatAlignLeft: 'formatAlignLeft',
    formatAlignCenter: 'formatAlignCenter',
    formatAlignRight: 'formatAlignRight',
    incrementFontSize: 'incrementFontSize',
    decrementFontSize: 'decrementFontSize',
};

export interface IconType {
    id: number;
    Icon: any;
    event: string;
    text?: string;
    group: string;
}

// Default to be format text
// Font Size dropdown
const pluginsList: IconType[] = [
    {
        id: 1,
        Icon: TextIncreaseIcon,
        event: eventTypes.incrementFontSize,
        group: 'text-size',
    },
    {
        id: 2,
        Icon: TextDecreaseIcon,
        event: eventTypes.decrementFontSize,
        group: 'text-size',
    },
    // {
    //     id: 3,
    //     Icon: FormatHeader2,
    //     event: eventTypes.h2,
    //     text: 'H1',
    //     group: 'text-format',
    // },
    {
        id: 4,
        Icon: FormatListBulletedIcon,
        event: eventTypes.ul,
        group: 'text-format',
    },

    {
        id: 5,
        Icon: FormatListNumberedIcon,
        event: eventTypes.ol,
        group: 'text-format',
    },
    // {
    //     id: 6,
    //     Icon: FormatQuoteIcon,
    //     event: eventTypes.quote,
    // },
    // {
    //     id: 7,
    //     Icon: CodeIcon,
    //     event: eventTypes.formatCode,
    // },
    // {
    //     id: 8,
    //     Icon: UndoOutlinedIcon,
    //     event: eventTypes.formatUndo,
    // },
    // {
    //     id: 9,
    //     Icon: RedoOutlinedIcon,
    //     event: eventTypes.formatRedo,
    // },
    {
        id: 10,
        Icon: FormatBoldOutlinedIcon,
        event: eventTypes.formatBold,
        group: 'text-style',
    },
    {
        id: 11,
        Icon: FormatItalicOutlinedIcon,
        event: eventTypes.formatItalic,
        group: 'text-style',
    },
    // {
    //     id: 12,
    //     Icon: FormatUnderlinedOutlinedIcon,
    //     event: eventTypes.formatUnderline,
    // },
    // { // reactive it if you need it
    //   id: 13,
    //   Icon: StrikethroughSOutlinedIcon,
    //   event: eventTypes.formatStrike,
    // },
    {
        id: 14,
        Icon: InsertLinkOutlinedIcon,
        event: eventTypes.formatInsertLink,
        group: 'link',
    },
    {
        id: 15,
        Icon: FormatAlignLeftOutlinedIcon,
        event: eventTypes.formatAlignLeft,
        group: 'alignment',
    },

    {
        id: 16,
        Icon: FormatAlignJustifyOutlinedIcon,
        event: eventTypes.formatAlignCenter,
        group: 'alignment',
    },
    {
        id: 17,
        Icon: FormatAlignRightOutlinedIcon,
        event: eventTypes.formatAlignRight,
        group: 'alignment',
    },
];

export default pluginsList;
