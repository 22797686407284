/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EventStatus } from './EventStatus';
import type { GetEventResAccessDto } from './GetEventResAccessDto';
import type { GetEventResBookingsItem0Dto } from './GetEventResBookingsItem0Dto';
import type { GetEventResBookingsItem1Dto } from './GetEventResBookingsItem1Dto';
import type { GetEventResNotesDto } from './GetEventResNotesDto';
import type { GetEventResOperationsDto } from './GetEventResOperationsDto';
import type { GetEventResProductDto } from './GetEventResProductDto';
import type { GetUserResCreatedDto } from './GetUserResCreatedDto';
import type { PeriodData } from './PeriodData';

export type GetEventResDto = {
    /**
     * Type of the resource
     */
    resource_type: GetEventResDto.resource_type;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<GetUserResCreatedDto>;
    /**
     * For shared events, indicates whether more bookings can be accepted
     */
    locked: boolean;
    /**
     * Product selected for the event
     */
    product: GetEventResProductDto;
    /**
     * Timestamp which represents date and time of the event.
     */
    date: PeriodData;
    /**
     * Event operations
     */
    operations?: GetEventResOperationsDto;
    /**
     * List of notes of some importance to the booking
     */
    notes?: GetEventResNotesDto;
    /**
     * Automatically computed status of the booking.
     */
    status: EventStatus;
    /**
     * Access to the shared product
     */
    access: GetEventResAccessDto;
    /**
     * Access to the shared product
     */
    bookings: Array<(GetEventResBookingsItem0Dto | GetEventResBookingsItem1Dto)>;
};

export namespace GetEventResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        EVENT = 'event',
    }


}
