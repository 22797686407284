import React from 'react';
import { Paper, Stack } from '@mui/material';
import { Switch } from '@travelity/form';
import { useFormContext } from 'react-hook-form';
import { UserSubscription } from '@travelity/api';
import { useUserContext } from '../../contexts/user';

export interface ProductConfigurationFormProps {
    hideDisabled?: boolean;
    preview?: boolean;
}

const ProductConfigurationForm: React.FC<ProductConfigurationFormProps> = ({
    hideDisabled,
    preview,
}) => {
    const { user } = useUserContext();
    const disableAutoscaled = (user?.subscription as UserSubscription).user
        ?.product
        ? !(user?.subscription as UserSubscription).user?.product?.autoscaled
        : false;
    const { watch } = useFormContext();
    const hide = hideDisabled && preview;

    return (
        <Stack spacing={2}>
            {(!hide || watch('shared')) && (
                <Paper
                    sx={{
                        py: 1,
                        px: 2,
                        maxWidth: '388px',
                        ...(preview
                            ? {
                                  opacity: 0.6,
                                  pointerEvents: 'none',
                              }
                            : {}),
                    }}
                >
                    <Switch
                        name="shared"
                        label="Group Tour"
                        LabelProps={{ sx: { color: '#2B395B' } }}
                    />
                </Paper>
            )}
            {(!hide || watch('autoscaled')) && (
                <Paper
                    sx={{
                        '&&': { mt: 1.5 },
                        py: 1,
                        px: 2,
                        maxWidth: '388px',
                        ...(preview
                            ? {
                                  opacity: 0.6,
                                  pointerEvents: 'none',
                              }
                            : {}),
                    }}
                >
                    <Switch
                        name="autoscaled"
                        label="Autoscaled"
                        LabelProps={{ sx: { color: '#2B395B' } }}
                        disabled={disableAutoscaled}
                    />
                </Paper>
            )}
        </Stack>
    );
};

export default React.memo(ProductConfigurationForm);
