import { useController, useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    InputProps as MuiInputProps,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select as MuiSelect,
    SelectChangeEvent,
    SelectProps as MuiSelectProps,
} from '@mui/material';
import { minutesToTime } from '@travelity/utils';

export interface SelectTimesProps extends MuiSelectProps {
    width?: string;
    size?: 'small' | 'medium';
    name: string;
    label: string;
    placeholder?: string;
    required?: boolean;
    InputProps?: MuiInputProps;
}

export const minutesToUserFriendly = (times: number[]): string[] => {
    return (
        times.reduce((arr: { start: number; end: number }[], m: number) => {
            if (arr.length === 0) return [{ start: m, end: m }];
            const last = arr[arr.length - 1];
            if (last.end === m - 15) {
                last.end = m;
                return arr;
            }
            if (last.end === last.start + 15) {
                const splitLast = {
                    start: last.end,
                    end: last.end,
                };
                last.end = last.start;
                return [...arr, splitLast, { start: m, end: m }];
            }
            return [...arr, { start: m, end: m }];
        }, []) as { start: number; end: number }[]
    ).map(({ start, end }) =>
        start === end
            ? minutesToTime(start)
            : `${minutesToTime(start)} - ${minutesToTime(end)}`
    );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const SelectTimes: React.FC<SelectTimesProps> = ({
    width,
    size,
    name,
    required,
    label,
    placeholder,
    InputProps = {},
    ...selectProps
}) => {
    const {
        control,
        // formState: { errors },
    } = useFormContext();
    const {
        field: { value: values, onChange },
    } = useController({
        name,
        control,
        defaultValue: [],
    });

    const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(
        null
    );

    const options = Array.from(Array(4 * 24)).map((v, i) => {
        const minutes = i * 15;
        return {
            label: minutesToTime(minutes),
            value: minutes,
        };
    });

    const handleChange = (event: SelectChangeEvent<typeof values>) => {
        const {
            target: { value },
        } = event;
        if (
            !event.shiftKey ||
            lastSelectedIndex === null ||
            typeof value === 'string'
        ) {
            const values =
                // On auto-fill we get a string value.
                typeof value === 'string' ? value.split(',') : value;
            values.sort((a, b) => a - b);
            onChange(values);
        }
    };

    const handleItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number
    ) => {
        const newValues = [...values];
        if (event.shiftKey && lastSelectedIndex !== null) {
            const checked = newValues.includes(options[index].value);
            const start = Math.min(lastSelectedIndex, index);
            const end = Math.max(lastSelectedIndex, index);
            for (let i = start; i <= end; i++) {
                if (checked) {
                    if (newValues.includes(options[i].value)) {
                        const valueIndex = newValues.indexOf(options[i].value);
                        newValues.splice(valueIndex, 1);
                    }
                } else {
                    if (!newValues.includes(options[i].value)) {
                        newValues.push(options[i].value);
                    }
                }
            }
            event.preventDefault();
            event.stopPropagation();
            newValues.sort((a, b) => a - b);
            onChange(newValues);
        }
        setLastSelectedIndex(index);
    };

    return (
        <FormControl sx={{ width: 1 }}>
            <InputLabel>{label}</InputLabel>
            <MuiSelect
                multiple
                value={values}
                name={name}
                onChange={handleChange}
                input={<OutlinedInput label={label} {...InputProps} />}
                displayEmpty
                renderValue={selected => {
                    if (selected.length === 0 && placeholder) {
                        return (
                            <Box
                                component="span"
                                sx={{
                                    color: '#949BAC',
                                }}
                            >
                                {placeholder}
                            </Box>
                        );
                    }
                    return minutesToUserFriendly(selected).join(', ');
                }}
                MenuProps={{
                    MenuListProps: {
                        sx: {
                            display: 'flex',
                            flexWrap: 'wrap',
                        },
                    },
                    PaperProps: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 250,
                        },
                    },
                    disableAutoFocusItem: true,
                }}
                {...selectProps}
                sx={{
                    ...(selectProps.sx || {}),
                    '&& .MuiSelect-select': { whiteSpace: 'wrap' },
                }}
            >
                {options.map(({ label: l, value }, index) => (
                    <MenuItem
                        sx={{
                            pl: 0,
                            pr: 0.75,
                            py: 0.75,
                            width: 0.25,
                            // borderRadius: '8px',
                        }}
                        key={value}
                        value={value}
                        onClick={event => handleItemClick(event, index)}
                    >
                        <Checkbox
                            sx={{ p: 0.75, pr: 0.5 }}
                            checked={values.indexOf(value) > -1}
                        />
                        <ListItemText sx={{ pt: 0.5 }} primary={l} />
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    );
};

export default SelectTimes;
