/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateManyCapacitiesReqDto } from '../models/CreateManyCapacitiesReqDto';
import type { CreateManyCapacitiesResDto } from '../models/CreateManyCapacitiesResDto';
import type { GetCapacitiesResDto } from '../models/GetCapacitiesResDto';
import type { GetCapacityResDto } from '../models/GetCapacityResDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CapacitiesService {

    /**
     * List/Filter Capacities
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param ids List of ids to return
     * @param pageNumber ID/Number of the page to open
     * @param pageSize number of items returned
     * @param startAtId Id of the event to start the next page with
     * @returns GetCapacitiesResDto Successful Response
     * @throws ApiError
     */
    public static getCapacities(
authorization: string,
ids?: Array<string>,
pageNumber?: number,
pageSize: number = 20,
startAtId?: string,
): CancelablePromise<GetCapacitiesResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/capacities',
            headers: {
                'authorization': authorization,
            },
            query: {
                'ids': ids,
                'page_number': pageNumber,
                'page_size': pageSize,
                'start_at_id': startAtId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create capacities
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns CreateManyCapacitiesResDto Successful Response
     * @throws ApiError
     */
    public static createManyCapacities(
authorization: string,
requestBody: CreateManyCapacitiesReqDto,
): CancelablePromise<CreateManyCapacitiesResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/capacities',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Fetch capacity
     * @param capacityId Id of the capacity being fetched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns GetCapacityResDto Successful Response
     * @throws ApiError
     */
    public static getCapacity(
capacityId: string,
authorization: string,
): CancelablePromise<GetCapacityResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/capacities/{capacity_id}',
            path: {
                'capacity_id': capacityId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
