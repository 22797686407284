import React from 'react';
import { Box, Chip, ChipProps } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';

export enum TagStatus {
    SUCCESS = 'success',
    ERROR = 'error',
    BLOCK = 'block',
}

export interface TagProps extends Partial<ChipProps> {
    label?: string;
    valueProps?: {
        sx?: Record<string, any>;
    };

    values?: (string | number)[];
    value?: string | number;
    status?: TagStatus;
}

export const Tag: React.FC<TagProps> = ({
    label,
    valueProps = {},
    values,
    value,
    status,
    ...rest
}) => {
    const valueList = value !== undefined ? [value] : values;

    return (
        <Chip
            {...rest}
            sx={{
                ...(rest.sx || {}),
                ...(label
                    ? {
                          '& .MuiChip-label': {
                              whiteSpace:
                                  valueList?.length && valueList.length > 1
                                      ? 'wrap'
                                      : 'nowrap',
                              mt: '-4px',
                              ...(!!status && {
                                  display: 'flex',
                                  alignItems: 'center',
                                  mb: '-4px',
                                  height: '30px',
                              }),
                          },
                          ...(!!rest.onClick && {
                              '&&&:hover': {
                                  boxShadow:
                                      '0px 1px 12px rgba(178, 185, 205, 0.6)',
                              },
                          }),
                      }
                    : {
                          '&&': valueProps.sx || {},
                          color: '#9198AA',
                          background: 'rgba(85, 181, 207, 0.2)',
                          p: '8px 10px',
                          fontSize: '14px',
                          boxShadow: 0,
                      }),
            }}
            variant="outlined"
            color="primary"
            size="small"
            label={
                <>
                    {label && (
                        <>
                            <Box
                                sx={{ px: 0.5, fontWeight: 400 }}
                                component="span"
                            >
                                {label}
                            </Box>
                            {valueList?.map(value => (
                                <Box
                                    component="span"
                                    key={value}
                                    {...valueProps}
                                    sx={{
                                        '&&': valueProps.sx || {},
                                        background: 'rgba(85, 181, 207, 0.2)',
                                        color: '#9198AA',
                                        lineHeight: '14px',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        display: 'inline-block',
                                        textAlign: 'center',
                                        borderRadius: '12px',
                                        minWidth: '25px',
                                        height: '22px',
                                        py: 0.5,
                                        px: 1,
                                        ml: 0.5,
                                        mt: 0.5,
                                    }}
                                >
                                    <span>{value}</span>
                                </Box>
                            ))}
                            {status && (
                                <Box
                                    component="span"
                                    sx={{
                                        // border: '1px solid',
                                        // borderColor:
                                        //     status === TagStatus.SUCCESS
                                        //         ? 'success.main'
                                        //         : 'error.main',
                                        bgcolor:
                                            status === TagStatus.SUCCESS
                                                ? 'success.main'
                                                : status === TagStatus.ERROR
                                                ? 'error.main'
                                                : 'rgba(86, 93, 109, 0.4)',
                                        color: '#FFF',
                                        lineHeight: '14px',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        display: 'inline-block',
                                        textAlign: 'center',
                                        borderRadius: '12px',
                                        width: '22px',
                                        height: '22px',
                                        py: '4px',
                                        px: '1px',
                                    }}
                                >
                                    {status === TagStatus.SUCCESS ? (
                                        <CheckIcon
                                            sx={{ fontSize: '14px' }}
                                            color="inherit"
                                        />
                                    ) : status === TagStatus.ERROR ? (
                                        <CloseIcon
                                            sx={{ fontSize: '14px' }}
                                            color="inherit"
                                        />
                                    ) : (
                                        <RemoveIcon
                                            sx={{ fontSize: '14px' }}
                                            color="inherit"
                                        />
                                    )}
                                </Box>
                            )}
                        </>
                    )}
                    {!label && valueList?.length && valueList[0]}
                </>
            }
        />
    );
};
