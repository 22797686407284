import { useFieldArray, useFormContext } from 'react-hook-form';
import React, { useRef } from 'react';
import { Box, Stack, TextFieldProps, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TagListItem } from '@travelity/ui';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import FilterIcon from '@mui/icons-material/Filter';

export interface AttachmentsProps {
    name: string;
    required?: boolean;
    regEx?: RegExp;
    showErrors?: boolean;
    chipSx?: Record<string, any>;
    inputProps?: Partial<TextFieldProps>;
    readOnly?: boolean;
}

const getSize = (file: File) => {
    return new Promise(resolve => {
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);
        img.onload = function () {
            resolve({
                // @ts-ignore
                width: this.width as number,
                // @ts-ignore
                height: this.height as number,
            });
        };
        img.src = objectUrl;
    });
};

const toBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            console.log(reader);
            resolve(reader.result as string);
        };
        reader.onerror = reject;
    });

const Attachments: React.FC<AttachmentsProps> = props => {
    const { name, readOnly = false } = props;
    const { control } = useFormContext();

    const {
        fields: files,
        remove,
        append,
    } = useFieldArray<Record<string, any>, string, string>({
        name,
        control,
    });

    const handleImgChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        if (e.target.files) {
            Array.from(e.target.files).forEach(file => {
                toBase64(file).then((data: string) => {
                    const contentStart = data.indexOf(',');
                    if (data.startsWith('data:image')) {
                        // @ts-ignore
                        getSize(file).then(({ width, height }) => {
                            append({
                                name: file.name,
                                contentType: file.type,
                                content: data.substring(contentStart + 1),
                                width: width,
                                height: height,
                            });
                        });
                    } else {
                        append({
                            name: file.name,
                            contentType: file.type,
                            content: data.substring(contentStart + 1),
                        });
                    }
                });
            });
            e.target.value = '';
        }
    };
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <Stack direction="column" gap={1}>
            {files
                .filter(f => !readOnly || !!f.url)
                .map(({ name, contentType, url }, index) => (
                    <TagListItem
                        key={`${name}-${index}`}
                        label={
                            <Stack
                                direction="row"
                                alignItems="center"
                                gap={0.5}
                            >
                                {contentType === 'application/pdf' ? (
                                    <PictureAsPdf sx={{ fontSize: '20px' }} />
                                ) : (
                                    <FilterIcon sx={{ fontSize: '20px' }} />
                                )}
                                <Box
                                    sx={{
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        lineHeight: 2,
                                    }}
                                >
                                    {name}
                                </Box>
                            </Stack>
                        }
                        deleteIcon={
                            <Tooltip title="Remove">
                                <CloseIcon />
                            </Tooltip>
                        }
                        onDelete={readOnly ? undefined : () => remove(index)}
                        onClick={
                            readOnly
                                ? () => window.open(url, '_blank')
                                : undefined
                        }
                        sx={{
                            borderRadius: '12px',
                            position: 'relative',
                            px: 2,
                            py: 1,
                            m: 0,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '10px',
                            bgcolor: '#FFF',
                            height: '34px',
                            boxShadow:
                                '0px 1px 12px 0px rgba(178, 185, 205, 0.30)',
                            '&:hover': {
                                boxShadow:
                                    '0px 1px 12px 0px rgba(178, 185, 205, 0.30)',
                            },
                            '& > .MuiChip-label': {
                                color: '#9198AA',
                                fontSize: '14px',
                                overflow: 'hidden',
                            },
                            '& > .MuiChip-icon': {
                                color: '#9198AA',
                                fontSize: '18px',
                                whiteSpace: 'wrap',
                            },
                        }}
                    />
                ))}
            {!readOnly && (
                <Button
                    variant="contained"
                    color="neutral"
                    fullWidth
                    onClick={() => inputRef.current?.click()}
                    sx={{ mt: 1 }}
                >
                    <UploadIcon /> Upload
                </Button>
            )}
            <Box
                component="input"
                type="file"
                multiple
                accept="image/jpeg,image/jpg,image/png,application/pdf"
                onChange={handleImgChange}
                ref={inputRef}
                sx={{
                    display: 'none',
                }}
            />
        </Stack>
    );
};

export default Attachments;
