import React, { useCallback, useEffect, useMemo } from 'react';
import {
    EventItem,
    useCapacity,
    useUpdateEventOperations,
    EventStatuses,
    useUpdateEventNotes,
} from '@travelity/api';
import { useForm } from '@travelity/form';
import _ from 'lodash';
import { useWatch } from 'react-hook-form';
import { NoteVisibility } from '@travelity/api/src/requests';
import { useSnackbar } from 'notistack';
import travelersSchema from '../../../components/booking-travelers/booking-travelers.schema';
import { BookingTravelersForm } from '../../../components/booking-travelers';
import { BookingScheduleForm } from '../../../components/booking-schedule';
import { ActivityHistory } from '../../../components/activity-history';
import { EventOperations } from '../../../components/event-operations';
import { SideFormAccordion } from '../../../components/side-form-accordion';
import { useSideFormAccordion } from '../../../components/side-form-accordion/side-form-accordion.hooks';
import { SidebarContent } from '../../../components/sidebar-content';
import { useResourcePermissions } from '../../../contexts/user';
import { EventProductForm } from '../../../components/event-product';
import notesSchema from '../../../components/booking-notes/booking-notes.schema';
import EventNotesForm from '../../../components/event-notes/event-notes';

export interface EventPreviewProps {
    event: EventItem;
    onClose: () => void;
}

export const eventBookingsToEventTravelers = (event: EventItem) => {
    return (
        event.bookings.map(booking => ({
            bookingId: booking.id,
            travelers: booking.participants.travelers,
            customer: booking.participants.customer,
        })) || []
    );
};

const EventPreview: React.FC<EventPreviewProps> = props => {
    const { event, onClose } = props;
    const permissions = useResourcePermissions('event');
    const { enqueueSnackbar } = useSnackbar();

    const { view, openTab, isEdit } = useSideFormAccordion();

    const readonly =
        event.status === EventStatuses.CANCELLED || !permissions.update;

    const { Form: TravelersForm, reset: resetTravelersForm } = useForm({
        defaultValues: {
            travelers: eventBookingsToEventTravelers(event),
        },
        mode: 'onChange',
        schema: travelersSchema,
    });

    useEffect(() => {
        resetTravelersForm({
            travelers: eventBookingsToEventTravelers(event),
        });
    }, [event]);

    // Operations
    const { mutate: updateOperations, isLoading: isOperationsSaving } =
        useUpdateEventOperations(
            event.assets.filter(i => !i.pinned),
            event.staff.filter(i => !i.pinned),
            event.product.type,
            event.capacity.id,
            {
                onSuccess: () => view(),
            }
        );

    const { mutate: updateNotes, isLoading: isNotesSaving } =
        useUpdateEventNotes(event.notes || [], {
            onSuccess: () => {
                view();
            },
            onError: (error: any) => {
                if (error?.status === 409) {
                    enqueueSnackbar('Nothing to save', {
                        variant: 'error',
                    });
                } else if (error?.body) {
                    enqueueSnackbar(error.body, { variant: 'error' });
                }
            },
        });

    const operationFormValues = useMemo(
        () => ({
            syncPricing: true,
            capacity: event.capacity,
            staff: event.staff
                .filter(staff => staff.reference)
                .map(v => ({
                    ...v.reference,
                    uuid: v.id,
                    position: v.position,
                })),
            assets: event.assets
                .filter(asset => asset.reference)
                .map(v => ({ ...v.reference, uuid: v.id })),
        }),
        [event]
    );
    const {
        Form: OperationsForm,
        reset: resetOperationsForm,
        control,
        submit: operationsSubmit,
        formState: { isDirty: isOperationsDirty },
    } = useForm({
        values: operationFormValues,
        onSubmit: useCallback(
            (data: Record<string, any>) => {
                updateOperations({
                    staff: data.staff.map(
                        ({
                            uuid,
                            position,
                            ...reference
                        }: {
                            uuid?: string;
                        }) => ({
                            id: uuid,
                            position,
                            reference,
                        })
                    ),
                    assets: data.assets.map(
                        ({ uuid, ...reference }: { uuid?: string }) => ({
                            id: uuid,
                            reference,
                        })
                    ),
                    eventId: event.id,
                    capacityId: data.capacity.id,
                    syncPricing: !!data.syncPricing,
                });
            },
            [event, updateOperations]
        ),
        mode: 'onChange',
    });

    const {
        Form: NotesForm,
        reset: resetNotes,
        submit: notesSubmit,
        formState: { errors: notesErrors, isDirty: isNotesDirty },
    } = useForm({
        values: {
            notes:
                event.notes?.map(({ id, content, visibility }) => ({
                    id,
                    value: content,
                    type: visibility,
                })) || [],
        },
        onSubmit: useCallback(
            (data: any, e?: React.BaseSyntheticEvent) => {
                e?.preventDefault();
                updateNotes({
                    eventId: event.id,
                    notes: data.notes?.map(
                        ({
                            id,
                            value,
                            type,
                        }: {
                            id: string;
                            value: string;
                            type: NoteVisibility;
                        }) => ({
                            id,
                            content: value,
                            visibility: type,
                        })
                    ),
                });
            },
            [event.id]
        ),
        mode: 'onChange',
        schema: notesSchema,
    });

    const onOperationsEditCancel = useCallback(() => {
        resetOperationsForm();
        view();
    }, [event, view]);

    const onEditNotesCancel = useCallback(() => {
        resetNotes();
        view();
    }, [event, view]);

    const selectedCapacity = useWatch({ control, name: 'capacity' });
    const { data: capacity, isLoading } = useCapacity(selectedCapacity?.id);

    return (
        <SidebarContent title="Event Preview" onClose={onClose}>
            <SideFormAccordion id="schedule" title="Date and Time">
                <BookingScheduleForm date={event.date} preview />
            </SideFormAccordion>
            <SideFormAccordion id="product" title="Product">
                <EventProductForm product={event.product} preview />
            </SideFormAccordion>
            <SideFormAccordion
                isEditable={false}
                id="travelers"
                title="Travelers"
            >
                <TravelersForm id="travelers">
                    <BookingTravelersForm readonly={!permissions.update} />
                </TravelersForm>
            </SideFormAccordion>
            <SideFormAccordion
                id="operations"
                title="Operations"
                isEditable={!readonly}
                isSaving={isOperationsSaving}
                notDirty={!isOperationsDirty || !selectedCapacity}
                onCancel={onOperationsEditCancel}
                onSave={operationsSubmit}
            >
                <OperationsForm id="operations">
                    <EventOperations
                        event={event}
                        route={event.route}
                        capacity={capacity}
                        loadingCapacity={isLoading}
                        allowedAssetTypes={
                            event.assets.length ? ['vehicle'] : []
                        }
                        allowedStaffPositions={_.uniq(
                            event.staff.map(staff => staff.position)
                        )}
                        preview={!isEdit || openTab !== 'operations'}
                    />
                </OperationsForm>
            </SideFormAccordion>

            <SideFormAccordion
                id="notes"
                title="Notes"
                isEditable={permissions.update}
                onCancel={onEditNotesCancel}
                isSaving={isNotesSaving}
                notDirty={!isNotesDirty}
                onSave={notesSubmit}
                errors={notesErrors}
            >
                <NotesForm id="notes">
                    <EventNotesForm preview={!isEdit || openTab !== 'notes'} />
                </NotesForm>
            </SideFormAccordion>
            <SideFormAccordion id="activity-log" title="Activity Log">
                <ActivityHistory entity="event" lifecycle={event.lifecycle} />
            </SideFormAccordion>
        </SidebarContent>
    );
};

export default React.memo(EventPreview);
