import * as Sentry from '@sentry/react';

const environments: Record<string, string> = {
    my: 'production',
    dev: 'dev',
    staging: 'staging',
};
const [subDomain] = window.location.hostname.split('.travelity.app');
const env = subDomain ? environments[subDomain] : undefined;

export const feedback = Sentry.feedbackIntegration({
    // Disable the injection of the default widget
    autoInject: false,
});

if (env) {
    Sentry.init({
        dsn: 'https://332f815d698d2acdebeba9c05de51a6d@o4508946673762304.ingest.us.sentry.io/4508954910982144',

        environment: env,
        tracesSampleRate: 1.0,
        integrations: [feedback, Sentry.browserTracingIntegration()],
    });
}
