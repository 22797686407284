import { SourceType } from '@travelity/api';

export interface FilterPaxValue {
    infants?: number;
    children?: number;
    youth?: number;
    adults?: number;
    seniors?: number;
}

export interface FilterDateValue {
    startDate: Date;
    endDate: Date;
}

export interface FilterOptionValue {
    label: string;
    value: string;
}

export interface FilterSourceValue {
    type: SourceType;
    name?: string;
    referral?: string;
}

export interface FilterPriceValue {
    currency?: string;
    minPrice?: number;
    maxPrice?: number;
}

export type FilterValue =
    | FilterDateValue
    | FilterPaxValue
    | FilterPriceValue
    | Date
    | FilterOptionValue
    | string
    | string[];

export interface FilterForm {
    value: FilterValue;
}

export enum FilterTypes {
    PAX,
    DROPDOWN,
    SEARCH_PRODUCT,
    SEARCH,
    DATES,
    DATE,
    PRICE,
    TAGS,
    KEYWORD,
    SOURCE,
}

export interface FilterOption<T extends FilterTypes = FilterTypes> {
    name: string;
    label: string;
    type: T;
    required?: boolean;
    multiple?: boolean;
    preventPast?: boolean;
    selectText?: string;
    disable?: (used: string[]) => boolean;
    options?: {
        value: string;
        label: string;
    }[];
}
