import React, { useCallback } from 'react';
import { Alert, Stack, Typography } from '@mui/material';
import { SelectCurrency, useForm } from '@travelity/form';
import { Button } from '@travelity/ui';
import { object, string } from 'yup';

import WarningIcon from '@mui/icons-material/Warning';

const schema = object().shape({
    currency: string().required('Currency is required'),
});

export interface CurrencyStepProps {
    currency?: string;
    back: () => void;
    next: (v: string) => void;
}

export const CurrencyStep: React.FC<CurrencyStepProps> = props => {
    const { currency, next, back } = props;

    const [showError, setShowErrors] = React.useState(false);

    const { Form, watch } = useForm({
        schema,
        onSubmit: useCallback(
            (data: { currency: string }) => {
                next(data.currency);
            },
            [next]
        ),
        defaultValues: { currency },
        mode: 'onChange',
        validateInitially: true,
    });

    return (
        <Form>
            <Stack
                sx={{
                    py: 5,
                    height: 1,
                    minHeight: 'calc(100vh - 84px)',
                }}
                gap={2}
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    sx={{
                        color: '#C0C4CE',
                        fontSize: '24px',
                    }}
                >
                    What is your base currency?
                </Typography>
                <Alert
                    severity="warning"
                    icon={
                        <WarningIcon
                            sx={{
                                color: '#EC8031',
                                fontSize: '24px',
                            }}
                        />
                    }
                    sx={{
                        width: '388px',
                        alignItems: 'center',
                        color: '#2B395B',
                        my: 1,
                        '.MuiAlert-action': { pt: 0 },
                    }}
                >
                    You won&apos;t be able to change the base currency after
                    creating your profile
                </Alert>
                <SelectCurrency
                    placeholder="Select base currency"
                    name="currency"
                    label="Currency"
                    showErrors={showError}
                />
                <Stack direction="row" spacing={1} sx={{ width: '388px' }}>
                    <Button
                        variant="contained"
                        color="neutral"
                        sx={{ flexGrow: 1 }}
                        onClick={back}
                    >
                        Back
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        sx={{
                            px: 10,
                            py: 1,
                        }}
                        doNotDisable
                        onIconClick={() => {
                            setShowErrors(true);
                        }}
                        requiredFields={
                            !watch('currency')?.length
                                ? ['Currency']
                                : undefined
                        }
                    >
                        Continue
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
};
