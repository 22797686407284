import React, { useMemo } from 'react';
import { Divider, Stack } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
    Pricing,
    ProductCapacityItem,
    ProductScheduleItem,
} from '@travelity/api';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import _ from 'lodash';
import { PricingListItem } from '../pricing-list-item';
import { useUserContext } from '../../../../contexts/user';

export interface PricingListProps {
    item: Record<string, any>;
    editing: boolean;
    capacities: ProductCapacityItem[];
    schedules: ProductScheduleItem[];
}

export const PricingList: React.FC<PricingListProps> = props => {
    const { item, editing, capacities, schedules } = props;
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const {
        fields: items,
        append,
        remove,
    } = useFieldArray({
        name: 'prices',
        control,
    });
    const prices = (editing ? items : item.prices) || [];

    const { user } = useUserContext();
    const currency = user?.currency || 'AMD';

    const usedDependencies = useMemo(() => {
        return prices
            ?.filter((i: Pricing) => !!i.schedule || !!i.capacity)
            .map((i: Pricing) => ({
                schedule: i.schedule?.id,
                capacity: i.capacity?.id,
            }));
    }, []);

    const error = !!_.get(errors, 'prices');

    return (
        <>
            <Divider
                sx={{
                    mt: 2,
                    '& .MuiDivider-wrapper': {
                        color: error ? 'error.main' : undefined,
                    },
                }}
            >
                Pricing
            </Divider>
            <Stack gap={1}>
                {prices.map((price, i) => (
                    <PricingListItem
                        /* eslint-disable-next-line */
                        key={price.id || `prices.${price.capacity?.name}.${i}`}
                        index={i}
                        remove={remove}
                        item={price}
                        editing={editing}
                        prefix={`prices.${i}`}
                        usedDependencies={usedDependencies}
                        capacities={capacities}
                        schedules={schedules}
                    />
                ))}
                {editing && (
                    <Button
                        sx={{
                            bgcolor: '#FFFFFF',
                        }}
                        variant="outlined"
                        className="add-button"
                        fullWidth
                        onClick={() => {
                            append({ currency, new: true });
                        }}
                        data-testid="add-card"
                    >
                        <Add /> Add Pricing
                    </Button>
                )}
            </Stack>
        </>
    );
};
