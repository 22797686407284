import React from 'react';
import { Box, Drawer, Typography, useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { ReactComponent as PreviewSvg } from './assets/preview.svg';

export interface ListSidebarProps {
    children?: React.ReactNode;
    isEmpty?: boolean;
    placeholderText?: string;
    onClose?: () => void;
}

export const ListSidebar: React.FC<ListSidebarProps> = React.memo(
    (props: ListSidebarProps) => {
        const { children, isEmpty, placeholderText, onClose } = props;
        const theme = useTheme();
        const tablet = useMediaQuery(theme.breakpoints.down('md'));

        return tablet ? (
            <Drawer
                open={!isEmpty}
                variant="temporary"
                anchor="right"
                onClose={onClose}
                sx={{
                    top: { xs: 0, sm: '76px' },
                }}
                PaperProps={{
                    sx: {
                        borderRadius: 0,
                        top: { xs: 0, sm: '76px' },
                        backgroundColor: '#FFF',
                        maxWidth: '100vw',
                    },
                }}
                slotProps={{
                    backdrop: {
                        sx: {
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            top: { xs: 0, sm: '61px' },
                        },
                    },
                }}
            >
                {children}
            </Drawer>
        ) : (
            <Box
                className="hide-on-print"
                sx={{
                    bgcolor: '#FFF',
                    my: 2,
                    ml: 2,
                    width: '483px',
                    minWidth: '483px',
                    position: 'relative',
                    height: 'calc(100% - 32px)',
                    ...(isEmpty && {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2,
                    }),
                }}
            >
                {isEmpty ? (
                    <>
                        <PreviewSvg />
                        <Typography sx={{ color: '#949BAC' }}>
                            {placeholderText}
                        </Typography>
                    </>
                ) : (
                    children
                )}
            </Box>
        );
    }
);
