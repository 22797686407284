import React, { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAuth0 } from '@auth0/auth0-react';
import {
    convertAttachmentToAttachmentDto,
    getCustomerDtoToCustomer,
} from './customer.converters';
import {
    CreateCustomerReqDto,
    CreateCustomerResDto,
    CustomersService,
    GetCustomer1ResDto,
    type UpdateCustomerReqDto,
    UpdateCustomerResDto,
} from '../../requests';
import { CustomMutationOptions, CustomQueryOptions } from '../common.types';
import { Attachment, Customer } from './customer.types';
import { convertItemsToActionItemsDto } from '../common.converters';
import { useBookingCache } from '../booking/booking.hooks';

interface UseCustomersData {
    name?: string;
    email?: string;
    phoneNumber?: string;
    pageNumber?: number;
    pageSize?: number;
}

export const useUpdateCustomerAttachments = (
    oldItems: Attachment[],
    options: CustomMutationOptions<
        {
            customerId: string;
            items: Attachment[];
        },
        ReturnType<typeof CustomersService.updateAttachmentsCustomers> | null
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useBookingCache();

    return useMutation({
        mutationFn: async ({ customerId, items }) => {
            const requestItems = convertItemsToActionItemsDto(
                oldItems.map(convertAttachmentToAttachmentDto),
                items.map(convertAttachmentToAttachmentDto),
                'id',
                (oldItem, newItem) => oldItem.id !== newItem.id
            );

            if (requestItems.length) {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return CustomersService.updateAttachmentsCustomers(
                    customerId,
                    authorization,
                    {
                        items: requestItems,
                    }
                );
            }
            return null;
        },
        ...options,
    });
};

const useCustomerDebounceKey = 'useCustomerDebounceKey';
export const useDebouncedCustomers = (
    search: string,
    options?: CustomQueryOptions<
        ReturnType<typeof CustomersService.getCustomer>
    >
) => {
    const [debouncedParams, setDebouncedParams] = React.useState<
        UseCustomersData | undefined
    >();

    React.useEffect(() => {
        const handler = setTimeout(() => {
            const filter: UseCustomersData = {};
            if (search.match(/^\+?[0-9 ]+$/)) filter.phoneNumber = search;
            else if (search.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/))
                filter.email = search;
            else if (search.length > 2) {
                filter.name = search;
            }
            setDebouncedParams(Object.keys(filter).length ? filter : undefined);
        }, 1000);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    const { getAccessTokenSilently } = useAuth0();
    const { data, ...other } = useQuery({
        queryKey: [useCustomerDebounceKey, debouncedParams],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CustomersService.getCustomer(
                authorization,
                debouncedParams?.name,
                debouncedParams?.email,
                debouncedParams?.phoneNumber
            );
        },
        keepPreviousData: false,
        staleTime: 0,
        enabled: !!debouncedParams,
        ...options,
    });

    const parsedData = useMemo(
        () =>
            debouncedParams && data?.items
                ? data?.items.map(getCustomerDtoToCustomer)
                : undefined,
        [debouncedParams, data]
    );

    return {
        data: parsedData,
        ...other,
        isLoading: other.isFetching,
    };
};

export const useAddCustomer = (
    options?: CustomMutationOptions<
        {
            customer: CreateCustomerReqDto;
            attachments: Attachment[];
        },
        CreateCustomerResDto
    >
) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mutateAsync: updateAttachments } = useUpdateCustomerAttachments([]);

    const { getAccessTokenSilently } = useAuth0();
    return useMutation(
        async ({ customer, attachments }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const data = await CustomersService.createCustomer(
                authorization,
                customer
            );
            if (attachments.length > 0) {
                await updateAttachments(
                    {
                        customerId: data.id,
                        items: attachments,
                    },
                    {
                        onError: () => {
                            enqueueSnackbar('Failed to upload file', {
                                variant: 'error',
                            });
                        },
                    }
                );
            }
            return data;
        },
        { ...options }
    );
};

const useCustomerKey = 'useCustomerKey';

export const useUpdateCustomer = (
    customer?: Customer,
    options?: CustomMutationOptions<{
        id: string;
        customer?: UpdateCustomerReqDto;
        attachments?: Attachment[];
    }>
) => {
    const { enqueueSnackbar } = useSnackbar();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();

    const { mutateAsync: updateAttachments } = useUpdateCustomerAttachments(
        customer?.attachments || []
    );

    return useMutation(
        async ({ id, customer: newCustomer, attachments = [] }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;

            let response: Partial<
                Omit<GetCustomer1ResDto | UpdateCustomerResDto, 'rbac'>
            > | null;

            response = await updateAttachments(
                {
                    customerId: id,
                    items: attachments,
                },
                {
                    onError: e => {
                        console.log(e);
                        enqueueSnackbar('Failed to upload file', {
                            variant: 'error',
                        });
                    },
                }
            );

            try {
                if (newCustomer) {
                    response = await CustomersService.updateCustomer(
                        id,
                        authorization,
                        newCustomer
                    );
                }
            } catch (e) {
                response = await CustomersService.getCustomer1(
                    id,
                    authorization
                );
            }

            if (!response) {
                response = await CustomersService.getCustomer1(
                    id,
                    authorization
                );
            }

            queryClient.setQueryData<GetCustomer1ResDto>(
                [useCustomerKey, id],
                // @ts-ignore
                prevCustomer =>
                    prevCustomer
                        ? {
                              ...prevCustomer,
                              ...response,
                          }
                        : undefined
            );

            return response;
        },
        options
    );
};

export const useCustomer = (
    id?: string,
    options: CustomQueryOptions<
        ReturnType<typeof CustomersService.getCustomer1>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();

    const { data, ...rest } = useQuery({
        queryKey: [useCustomerKey, id],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CustomersService.getCustomer1(id as string, authorization);
        },
        enabled: !!id,
        ...options,
    });

    const parsedData = useMemo(
        () => (data ? getCustomerDtoToCustomer(data) : undefined),
        [data]
    );

    return {
        data: parsedData,
        ...rest,
    };
};

export const useCreateCustomer = (
    options?: CustomMutationOptions<
        CreateCustomerReqDto,
        ReturnType<typeof CustomersService.createCustomer>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async requestBody => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CustomersService.createCustomer(authorization, requestBody);
        },
        ...options,
    });
};
