import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';
import { ReactComponent as LoopSvg } from '../../assets/loop.svg';

export interface ListItemsProps {
    children: React.ReactNode;
    items?: number;
    isLoading?: boolean;
    noItemsText?: string;
    noItemsIcon?: React.ReactNode;
    header?: React.ReactNode;
    entityName?: string;
    entityNamePlural?: string;
    subtractHeight?: number;
    hideCountInfo?: boolean;
    scrollbarProps?: ScrollBarProps;
}

export const ListItems: React.FC<ListItemsProps> = React.memo(
    (props: ListItemsProps) => {
        const {
            children,
            items = 0,
            isLoading,
            noItemsText,
            noItemsIcon = <LoopSvg />,
            header,
            entityName,
            entityNamePlural,
            subtractHeight = 0,
            hideCountInfo,
            scrollbarProps,
        } = props;

        return isLoading ? null : (
            <>
                {!hideCountInfo && (
                    <>
                        {items > 1 && (
                            <Divider>
                                {items} {entityNamePlural} found
                            </Divider>
                        )}
                        {items === 1 && <Divider>1 {entityName} found</Divider>}
                        {items === 0 && (
                            <Divider>No {entityNamePlural} found</Divider>
                        )}
                    </>
                )}
                <Box
                    sx={{
                        py: 1,
                        mx: { xs: -2, sm: 0 },
                        bgcolor: items
                            ? { xs: '#F1F1F1', sm: '#F8F9FA' }
                            : undefined,
                        height: `calc(100% - ${
                            subtractHeight + (hideCountInfo ? 0 : 21)
                        }px)`,
                        '& > div': {
                            py: 1,
                        },
                    }}
                >
                    {header}
                    <PerfectScrollbar {...scrollbarProps}>
                        {items ? (
                            <Stack pr={2} pl={2} gap={{ xs: 2, sm: 1 }}>
                                {children}
                            </Stack>
                        ) : (
                            <Stack
                                sx={{
                                    display: 'flex',
                                    height: 1,
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {noItemsIcon}
                                <Typography sx={{ color: '#949BAC' }}>
                                    {noItemsText}
                                </Typography>
                            </Stack>
                        )}
                    </PerfectScrollbar>
                </Box>
            </>
        );
    }
);
