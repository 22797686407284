import React, { useState } from 'react';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Box, Stack, Typography, Button } from '@mui/material';
import { Price } from '../components/price';

export interface SubscriptionStepProps {
    back: () => void;
    next: (v: string) => void;
}

const trial = true;

export const SubscriptionStep: React.FC<SubscriptionStepProps> = props => {
    const { back, next } = props;
    const [isMonthly, setIsMonthly] = useState(false);

    if (trial) {
        return (
            <Stack>
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        background: 'url(https://travelity.app/header.png)',
                        minHeight: 'calc(100vh - 180px)',
                    }}
                >
                    <Box
                        sx={{
                            mt: 8,
                            color: '#3B4D7D',
                            textAlign: 'center',
                            fontSize: '32px',
                            fontWeight: '400',
                            lineHeight: '40px',
                            width: '100%',
                        }}
                    >
                        Thank you for choosing Travelity!
                    </Box>
                    <Box
                        sx={{
                            color: '#3B4D7D',
                            textAlign: 'center',
                            width: '700px',
                            marginTop: '16px',
                        }}
                    >
                        We have a special gift for you!
                    </Box>
                    <Box
                        sx={{
                            mt: 4,
                            width: '700px',
                            textAlign: 'center',
                            color: '#3B4D7D',
                        }}
                    >
                        While Travelity is in beta, you will be able to use the
                        entire bundle of powerful features under our
                        &quot;Team&quot; subscriptions for free. After the beta
                        stage, you will be asked to pick the subscription plan
                        you&apos;d like to continue with.
                    </Box>
                    <Box
                        sx={{
                            textAlign: 'center',
                            width: '700px',
                            marginTop: '8px',
                            color: '#3B4D7D',
                        }}
                    >
                        Until then, enjoy the ride!
                    </Box>
                </Stack>
                <Box
                    sx={{
                        fontSize: '13px',
                        textAlign: 'center',
                        marginTop: '8px',
                        color: '#3B4D7D',
                        '& > a': {
                            color: '#3B4D7D',
                            textDecoration: 'underline',
                        },
                    }}
                >
                    By clicking Go Team Subscription, you agree to our{' '}
                    <a
                        href="https://travelity.app/terms.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Terms
                    </a>
                    ,{' '}
                    <a
                        href="https://travelity.app/privacy.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>{' '}
                    and Cookies Policy.
                </Box>
                <Stack
                    direction="row"
                    gap={8}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Button
                        variant="outlined"
                        size="large"
                        sx={{ width: '100px', mb: 2 }}
                        onClick={back}
                    >
                        Back
                    </Button>
                    <Button
                        sx={{ width: '250px', mt: 2, mb: 4 }}
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => next('team')}
                    >
                        Go Team Subscription
                    </Button>
                </Stack>
            </Stack>
        );
    }

    return (
        <Stack
            sx={{
                py: 5,
                minHeight: 'calc(100vh - 84px)',
            }}
            gap={2}
            alignItems="center"
            justifyContent="center"
        >
            <Typography
                sx={{
                    color: '#3B4D7D',
                    fontSize: '24px',
                    fontWeight: 700,
                }}
            >
                The best work solution, for the best price.
            </Typography>
            <Stack direction="row" gap={4}>
                <Stack direction="row" gap={2}>
                    <TaskAltIcon sx={{ color: '#37C16E' }} />
                    <Typography sx={{ color: '#3B4D7D', fontWeight: 'bold' }}>
                        No hidden fees
                    </Typography>
                </Stack>
                <Stack direction="row" gap={2}>
                    <TaskAltIcon sx={{ color: '#37C16E' }} />
                    <Typography sx={{ color: '#3B4D7D', fontWeight: 'bold' }}>
                        Cancel anytime
                    </Typography>
                </Stack>
            </Stack>
            <Stack
                direction="row"
                sx={{
                    mt: 3,
                    p: '10px',
                    height: '52px',
                    width: '210px',
                    borderRadius: '26px',
                    background: '#F4F6FA',
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        height: 'calc(100% - 20px)',
                        width: 'calc(50% - 10px)',
                        left: !isMonthly ? '10px' : '50%',
                        top: '10px',
                        borderRadius: '16px',
                        transition: 'left 0.2s',
                        backgroundColor: '#FFFFFF',
                    }}
                />
                <Button
                    onClick={() => setIsMonthly(false)}
                    aria-label="yearly"
                    sx={{
                        width: '50%',
                        height: '100%',
                        fontWeight: 'bold',
                        color: !isMonthly ? 'primary.main' : '#2B395B',
                        borderRadius: '8px',
                        '&:hover': {
                            bgcolor: 'transparent',
                        },
                    }}
                    size="large"
                >
                    Yearly
                </Button>
                <Button
                    onClick={() => setIsMonthly(true)}
                    aria-label="Monthly"
                    sx={{
                        width: '50%',
                        height: '100%',
                        fontWeight: 'bold',
                        color: isMonthly ? 'primary.main' : '#2B395B',
                        borderRadius: '8px',
                        '&:hover': {
                            bgcolor: 'transparent',
                        },
                    }}
                    size="large"
                >
                    Monthly
                </Button>
            </Stack>
            <Typography sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                Save up to 25% with Yearly
            </Typography>
            <Typography
                sx={{
                    mt: -1,
                    fontWeight: 400,
                    color: '#3B4D7D',
                    opacity: 0.7,
                }}
            >
                (billed {isMonthly ? 'monthly' : 'annually'})
            </Typography>
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                gap={2}
                sx={{
                    px: 2,
                    mt: 3,
                    width: 1,
                    '& > div': {
                        width: {
                            md: 'calc(33% - 10px)',
                            xs: '100%',
                        },
                    },
                }}
            >
                <Price
                    title="Free"
                    description="Best for making the first steps with Travelity"
                    price="0"
                    features={[
                        '1 user account',
                        'User-level timezone and currency configuration',
                        '1 event per day',
                    ]}
                    onClick={() => next('free')}
                    free
                />
                <Price
                    title="Professional"
                    description="Best for professionals who are a one-person orchestra"
                    highlighted
                    price={isMonthly ? '20' : '15'}
                    features={[
                        'User-level, fully customizable work hours',
                        '10 daily events',
                    ]}
                    onClick={() => next('professional')}
                    everythingIn="Free"
                />
                <Price
                    title="Team"
                    description="Best for small teams of individual professionals"
                    price={isMonthly ? '200' : '150'}
                    features={[
                        'Product-level multi-currency and multiple timezone support',
                        'Unlimited daily events',
                        'Up to 10 teams with up to 10 members each',
                    ]}
                    onClick={() => next('team')}
                    everythingIn="Professional"
                />
                <Price
                    title="Enterprise"
                    description="Best for companies and corporations of different sizes"
                    features={[
                        'On-demand isolated environment',
                        'Tell us what else',
                    ]}
                    demoButton
                    everythingIn="Team"
                />
            </Stack>
            <Button
                variant="contained"
                color="neutral"
                sx={{ width: '100px', mb: 2 }}
                onClick={back}
            >
                Back
            </Button>
        </Stack>
    );
};
