import React from 'react';
import { Divider, Paper, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Customer } from '@travelity/api';
import BookingTravelerCard from './components/booking-traveler-card';
import { TravelerForm } from '../booking-participants/booking-participants.types';

export interface BookingParticipantsFormProps {
    readonly?: boolean;
}

const BookingParticipantsForm: React.FC<
    BookingParticipantsFormProps
> = props => {
    const { readonly } = props;
    const { watch } = useFormContext();
    const bookings = watch('travelers') as {
        bookingId: string;
        customer: Customer;
        travelers?: TravelerForm[];
    }[];

    return (
        <Stack spacing={2}>
            {bookings?.map(({ customer, travelers, bookingId }) => (
                <>
                    <Divider>Customer: {customer.fullName}</Divider>
                    <Paper
                        sx={{
                            mt: 2,
                            p: 2,
                            height: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {travelers?.map(traveler => (
                            <BookingTravelerCard
                                key={traveler.travelerId}
                                traveler={traveler}
                                bookingId={bookingId}
                                readonly={readonly}
                            />
                        ))}
                    </Paper>
                </>
            ))}
        </Stack>
    );
};

export default React.memo(BookingParticipantsForm);
