import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BlockIcon from '@mui/icons-material/Block';

export interface ErrorDialogProps {
    handleCancel?: () => void;
    handleConfirm: () => void;
    open: boolean;
    title?: React.ReactNode;
    content?: string;
    confirmText?: string;
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({
    handleCancel,
    handleConfirm,
    open,
    title,
    content,
    confirmText,
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleCancel || handleConfirm}
            PaperProps={{
                sx: { py: 1, bgcolor: '#FFF', position: 'relative' },
            }}
        >
            <DialogTitle
                sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontWeight: 600,
                    color: '#2B395B',
                    alignItems: 'center',
                    display: 'flex',
                    gap: 2,
                    px: 4,
                }}
                id="alert-dialog-title"
            >
                <BlockIcon fontSize="large" color="error" />
                {title}
            </DialogTitle>
            {!!content && (
                <DialogContent>
                    <DialogContentText
                        sx={{ color: '#67728B', fontSize: '14px' }}
                        id="alert-dialog-description"
                    >
                        {content}
                    </DialogContentText>
                </DialogContent>
            )}
            <DialogActions sx={{ px: 3, py: 2 }}>
                {handleCancel && (
                    <Button
                        color="neutral"
                        variant="contained"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleConfirm}
                    autoFocus
                >
                    {confirmText || 'Ok'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorDialog;
