import React, { useState } from 'react';
import { LexicalEditor, EditorState, TextNode } from 'lexical';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { OnChangePlugin as LexicalOnchangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { $generateHtmlFromNodes } from '@lexical/html';
import { useController, useFormContext } from 'react-hook-form';

import { AutoLinkNode, LinkNode } from '@lexical/link';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { TableNode, TableCellNode, TableRowNode } from '@lexical/table';
import { ListNode, ListItemNode } from '@lexical/list';
import _ from 'lodash';
import { Box } from '@mui/material';
import LexicalEditorTopBar from './components/rich-text-topbar';
// import { HeadingNode, QuoteNode } from '@lexical/rich-text';
// import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
// import { ListItemNode, ListNode } from '@lexical/list';
// import { CodeHighlightNode, CodeNode } from '@lexical/code';
// import { AutoLinkNode, LinkNode } from '@lexical/link';
// import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
// import { $getRoot, $getSelection } from 'lexical';
// import { useEffect } from 'react';

import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { LoadInitialContent } from './components/load-initial';
import { ExtendedTextNode } from './nodes/extended-text-node';

// import FloatingTextFormatToolbarPlugin from './components/floating-text-format-plugin';

export interface RichTextEditorProps {
    name: string;
    showErrors?: boolean;
}

export interface onChangePluginProps {
    onChange: (data: { content: string; editorState: EditorState }) => void;
}

function OnChangePlugin({ onChange }: onChangePluginProps): JSX.Element {
    return (
        <LexicalOnchangePlugin
            onChange={(editorState: EditorState, editor: LexicalEditor) => {
                editorState.read(() => {
                    const htmlString = $generateHtmlFromNodes(editor, null);
                    onChange({ content: htmlString, editorState });
                });
            }}
        />
    );
}

const theme = {
    // Theme styling goes here
    //...
};

function onError(error: any) {
    console.error(error);
}

export const lexicalEditorConfig = {
    namespace: 'MyEditor',
    theme: theme,
    onError,

    nodes: [
        ExtendedTextNode,
        {
            replace: TextNode,
            with: (node: TextNode) => new ExtendedTextNode(node.__text),
            withKlass: ExtendedTextNode,
        },
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
    ],
};

export const RichTextEditor: React.FC<RichTextEditorProps> = props => {
    const { name, showErrors } = props;

    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { field } = useController({
        control,
        name,
    });

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);

    // Autofocus on the editor
    // const editor = React.useRef(null);
    // function focusEditor() {
    //     editor.current.focus();
    // }
    // React.useEffect(() => {
    //     focusEditor();
    // }, []);
    // const placeholderElement = <PlaceholderElement placeholder={placeholder} />;
    const [floatingAnchorElem, setFloatingAnchorElem] =
        useState<HTMLDivElement | null>(null);

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    return (
        <Box>
            <LexicalComposer initialConfig={lexicalEditorConfig}>
                <LexicalEditorTopBar />

                <Box
                    sx={{
                        border: '1px solid #DFE1ED',
                        borderRadius: '32px',
                        pb: 2,
                    }}
                >
                    <LoadInitialContent initialContent={field.value?.content} />
                    <RichTextPlugin
                        contentEditable={
                            <Box
                                component={ContentEditable}
                                sx={{
                                    my: 1,
                                    mx: 3,
                                    minHeight: '120px',
                                    '&:focus': {
                                        outline: 'none',
                                    },
                                }}
                            />
                        }
                        // placeholder={<div>Enter some text...</div>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <OnChangePlugin onChange={field.onChange} />
                    <ListPlugin />
                    <LinkPlugin />
                </Box>
                {/*<FloatingTextFormatToolbarPlugin />*/}
                {/*<EditorContainer background={background}>*/}
                {/*    <LexicalAutoLinkPlugin />*/}
                {/*    <RichTextPlugin*/}
                {/*        contentEditable={*/}
                {/*            <EditorScroller>*/}
                {/*                <EditorWrapper ref={onRef}>*/}
                {/*                    <ContentEditable*/}
                {/*                        className={'ContentEditable__root'}*/}
                {/*                    />*/}
                {/*                </EditorWrapper>*/}
                {/*            </EditorScroller>*/}
                {/*        }*/}
                {/*        placeholder={placeholderElement}*/}
                {/*        ErrorBoundary={null}*/}
                {/*    />*/}
                {/*    <LinkPlugin />*/}
                {/*    <ClickableLinkPlugin />*/}
                {/*    {floatingAnchorElem && (*/}
                {/*        <FloatingLinkEditorPlugin*/}
                {/*            anchorElem={floatingAnchorElem}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*</EditorContainer>*/}
            </LexicalComposer>
        </Box>
    );
};
