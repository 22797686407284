import React, { useMemo, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
    Box,
    ClickAwayListener,
    Divider,
    InputAdornment,
    List,
    MenuItem as MuiMenuItem,
    Paper,
    Popper,
    SelectProps as MuiSelectProps,
    Stack,
} from '@mui/material';
import { get } from 'lodash';
import MuiTextField from '@mui/material/TextField';
import {
    ProductMinimal,
    ProductType,
    useDebouncedProducts,
} from '@travelity/api';
import { styled } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Transitions from '../transitions/transitions';
import { ProductTypeIcon } from '../product-type-icon';

export interface SelectProductProps extends MuiSelectProps {
    width?: string;
    size?: 'small' | 'medium';
    name: string;
    label: string;
    placeholder?: string;
    required?: boolean;
    showErrors?: boolean;
    types?: ProductType[];
    MenuItem?: React.FC<any>;
}

const DefaultMenuItem: React.FC<ProductMinimal> = props => {
    const { name, type, shared } = props;

    return (
        <Stack
            sx={{
                lineHeight: '12px',
                px: '8px',
                width: 1,
                flexWrap: 'nowrap',
            }}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gap={1}
        >
            <ProductTypeIcon type={type} shared={shared} />
            <Box
                sx={{
                    py: '8px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {name}
            </Box>
        </Stack>
    );
};

const StyledSpinner = styled(RefreshIcon)(() => ({
    animation: `nfLoaderSpin infinite 700ms linear`,
    transformBox: 'fill-box',
    color: '#B2B9CD',

    '@keyframes nfLoaderSpin': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
}));

const SelectProduct: React.FC<SelectProductProps> = ({
    width,
    size,
    name,
    required,
    label,
    placeholder,
    showErrors,
    types,
    MenuItem = DefaultMenuItem,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        defaultValue: null,
    });

    const [open, setOpen] = useState(false);
    const [focus, setFocus] = useState(false);
    const [searchText, setSearchText] = useState('');

    const { data: products, isLoading } = useDebouncedProducts(
        searchText,
        types,
        {
            onSuccess: data => {
                if (data?.items && focus && !open) {
                    setOpen(true);
                }
            },
        }
    );

    const inputRef = useRef<HTMLInputElement | null>(null);
    const listRef = useRef<HTMLElement | null>(null);

    const handleClose = (
        event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
    ) => {
        if (
            inputRef.current &&
            !inputRef.current.contains(event.target as Node)
        ) {
            setOpen(false);
        }
    };

    const error =
        showErrors && (get(errors, name)?.message as string | undefined);

    const selectedProduct = useMemo(() => {
        return value ? value.label : undefined;
    }, [value]);

    // const isSelected = useMemo(() => {
    //     return (
    //         searchText.match(/^\+?[0-9 ]+$/) ||
    //         (searchText.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/) &&
    //             products?.length === 1)
    //     );
    // }, [searchText, products]);

    const isOpen = open && !!searchText && !!products;

    return (
        <>
            <MuiTextField
                margin="dense"
                size={size}
                InputLabelProps={{
                    required: !!required,
                    shrink: !!placeholder,
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {isLoading && <StyledSpinner />}
                            {!!value && (
                                <CheckCircleIcon sx={{ color: '#37C16E' }} />
                            )}
                        </InputAdornment>
                    ),
                }}
                ref={inputRef}
                label={label}
                placeholder={placeholder}
                value={selectedProduct || searchText}
                onChange={e => {
                    onChange(null);
                    setSearchText(e.target.value);
                }}
                type="text"
                error={!!error}
                helperText={error}
                onFocus={() => {
                    setOpen(true);
                    setFocus(true);
                }}
                onBlur={e => {
                    if (e.relatedTarget && listRef.current) {
                        if (listRef.current.contains(e.relatedTarget as Node)) {
                            return;
                        }
                    }
                    setFocus(false);
                    setOpen(false);
                }}
                sx={{
                    m: 0,
                    width: width || '100%',
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #DFE1ED',
                    },
                    '&& .MuiOutlinedInput-notchedOutline': {
                        ...(isOpen && {
                            borderBottom: 'none',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        }),
                    },
                }}
            />

            <Popper
                placement="bottom-end"
                open={isOpen}
                anchorEl={inputRef.current}
                role={undefined}
                transition
                sx={{ zIndex: 10000 }}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 0],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                borderRadius: '24px',
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                border: '1px solid #DFE1ED',
                                borderTop: 0,
                                background: '#FFF',
                                minHeight: '80px',
                                maxHeight: '200px',
                                width: inputRef.current?.clientWidth,
                                overflowY: 'auto',
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <List
                                    component="nav"
                                    ref={listRef}
                                    sx={{
                                        width: '100%',
                                        borderRadius: '24px',
                                        py: 0.5,
                                        '& .MuiListItemButton-root': {
                                            mt: 0.5,
                                        },
                                    }}
                                >
                                    <Divider sx={{ mx: 2 }} />
                                    {products?.map((product, i) => (
                                        <>
                                            {i !== 0 && <Divider />}
                                            <MuiMenuItem
                                                sx={{
                                                    fontSize: '12px',
                                                    color: '#2B395B',
                                                    borderRadius: '8px',
                                                    py: '4px',
                                                    px: '4px',
                                                    mx: 2,
                                                    my: 1,
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#EAF2F4',
                                                    },
                                                }}
                                                key={product.id}
                                                onClick={() => {
                                                    onChange({
                                                        value: product.id,
                                                        label: product.name,
                                                    });
                                                    setOpen(false);
                                                }}
                                            >
                                                <MenuItem {...product} />
                                            </MuiMenuItem>
                                        </>
                                    ))}
                                    {products && !products.length && (
                                        <Box
                                            sx={{
                                                textAlign: 'left',
                                                fontSize: '12px',
                                                lineHeight: '12px',
                                                color: '#C0C4CE',
                                                m: 2,
                                                px: 1,
                                            }}
                                        >
                                            No product found
                                        </Box>
                                    )}
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default SelectProduct;
