import React from 'react';
import { SelectProps as MuiSelectProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useUserContext } from '@travelity/web/src/contexts/user';
import { useCurrencyOptions } from '@travelity/web/src/hooks';
import { SearchSelect } from '../search-select';

export interface SelectCurrencyProps extends MuiSelectProps {
    width?: string;
    size?: 'small' | 'medium';
    name: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    showErrors?: boolean;
    formControlSx?: SystemStyleObject<Theme>;
}

const SelectCurrency: React.FC<SelectCurrencyProps> = props => {
    const { user } = useUserContext();
    const defaultValue = user ? user.currency : undefined;
    const disableCurrencySelection = user?.subscription
        ? !user.subscription.user?.financial?.multi_currency
        : false;
    const options = useCurrencyOptions();

    return (
        <SearchSelect
            {...props}
            options={options}
            disabled={disableCurrencySelection || props.disabled}
            defaultValue={defaultValue}
        />
    );
};

export default SelectCurrency;
