import React, { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
    BookingStatus,
    EventItem as IEventItem,
    ProductType,
    SourceType,
} from '@travelity/api';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export interface PrintEventProps {
    event: IEventItem;
}

const ageBands = ['Adults', 'Children', 'Infants', 'Youth', 'Seniors'];

const toPlural = (name: string, value: number) => {
    if (value > 1) return `${value} ${name}`;
    if (name === 'children') return `1 child`;
    // TODO remove s
    return `1 ${name.substring(0, name.length - 1)}`;
};

export const formatPaxValue = (value: Record<string, number>) =>
    ageBands
        .map(name => name.toLowerCase())
        .filter(name => value[name])
        .map(name => toPlural(name, value[name]))
        .join(', ');

export const KeyValue: React.FC<{
    title: string;
    value?: string | number;
    hide?: boolean;
}> = props => {
    const { title, value, hide } = props;
    return hide ? null : (
        <Typography
            variant="body2"
            className="gray-text"
            sx={{ fontSize: '14px', py: 1 }}
        >
            {title && (
                <Box component="span" sx={{ fontWeight: 700, color: '#000' }}>
                    {title}:{' '}
                </Box>
            )}
            <Box component="span" sx={{ color: '#000' }}>
                {value}
            </Box>
        </Typography>
    );
};

export const PrintEvent: React.FC<PrintEventProps> = props => {
    const { event } = props;

    const { t } = useTranslation();

    const bookings = useMemo(
        () =>
            event.bookings
                .filter(booking => booking.status !== BookingStatus.CANCELLED)
                .map(booking => ({
                    id: booking.id,
                    customerName: booking.participants.customer.fullName,
                    pax: formatPaxValue(booking.participants.pax),
                    status:
                        booking.status === BookingStatus.DRAFT
                            ? 'Draft'
                            : booking.status === BookingStatus.HOLD
                            ? 'Hold'
                            : 'Confirmed',
                    paymentStatus: booking.financials.debt
                        ? 'Not Paid'
                        : 'Paid',
                    source:
                        booking.source.type === SourceType.DIRECT
                            ? t(booking.source.name, { ns: 'team' })
                            : booking.source.name,
                    orderNumber: booking.orderNumber,
                    orderId: booking.orderId,
                })),
        [event]
    );

    const staffAndAssets = useMemo(() => {
        const staffGrouped = _.groupBy(event.staff, 'position');
        return [
            ...Object.entries(staffGrouped).map(([position, staff]) => ({
                name: t(position, { ns: 'common' }),
                value: staff
                    .filter(s => s.reference)
                    .map(s => s.reference?.fullName || s.reference?.email)
                    .join(', '),
            })),
            ...(event.assets.length
                ? [
                      {
                          name: 'Vehicle',
                          value: event.assets
                              .filter(a => a.reference)
                              .map(a => a.reference?.licensePlate)
                              .join(', '),
                      },
                  ]
                : []),
        ];
    }, [event]);

    return (
        <Box
            className="show-on-print draft"
            sx={{ bgcolor: '#FFF', position: 'relative' }}
        >
            <Box sx={{ px: 0, margin: '0 auto', color: '#444' }}>
                <Box sx={{ borderBottom: 'solid 1px #000', py: 3, px: 2 }}>
                    <Typography
                        variant="h4"
                        sx={{
                            pt: 0,
                            fontSize: '32px',
                            pb: 3,
                            mb: 0,
                            color: '#000',
                            lineHeight: 1,
                        }}
                    >
                        TRAVELERS LIST
                    </Typography>
                </Box>
                <Stack
                    sx={{
                        pl: 2,
                        pr: 4,
                        py: 2,
                        mx: 4,
                        mt: 10,
                        mb: 6,
                        border: '1px solid #000',
                    }}
                    gap={2}
                >
                    <Stack direction="row" justifyContent="space-between">
                        <Stack sx={{ flexGrow: 2 }}>
                            <KeyValue
                                title={
                                    event.product.type === ProductType.TOUR
                                        ? `${
                                              event.product.shared
                                                  ? 'Group '
                                                  : 'Private '
                                          }${t(event.product.type, {
                                              ns: 'product',
                                          })}`
                                        : t(event.product.type, {
                                              ns: 'product',
                                          })
                                }
                                value={event.route?.items
                                    .map(stop => stop.location.name)
                                    .join(', ')}
                            />
                            <KeyValue
                                title="Date"
                                value={format(event.date.start, 'dd.MM.yyyy')}
                            />
                        </Stack>
                        <Stack sx={{ width: '33%' }}>
                            <KeyValue
                                title="Total PAX"
                                value={event.totalPax}
                            />
                            <KeyValue title="Vacant" value={event.vacant} />
                        </Stack>
                    </Stack>
                    {!!staffAndAssets.length && (
                        <Stack
                            direction="row"
                            gap={2}
                            justifyContent="space-between"
                            sx={{ '& > p': { width: '33%' } }}
                        >
                            {staffAndAssets.map(item => (
                                <KeyValue
                                    title={item.name}
                                    value={item.value}
                                />
                            ))}
                        </Stack>
                    )}
                </Stack>
                <Box sx={{ px: 4 }}>
                    <Box
                        component="table"
                        sx={{
                            fontSize: '13px',
                            width: 1,
                            borderCollapse: 'collapse',
                        }}
                    >
                        <thead>
                            <Box
                                component="tr"
                                sx={{
                                    '& th': {
                                        border: 'solid #000 1px',
                                        borderCollapse: 'collapse',
                                        py: 2,
                                        px: 1,
                                    },
                                }}
                            >
                                <Box component="th" sx={{ textAlign: 'left' }}>
                                    Customer List
                                </Box>
                                <th>PAX</th>
                                <th>Booking Status</th>
                                <th>Payment Status</th>
                                <th>Source</th>
                                <th>Order Number</th>
                            </Box>
                        </thead>
                        <tbody>
                            {bookings.map(booking => (
                                <Box
                                    component="tr"
                                    sx={{
                                        '& td': {
                                            border: 'solid #666 1px',
                                            borderCollapse: 'collapse',
                                            py: 2,
                                            px: 1,
                                            textAlign: 'center',
                                        },
                                    }}
                                >
                                    <Box
                                        component="td"
                                        sx={{ '&&': { textAlign: 'left' } }}
                                    >
                                        {booking.customerName}
                                    </Box>
                                    <td>{booking.pax}</td>
                                    <td>{booking.status}</td>
                                    <td>{booking.paymentStatus}</td>
                                    <td>{booking.source}</td>
                                    <td>
                                        <Box
                                            component="a"
                                            sx={{ color: 'secondary.main' }}
                                            target="_blank"
                                            href={`https://${window.location.hostname}/order/${booking.orderId}`}
                                            rel="noreferrer"
                                        >
                                            {booking.orderNumber}
                                        </Box>
                                    </td>
                                </Box>
                            ))}
                        </tbody>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
