import React, { forwardRef, useState } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import {
    convertNameDtoToFullName,
    MemberRole,
    TeamMember,
    useRemoveTeamMember,
} from '@travelity/api';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
    Card,
    ConfirmationDialog,
    Heading,
    IconButton,
    IconButtonGroup,
    Tag,
} from '@travelity/ui';
import { useLocation, useParams } from 'react-router-dom';
import { MemberStatusIcon } from '../member-status-icon';
import { OccupationIcon } from '../occupation-icon';
import { DataRow } from '../data-row';

export interface EventBookingItemProps {
    member: TeamMember;
    teamId: string;
    isSelected: boolean;
    intend?: boolean;
    disableRemove?: boolean;
}

const TeamMemberItem = forwardRef<HTMLDivElement, EventBookingItemProps>(
    ({ member, teamId, isSelected, intend = true, disableRemove }, ref) => {
        const { t } = useTranslation();
        const { pathname } = useLocation();
        const teams = pathname.startsWith('/teams') ? 'teams' : 'team';
        const { teamId: selectedTeamId, memberId } = useParams();

        const phoneNumber = member.phone_numbers[0];

        const [deletingMember, setDeletingMember] = useState(false);

        const { mutate: removeMember, isLoading: isRemoveMemberLoading } =
            useRemoveTeamMember({
                onSuccess: () => {
                    setDeletingMember(false);
                },
            });

        const isSelectedMember =
            isSelected ||
            (selectedTeamId === teamId &&
                (memberId === member.id || memberId === member.email));

        return (
            <>
                <Card
                    ref={ref}
                    isSelected={isSelectedMember}
                    leftAdornment={
                        intend ? (
                            <Box
                                sx={{
                                    width: '88px',
                                    height: '88px',
                                }}
                            />
                        ) : undefined
                    }
                    buttons={
                        <IconButtonGroup>
                            <IconButton
                                href={`/${teams}/${teamId}/member/${
                                    member.id || member.email
                                }/personal-details/view`}
                                icon={<VisibilityIcon fontSize="small" />}
                                tooltip="Preview"
                            />
                            {member.role !== MemberRole.OWNER && (
                                <>
                                    <Divider sx={{ mx: 0.75 }} />
                                    <IconButton
                                        onClick={() => {
                                            setDeletingMember(true);
                                        }}
                                        disabled={disableRemove}
                                        hoverColor="error.main"
                                        icon={
                                            <RemoveCircleIcon fontSize="small" />
                                        }
                                        tooltip="Remove"
                                    />
                                </>
                            )}
                        </IconButtonGroup>
                    }
                >
                    <Stack
                        direction="row"
                        gap={1}
                        sx={{
                            px: 2,
                            py: 1,
                        }}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography sx={{ color: '#B7BCC7', fontSize: '12px' }}>
                            Info badges
                        </Typography>
                        <Stack direction="row" gap={1}>
                            {member.company && (
                                <Tag label="Company" value={member.company} />
                            )}
                            <Tag
                                label="Role"
                                value={t(member.role, {
                                    ns: 'team',
                                })}
                            />
                        </Stack>
                    </Stack>
                    <Divider />
                    <Stack gap={1} direction="row">
                        <Stack
                            sx={{
                                width: '128px',
                                pb: 1,
                                pt: 1.5,
                                px: 2,
                                borderRight: '1px solid #D6DAE4',
                            }}
                            gap={0.5}
                        >
                            <Stack
                                sx={{
                                    height: '50px',
                                }}
                                justifyContent="center"
                                alignItems="center"
                                gap={0.5}
                            >
                                <OccupationIcon
                                    occupation={member.occupation}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        color: '#B2B9CD',
                                    }}
                                >
                                    {member.occupation
                                        ? t(member.occupation, {
                                              ns: 'common',
                                          })
                                        : '-'}
                                </Typography>
                            </Stack>
                            <Divider />
                            <Stack
                                sx={{
                                    height: '50px',
                                }}
                                justifyContent="center"
                                alignItems="center"
                                gap={0.5}
                            >
                                <MemberStatusIcon
                                    status={member.membershipStatus}
                                />
                                <Heading
                                    sx={{
                                        fontSize: '12px',
                                        maxWidth: '100%',
                                    }}
                                    alpha={0.4}
                                    ellipsis
                                >
                                    {t(member.membershipStatus, {
                                        ns: 'team',
                                    })}
                                </Heading>
                            </Stack>
                        </Stack>
                        <Stack sx={{ px: 2, py: 2 }} flexGrow={2} gap={1.5}>
                            <DataRow
                                label="Name"
                                darkValue
                                value={
                                    member.name
                                        ? convertNameDtoToFullName(member.name)
                                        : '-'
                                }
                            />
                            <Divider />
                            <DataRow
                                label="Email"
                                darkValue
                                value={`${member.email}`}
                            />
                            <Divider />
                            <DataRow
                                label="Phone Number"
                                darkValue
                                value={phoneNumber || '-'}
                            />
                        </Stack>
                    </Stack>
                </Card>

                <ConfirmationDialog
                    title="Are you sure you want to remove this member from team?"
                    confirmText="Remove"
                    open={deletingMember}
                    isLoading={isRemoveMemberLoading}
                    handleCancel={() => setDeletingMember(false)}
                    handleConfirm={() => {
                        removeMember({
                            teamId,
                            id: member.id,
                        });
                    }}
                />
            </>
        );
    }
);

export default TeamMemberItem;
