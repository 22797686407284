/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingStatus } from './BookingStatus';
import type { GetEventResBookingsItem0AccessDto } from './GetEventResBookingsItem0AccessDto';
import type { GetEventResBookingsItem0EventDto } from './GetEventResBookingsItem0EventDto';
import type { GetEventResBookingsItem0FinancialsDto } from './GetEventResBookingsItem0FinancialsDto';
import type { GetEventResBookingsItem0OrderDto } from './GetEventResBookingsItem0OrderDto';
import type { GetEventResBookingsItem0ProductDto } from './GetEventResBookingsItem0ProductDto';
import type { GetEventResBookingsItem0Source0Dto } from './GetEventResBookingsItem0Source0Dto';
import type { GetEventResBookingsItem0Source1Dto } from './GetEventResBookingsItem0Source1Dto';
import type { GetEventResBookingsItem0TransactionsDto } from './GetEventResBookingsItem0TransactionsDto';
import type { GetEventResNotesDto } from './GetEventResNotesDto';
import type { GetUserResCreatedDto } from './GetUserResCreatedDto';

export type GetBookingResDto = {
    /**
     * Type of the resource
     */
    resource_type: GetBookingResDto.resource_type;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    is_deleted: boolean;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<GetUserResCreatedDto>;
    /**
     * Source of the booking
     */
    source: (GetEventResBookingsItem0Source0Dto | GetEventResBookingsItem0Source1Dto);
    /**
     * Order reference
     */
    product: GetEventResBookingsItem0ProductDto;
    /**
     * Booked event
     */
    event?: GetEventResBookingsItem0EventDto;
    /**
     * Order reference
     */
    order: GetEventResBookingsItem0OrderDto;
    /**
     * List of transactions
     */
    transactions?: GetEventResBookingsItem0TransactionsDto;
    /**
     * List of transactions
     */
    financials: GetEventResBookingsItem0FinancialsDto;
    /**
     * List of notes of some importance to the booking
     */
    notes?: GetEventResNotesDto;
    /**
     * Automatically computed status of the booking.
     */
    status: BookingStatus;
    /**
     * Access to the shared product
     */
    access: GetEventResBookingsItem0AccessDto;
};

export namespace GetBookingResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        BOOKING = 'booking',
    }


}
