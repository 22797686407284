import { Alert, Switch, Theme } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';
import { SystemStyleObject } from '@mui/system';

export interface PriceSyncProps {
    value: boolean;
    onChange: (value: boolean) => void;
    sx?: SystemStyleObject<Theme>;
}

export const PriceSync: React.FC<PriceSyncProps> = props => {
    const { value, onChange, sx } = props;

    return (
        <Alert
            severity="warning"
            icon={
                <WarningIcon
                    sx={{
                        color: '#EC8031',
                        fontSize: '24px',
                    }}
                />
            }
            action={<Switch checked={value} onChange={(e, v) => onChange(v)} />}
            sx={{
                '&&': sx,
                alignItems: 'center',
                color: '#2B395B',
                my: 1,
                '.MuiAlert-action': { pt: 0 },
            }}
        >
            Update booking prices if applicable
        </Alert>
    );
};
