import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Divider } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
    eventsFilterToRequestBodyConverter,
    useEventsLazy,
    EventItem as IEventItem,
    Booking,
} from '@travelity/api';
import { groupBy } from 'lodash';
import { format } from 'date-fns';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { EventStatus } from '@travelity/api/src/requests';
import { ConfirmationDialog } from '@travelity/ui';
import { Helmet } from 'react-helmet';
import { EventItem } from '../../components/event-item';
import EventPreview from './components/event-preview';
import { EventItemSkeleton } from '../../components/event-item-skeleton';
import { DailyViewHeader } from './components/daily-view-header';
import { Filters } from '../../components/filters';
import { useIsChanged, useLoadOnScroll } from '../../hooks';
import {
    FilterOption,
    FilterTypes,
} from '../../components/filters/filters.types';
import { BookingPreview } from '../../components/booking-preview';
import {
    List,
    ListItems,
    ListMain,
    ListSidebar,
} from '../../components/list-layout';
import { EventsHeader } from './components/events-header';
import { SidebarContent } from '../../components/sidebar-content';
import { productTypeOptions } from '../select-type/select-type';
import { useHasFilter } from '../../components/filters/filters.hooks';
import { PrintEvent } from '../../components/event-item/components/print-event';
import { useUserContext } from '../../contexts/user';

const eventFilters: FilterOption[] = [
    {
        name: 'dates',
        label: 'Dates',
        type: FilterTypes.DATES,
        selectText: 'Please, select the value for date filters',
    },
    {
        name: 'statuses',
        label: 'Statuses',
        type: FilterTypes.DROPDOWN,
        multiple: true,
        selectText: 'Please, select the value for event status',
        options: [
            {
                value: EventStatus.CANCELLED,
                label: 'Cancelled',
            },
        ],
    },
    {
        name: 'type',
        label: 'Product Type',
        type: FilterTypes.DROPDOWN,
        selectText: 'Please, select the value for product type',
        options: productTypeOptions,
    },
    {
        name: 'createdAt',
        label: 'Created At',
        type: FilterTypes.DATES,
        disable: used =>
            used.includes('updatedAt') ||
            used.includes('updatedBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Please, select the dates for created at filter',
    },
    {
        name: 'createdBy',
        label: 'Created By',
        type: FilterTypes.KEYWORD,
        disable: used =>
            used.includes('updatedAt') ||
            used.includes('updatedBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Type user name or email',
    },
    {
        name: 'updatedAt',
        label: 'Updated At',
        type: FilterTypes.DATES,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Please, select the dates for updated at filter',
    },
    {
        name: 'updatedBy',
        label: 'Updated By',
        type: FilterTypes.KEYWORD,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Type user name or email',
    },
    {
        name: 'deletedAt',
        label: 'Canceled At',
        type: FilterTypes.DATES,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('updatedAt') ||
            used.includes('updatedBy'),
        selectText: 'Please, select the dates for deleted at filter',
    },
    {
        name: 'deletedBy',
        label: 'Canceled By',
        type: FilterTypes.KEYWORD,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('updatedAt') ||
            used.includes('updatedBy'),
        selectText: 'Type user name or email',
    },
    {
        name: 'searchText',
        label: 'Search',
        type: FilterTypes.SEARCH,
        selectText: 'Search product names, route locations, etc...',
    },
];

export interface EventsProps {}

export const matchesPrevTime = (events: IEventItem[], index: number) => {
    return (
        index &&
        events[index - 1].date.start.getTime() ===
            events[index].date.start.getTime()
    );
};

const Events: React.FC<EventsProps> = () => {
    const { eventId, bookingId } = useParams();
    const navigate = useNavigate();

    const [filters, setFilters] = useState({});
    const hasFilters = useHasFilter(filters, eventFilters);

    const [showInvalidatePagination, setShowInvalidatePagination] =
        useState(false);

    const containerRef = useRef<HTMLElement>();
    const {
        data: events,
        isLoading,
        isRefetching,
        // update: updateEvent,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        refetch,
        totalCount,
    } = useEventsLazy(
        useMemo(() => eventsFilterToRequestBodyConverter(filters), [filters]),
        {
            onInvalidPagination: () => {
                setShowInvalidatePagination(true);
            },
        }
    );

    useEffect(() => {
        if (events?.length && eventId) {
            const event = events.find(e => e.id === eventId);
            if (!event) {
                navigate('/events');
            }
        }
    }, [events, eventId]);

    const { onScroll: handleScrollLoadMore } = useLoadOnScroll({
        hasNextPage: !!hasNextPage && !isFetchingNextPage,
        fetchNextPage,
    });

    const groupByDay = useMemo(() => {
        if (events) {
            return groupBy(events, ({ date }) =>
                format(date.start, 'dd-MMM-yyyy')
            );
        }
        return {};
    }, [events]);

    const event = useMemo(() => {
        return events?.find(e => e.id === eventId);
    }, [events, eventId]);

    const bookingItem = useMemo(() => {
        return event?.bookings && bookingId
            ? event.bookings?.find((b: Booking) => b.id === bookingId)
            : undefined;
    }, [event, bookingId]);

    // Handle first visible date
    const [firstVisibleDate, setFirstVisibleDate] = useState<number>();
    const refs = useRef<Record<number, HTMLDivElement | null>>({});

    const filtersChanged = useIsChanged(filters, true);
    useEffect(() => {
        if (filtersChanged) refs.current = {};
    }, [filters, filtersChanged]);

    useEffect(() => {
        if (events?.length) {
            setFirstVisibleDate(
                prevState => prevState || events[0].date.start.getTime()
            );
        } else {
            setFirstVisibleDate(undefined);
        }
    }, [events]);
    const setDayRef = useCallback((ref: HTMLDivElement) => {
        const day = ref?.getAttribute('data-day');
        if (day) {
            refs.current[parseInt(day, 10)] = ref;
        }
    }, []);
    const handleScrollFirstVisibleDate = useCallback(
        (container: HTMLElement) => {
            const days = Object.keys(refs.current)
                .map(k => ({
                    date: k,
                    // @ts-ignore
                    offset: refs.current[k].offsetTop + 30,
                }))
                .sort((a, b) => b.offset - a.offset);
            const nearest =
                days.find(({ offset }) => offset <= container.scrollTop) ||
                days[days.length - 1];
            if (nearest) setFirstVisibleDate(parseInt(nearest.date, 10));
        },
        []
    );

    const onScroll = useCallback(
        (container: HTMLElement) => {
            handleScrollFirstVisibleDate(container);
            handleScrollLoadMore(container);
        },
        [handleScrollFirstVisibleDate, handleScrollLoadMore]
    );

    const [printEvent, setPrintEvent] = useState<IEventItem>();
    const { user } = useUserContext();

    if (user && !user.roleAccess.event?.read) {
        navigate('/bookings');
    }

    return (
        <>
            <Helmet>
                {printEvent ? (
                    <title>
                        Travelity_
                        {event?.product.name.replaceAll(/\W/g, '_') || ''}_
                        {event?.product.shared ? 'group' : 'private'}_Report
                    </title>
                ) : (
                    <title>Travelity - Event</title>
                )}
            </Helmet>
            <List>
                {!!printEvent && <PrintEvent event={printEvent} />}
                <ListMain
                    hideOnPrint={!!printEvent}
                    isLoading={isLoading || isRefetching}
                    SkeletonComponent={EventItemSkeleton}
                >
                    <Filters
                        values={filters}
                        setValues={setFilters}
                        options={eventFilters}
                    />
                    <ListItems
                        noItemsIcon={
                            <CalendarMonthIcon
                                sx={{
                                    fontSize: '164px',
                                    color: '#949BAC',
                                }}
                            />
                        }
                        noItemsText="No events found in the given range"
                        subtractHeight={hasFilters ? 116 : 60}
                        hideCountInfo
                        items={totalCount}
                        isLoading={isLoading || isRefetching}
                        header={
                            <EventsHeader firstVisibleDate={firstVisibleDate} />
                        }
                        scrollbarProps={{
                            onScrollY: onScroll,
                            style: { height: 'calc(100% - 72px)' },
                            containerRef: el => {
                                containerRef.current = el;
                            },
                        }}
                    >
                        {Object.values(groupByDay).map((dayEvents, i) => (
                            <React.Fragment
                                key={dayEvents[0].date.start.toString()}
                            >
                                <DailyViewHeader
                                    date={dayEvents[0].date.start}
                                    sx={
                                        i === 0
                                            ? {
                                                  display: 'none',
                                              }
                                            : {}
                                    }
                                    ref={setDayRef}
                                />
                                {dayEvents.map((ev, j) => (
                                    <React.Fragment key={ev.id}>
                                        {!matchesPrevTime(dayEvents, j) && (
                                            <Divider
                                                sx={{
                                                    my: 1,
                                                    '.MuiDivider-wrapper': {
                                                        color: '#9198AA',
                                                    },
                                                }}
                                            >
                                                {format(ev.date.start, 'HH:mm')}
                                            </Divider>
                                        )}
                                        <EventItem
                                            bookingId={bookingId}
                                            isSelected={eventId === ev.id}
                                            event={ev}
                                            onPrintClick={() => {
                                                setPrintEvent(ev);
                                                setTimeout(() => {
                                                    window.print();
                                                }, 1);
                                            }}
                                            refetch={refetch}
                                        />
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        ))}
                        {hasNextPage && <EventItemSkeleton />}
                    </ListItems>
                </ListMain>
                <ListSidebar
                    isEmpty={!eventId}
                    placeholderText="No event is selected for preview"
                >
                    {event && !bookingItem && (
                        <EventPreview
                            event={event}
                            onClose={() => navigate('/events')}
                        />
                    )}
                    {bookingItem && (
                        <SidebarContent
                            title="Booking Preview"
                            isLoading={isLoading || isRefetching}
                            onClose={() => navigate('/events')}
                        >
                            <BookingPreview
                                siblingBookings={[]}
                                booking={bookingItem}
                                updateBooking={refetch}
                                closeForm={() => navigate('/')}
                            />
                        </SidebarContent>
                    )}
                </ListSidebar>
            </List>
            <ConfirmationDialog
                handleConfirm={() => {
                    if (containerRef.current) {
                        containerRef.current.scrollTop = 0;
                    }
                    refetch();
                    setShowInvalidatePagination(false);
                }}
                open={showInvalidatePagination}
                title="The list of events has been updated!"
                confirmText="Ok"
                content="The Daily View will be reloaded to keep you up to date."
            />
        </>
    );
};

export default Events;
