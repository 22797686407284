/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BookingSourceType {
    DIRECT = 'direct',
    REFERRAL = 'referral',
}
