import React from 'react';
import { Box, Divider, Stack, Tooltip } from '@mui/material';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import {
    useSetTravelerParticipation,
    Traveler,
    useCustomer,
} from '@travelity/api';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { Card, IconButton, Tag, TagListItem } from '@travelity/ui';
import { useSnackbar } from 'notistack';
import { AttendanceType } from '@travelity/api/src/requests';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import FilterIcon from '@mui/icons-material/Filter';
import CloseIcon from '@mui/icons-material/Close';
import { DataRow } from '../../data-row';

export interface BookingTravelerCardProps {
    traveler: Traveler;
    bookingId: string;
    readonly?: boolean;
}

const BookingTravelerCard: React.FC<BookingTravelerCardProps> = ({
    traveler,
    bookingId,
    readonly,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [showDetails, setShowDetails] = React.useState(false);

    const { mutate: setParticipation, isLoading: isSaving } =
        useSetTravelerParticipation({
            onError: () => {
                enqueueSnackbar(`Failed to set traveler participation`, {
                    variant: 'success',
                });
            },
        });

    const { data: customer, isLoading: isCustomerLoading } = useCustomer(
        traveler.customerId,
        {
            enabled: showDetails,
        }
    );

    const emails = customer?.emails?.map(({ value }) => value) || [];
    const phoneNumbers =
        customer?.numbers?.map(({ value }) => `+${value}`) || [];
    const files = customer?.attachments || [];

    return (
        <Card
            sx={{ minHeight: '122px' }}
            key={traveler.travelerId}
            loadingOverlay={isSaving}
            buttons={
                <Stack gap={0.5}>
                    <IconButton
                        icon={<EmojiPeopleIcon />}
                        withShadow
                        disabledColor={
                            traveler.attendance === AttendanceType.PRESENCE
                                ? '#2CAC60'
                                : '#C9CEDC'
                        }
                        hoverColor="#2CAC60"
                        disabled={
                            readonly ||
                            traveler.attendance === AttendanceType.PRESENCE
                        }
                        onClick={() => {
                            setParticipation({
                                bookingId,
                                travelerId: traveler.travelerId,
                                attendance: AttendanceType.PRESENCE,
                            });
                        }}
                    />
                    <IconButton
                        icon={<PersonOffIcon />}
                        withShadow
                        disabledColor={
                            traveler.attendance === AttendanceType.ABSENCE
                                ? '#D84F5F'
                                : '#C9CEDC'
                        }
                        hoverColor="#D84F5F"
                        disabled={
                            readonly ||
                            traveler.attendance === AttendanceType.ABSENCE
                        }
                        onClick={() => {
                            setParticipation({
                                bookingId,
                                travelerId: traveler.travelerId,
                                attendance: AttendanceType.ABSENCE,
                            });
                        }}
                    />
                    <IconButton
                        icon={<AccountBoxIcon />}
                        withShadow
                        pressed={showDetails}
                        onClick={() => {
                            setShowDetails(!showDetails);
                        }}
                    />
                </Stack>
            }
        >
            <Box
                sx={{
                    py: '22px',
                    px: 2,
                    color: '#9298ab',
                    bgcolor: '#f7f8fa',
                    height: '36px',
                    fontWeight: 600,
                    borderRadius: '12px 12px 0px 0px',
                    lineHeight: '10px',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Stack
                    direction="row"
                    sx={{ width: 1 }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box
                        component="span"
                        title={traveler.fullName}
                        sx={{
                            overflowX: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            lineHeight: 2,
                        }}
                    >
                        {traveler.fullName}
                    </Box>
                    <Tag label="Type" value="Traveler" />
                </Stack>
            </Box>
            <Stack gap={1.5} p={2}>
                <DataRow
                    label="Accompanying Travelers"
                    value={`${traveler.guestCount || 0}`}
                    darkValue
                    tagValue
                />
                {showDetails && (
                    <>
                        <Divider>Contact Details</Divider>
                        <DataRow
                            label="Emails"
                            value={emails.join(', ')}
                            isLoading={isCustomerLoading}
                        />
                        <DataRow
                            label="Phone Numbers"
                            value={phoneNumbers.join(', ')}
                            isLoading={isCustomerLoading}
                        />

                        {!!files.length && (
                            <>
                                <Divider sx={{ mt: 1.5 }}>Attachments</Divider>
                                <Stack direction="column" gap={1}>
                                    {files.map(
                                        ({ name, contentType, url }, index) => (
                                            <TagListItem
                                                /* eslint-disable-next-line react/no-array-index-key */
                                                key={`${name}-${index}`}
                                                label={
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        gap={0.5}
                                                    >
                                                        {contentType ===
                                                        'application/pdf' ? (
                                                            <PictureAsPdf
                                                                sx={{
                                                                    fontSize:
                                                                        '20px',
                                                                }}
                                                            />
                                                        ) : (
                                                            <FilterIcon
                                                                sx={{
                                                                    fontSize:
                                                                        '20px',
                                                                }}
                                                            />
                                                        )}
                                                        <Box
                                                            sx={{
                                                                overflow:
                                                                    'hidden',
                                                                whiteSpace:
                                                                    'nowrap',
                                                                textOverflow:
                                                                    'ellipsis',
                                                                lineHeight: 2,
                                                            }}
                                                        >
                                                            {name}
                                                        </Box>
                                                    </Stack>
                                                }
                                                deleteIcon={
                                                    <Tooltip title="Remove">
                                                        <CloseIcon />
                                                    </Tooltip>
                                                }
                                                onClick={() =>
                                                    window.open(url, '_blank')
                                                }
                                                sx={{
                                                    borderRadius: '12px',
                                                    position: 'relative',
                                                    px: 2,
                                                    py: 1,
                                                    m: 0,
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                    bgcolor: '#FFF',
                                                    height: '34px',
                                                    boxShadow:
                                                        '0px 1px 12px 0px rgba(178, 185, 205, 0.30)',
                                                    '&:hover': {
                                                        boxShadow:
                                                            '0px 1px 12px 0px rgba(178, 185, 205, 0.30)',
                                                    },
                                                    '& > .MuiChip-label': {
                                                        color: '#9198AA',
                                                        fontSize: '14px',
                                                        overflow: 'hidden',
                                                    },
                                                    '& > .MuiChip-icon': {
                                                        color: '#9198AA',
                                                        fontSize: '18px',
                                                        whiteSpace: 'wrap',
                                                    },
                                                }}
                                            />
                                        )
                                    )}
                                </Stack>
                            </>
                        )}
                    </>
                )}
            </Stack>
        </Card>
    );
};

export default React.memo(BookingTravelerCard);
