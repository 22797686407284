import React, { useCallback, useMemo, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { CardList, PaxPicker } from '@travelity/form';
import { IconButton } from '@travelity/ui';
import {
    Customer,
    PaxData,
    RouteStop,
    Traveler,
    TravelerPickUp,
} from '@travelity/api';
import { type GetProductsResItem0RouteItemDto } from '@travelity/api/src/requests';
import { useFormContext } from 'react-hook-form';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { CustomerSearchModal } from '../customer-search-modal';
import BookingTravelerCard from './components/booking-traveler-card';
import { CustomerModal } from '../customer-modal';
import { SelectPickupPoint } from '../select-pickup-point/select-pickup-point';

export interface BookingParticipantsFormProps {
    preview?: boolean;
    route?: {
        items: (GetProductsResItem0RouteItemDto & { time?: Date })[];
        round_trip: boolean;
    };
}

const BookingParticipantsForm: React.FC<BookingParticipantsFormProps> = ({
    preview,
    route,
}) => {
    // const { t } = useTranslation();
    const { watch } = useFormContext();

    const pax = watch('pax');
    const travelerList = watch('travelers');

    const stops = useMemo(() => {
        const list: RouteStop[] = [];

        route?.items?.forEach(stop => {
            list.push({
                pinned: !!stop.pinned,
                type: stop.type,
                name: stop.location.name,
                time: stop.time || new Date(),
                id: stop.id,
            });
        });

        return list;
    }, [route, travelerList]);

    const totalPax = Object.values(pax as PaxData).reduce(
        (s: number, c) => s + (c || 0),
        0
    );
    const current =
        travelerList.reduce(
            (s: number, c: Traveler) => s + 1 + (c.guestCount || 0),
            0
        ) || 0;
    const travelersLeft = totalPax > current ? totalPax - current : 0;

    // Handle customer selection
    const [customerSearchModal, setCustomerSearchModal] = useState<{
        callback?: (v: Record<string, any>) => void;
    }>();
    const [customerModal, setCustomerModal] = useState<{ id?: string }>();

    const onAdd = useCallback((callback: (v: Record<string, any>) => void) => {
        setCustomerSearchModal({ callback });
    }, []);

    const handleCustomerSelect = useCallback(
        (newItem: Partial<Customer>) => {
            if (newItem.id) {
                customerSearchModal?.callback?.({
                    fullName: newItem.fullName,
                    customerId: newItem.id,
                    guestCount: travelersLeft - 1,
                    isNew: true,
                });
                setCustomerModal(undefined);
                setCustomerSearchModal(undefined);
            } else {
                setCustomerModal({});
            }
        },
        [customerSearchModal]
    );

    return (
        <Stack spacing={2}>
            <PaxPicker
                name="pax"
                withDropdown
                selectProps={{
                    label: 'PAX',
                    placeholder: 'Select PAX',
                    disabled: preview,
                }}
            />
            <CardList
                renderContent={({ item, editing }) => (
                    <BookingTravelerCard
                        item={item}
                        editing={editing}
                        travelerCap={travelersLeft + (item.guestCount || 0)}
                    />
                )}
                disableButtons={preview}
                disableContent={preview}
                name="travelers"
                // disabled={preview}
                disableAdding={travelersLeft <= 0}
                addButtonText="Add Traveler"
                maxHeight={600}
                disableReorder
                customAdd={onAdd}
                renderCustomButtons={({ form, item }) => {
                    const customerId = form?.watch('customerId');

                    return customerId ? (
                        <IconButton
                            icon={<AccountBoxIcon fontSize="small" />}
                            withShadow
                            onClick={() =>
                                setCustomerModal({
                                    id: item.customerId,
                                })
                            }
                            tooltip="Customer"
                            tooltipPlacement="left"
                        />
                    ) : null;
                }}
                renderPreComponent={({ editing, form, item }) => (
                    <SelectPickupPoint
                        disabled={preview}
                        selected={editing ? form?.watch('pickUp') : item.pickUp}
                        setSelected={(v?: TravelerPickUp) => {
                            form?.setValue('pickUp', v, {
                                shouldDirty: true,
                            });
                        }}
                        editing={editing}
                        stops={stops}
                    />
                )}
                renderHeader={({ item }) => {
                    return (
                        <Stack
                            direction="row"
                            sx={{ width: 1, pl: '10px' }}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box component="span">{item.fullName}</Box>
                        </Stack>
                    );
                }}
            />
            <CustomerSearchModal
                handleCancel={() => setCustomerSearchModal(undefined)}
                handleConfirm={handleCustomerSelect}
                open={!!customerSearchModal}
            />
            {customerModal && (
                <CustomerModal
                    onClose={() => setCustomerModal(undefined)}
                    onAdd={handleCustomerSelect}
                    readonly={preview}
                    open={!!customerModal}
                    customerId={customerModal?.id}
                />
            )}
        </Stack>
    );
};

export default React.memo(BookingParticipantsForm);
