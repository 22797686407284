import { Box, Chip, Stack } from '@mui/material';
import { format } from 'date-fns';
// eslint-disable-next-line import/no-extraneous-dependencies
import SwipeViews from 'react-swipeable-views-react-18-fix';
// eslint-disable-next-line import/no-extraneous-dependencies
import { virtualize } from 'react-swipeable-views-utils-react-18-fix';
// eslint-disable-next-line import/no-extraneous-dependencies
import { mod } from 'react-swipeable-views-core-react-18-fix';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { AvailabilityProduct, AvailableEvent } from '@travelity/api';
import { chunk, uniqBy } from 'lodash';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const VirtualizeSwipeableViews = virtualize(SwipeViews);

export interface SwipeTimesProps {
    events: AvailableEvent[];
    day: number;
    availabilityProduct: AvailabilityProduct;
    selectedTime?: number;
    onEventClick: (
        availabilityProduct: AvailabilityProduct,
        day: number,
        t: number
    ) => void;
    initialWidth?: number;
}

export const SwipeTimes: React.FC<SwipeTimesProps> = memo(
    (props: SwipeTimesProps) => {
        const {
            events,
            onEventClick,
            initialWidth,
            selectedTime,
            day,
            availabilityProduct,
        } = props;

        const initialWithoutMargin = initialWidth
            ? initialWidth - 29
            : initialWidth;
        const [value, setValue] = React.useState(0);
        const ref = React.useRef<HTMLDivElement>(null);
        const chunks = useMemo(() => {
            const width = ref.current?.offsetWidth || initialWithoutMargin;
            return width
                ? chunk(uniqBy(events, 'time'), Math.floor(width / 55))
                : [];
        }, [events, ref.current, initialWithoutMargin]);

        const [state, setState] = React.useState(0);

        useEffect(() => {
            if (!state) {
                setState(Math.random());
            }
        }, []);

        const handleChangeIndex = (index: number) => {
            setValue(index);
        };

        const slideRenderer = useCallback(
            (params: { index: number; key: string }) => {
                const { index, key } = params;
                const i = mod(index, chunks.length);
                const ch = chunks[i];

                return (
                    <Box key={key}>
                        {ch.map(({ time }) => (
                            <Chip
                                key={time}
                                size="small"
                                clickable
                                onClick={() =>
                                    onEventClick(availabilityProduct, day, time)
                                }
                                sx={{
                                    width: '52px',
                                    m: '1px 2px 2px 1px',
                                    height: 'auto',
                                    fontWeight: 500,
                                    '& > .MuiChip-label': {
                                        px: '6px',
                                    },
                                    ...(selectedTime === time
                                        ? {
                                              bgcolor: '#55B5CF',
                                              color: '#FFF',
                                              '&:active': {
                                                  bgcolor: '#55B5CF',
                                              },
                                              '&:hover': {
                                                  bgcolor: '#55B5CF',
                                              },
                                          }
                                        : {
                                              bgcolor: '#DDF0F5',
                                              color: '#3B4D7D',
                                              '&:active': {
                                                  bgcolor: '#DDF0F5',
                                              },
                                              '&:hover': {
                                                  bgcolor: '#AAD9E7',
                                              },
                                          }),
                                }}
                                variant="filled"
                                label={format(time, 'HH:mm')}
                            />
                        ))}
                    </Box>
                );
            },
            [chunks]
        );

        return (
            <Stack
                ref={ref}
                direction="row"
                flexWrap="wrap"
                sx={{
                    ml: '17px',
                    mr: '12px',
                    position: 'relative',
                    minHeight: 25,
                }}
            >
                {value > 0 && (
                    <Box
                        sx={{
                            position: 'absolute',
                            left: -18,
                            top: 3,
                            cursor: 'pointer',
                            color: '#949BAC',
                            ':hover': {
                                color: '#3B4D7D',
                            },
                        }}
                        onClick={() => setValue(value - 1)}
                    >
                        <KeyboardArrowLeftIcon fontSize="small" />
                    </Box>
                )}
                {value !== chunks.length - 1 && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: -17,
                            top: 3,
                            cursor: 'pointer',
                            color: '#949BAC',
                            ':hover': {
                                color: '#3B4D7D',
                            },
                        }}
                        onClick={() => setValue(value + 1)}
                    >
                        <KeyboardArrowRightIcon fontSize="small" />
                    </Box>
                )}
                <VirtualizeSwipeableViews
                    axis="x"
                    index={value}
                    enableMouseEvents
                    onChangeIndex={handleChangeIndex}
                    slideRenderer={slideRenderer}
                />
            </Stack>
        );
    }
);
