import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Divider, Paper, Stack } from '@mui/material';
import { Card, IconButton, IconTextButton, Tag } from '@travelity/ui';
import {
    AgebandCapacity,
    AgeBands,
    Asset,
    CapacityItem,
    CapacityOptionTypes,
    EventItem,
    FlatCapacity,
    StaffPosition,
    useProductCapacities,
} from '@travelity/api';
import { CardList } from '@travelity/form';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { capitalizeFirstLetter } from '@travelity/api/generator/common';
import DoNotDisturbOn from '@mui/icons-material/DoNotDisturbOn';
import Add from '@mui/icons-material/Add';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { type GetProductsResItem0RouteItemDto } from '@travelity/api/src/requests';
import { StaffModalData, useStaffModal } from '../staff-modal';
import { DataRow } from '../data-row';
import { useAssetModal } from '../asset-modal';
import { useResourceType } from '../../contexts/user';
import { Route } from '../route';
import { PriceSync } from '../price-sync';

export interface EventOperationsProps {
    event: EventItem;
    capacity?: CapacityItem;
    loadingCapacity?: boolean;
    preview?: boolean;
    route?: {
        items: (GetProductsResItem0RouteItemDto & { time?: Date })[];
        round_trip: boolean;
    };
    allowedAssetTypes?: string[];
    allowedStaffPositions?: StaffPosition[];
}

export const EventOperations: React.FC<EventOperationsProps> = props => {
    const { t } = useTranslation();
    const {
        event,
        preview,
        route,
        allowedStaffPositions,
        allowedAssetTypes,
        capacity,
        loadingCapacity,
    } = props;
    const location = useLocation();

    const supportsAssets = useResourceType('asset');
    const supportsStaff = useResourceType('team');

    const canHaveAssets = !!allowedAssetTypes?.length && supportsAssets;
    const canHaveStaff = !!allowedStaffPositions?.length && supportsStaff;

    const [centerModal, setCenterModal] = React.useState(false);

    const { watch, control, setValue } = useFormContext();

    const [staffAddCallback, setStaffAddCallback] =
        React.useState<(v: Record<string, any>) => void>();

    const {
        StaffModal,
        addStaff,
        closeModal: closeStaffModal,
    } = useStaffModal();

    const onStaffClose = useCallback(() => {
        closeStaffModal();
        setCenterModal(false);
    }, []);

    const appendStaff = useCallback((v: Record<string, any>) => {
        const prevState = watch('staff') || [];
        setValue('staff', [...prevState, v], { shouldDirty: true });
    }, []);

    const routes = useMemo(
        () =>
            route?.items?.map(stop => ({
                date: stop.time,
                name: stop.location.name,
            })) || [],
        [route]
    );

    const onStaffConfirm = useCallback(
        ({ staff, position }: StaffModalData) => {
            onStaffClose();
            if (staffAddCallback) {
                staffAddCallback({
                    fullName: staff.fullName,
                    email: staff.email,
                    phoneNumber: staff.phoneNumber,
                    id: staff.id,
                    position,
                });
            } else {
                appendStaff({
                    fullName: staff.fullName,
                    email: staff.email,
                    phoneNumber: staff.phoneNumber,
                    id: staff.id,
                    position,
                });
            }
        },
        [staffAddCallback]
    );

    const customStaffAdd = useCallback(
        (callback: (v: Record<string, any>) => void) => {
            addStaff();
            setStaffAddCallback(() => callback);
        },
        []
    );

    useEffect(() => {}, [event]);

    const existingEmails = useWatch({ control, name: 'staff' }).map(
        ({ email }: { email: string }) => email
    );

    const [assetAddCallback, setAssetAddCallback] =
        React.useState<(v: Record<string, any>) => void>();

    const {
        AssetModal,
        addAsset,
        closeModal: closeAssetModal,
    } = useAssetModal();

    const onAssetClose = useCallback(() => {
        closeAssetModal();
        setCenterModal(false);
    }, []);

    const appendAsset = useCallback((v: Record<string, any>) => {
        const prevState = watch('assets') || [];
        setValue('assets', [...prevState, v], { shouldDirty: true });
    }, []);

    const onAssetConfirm = useCallback(
        (asset: Asset) => {
            onAssetClose();
            if (assetAddCallback) {
                assetAddCallback(asset);
            } else {
                appendAsset(asset);
            }
        },
        [onAssetClose, assetAddCallback]
    );

    const customAssetAdd = useCallback(
        (callback: (v: Record<string, any>) => void) => {
            addAsset();
            setAssetAddCallback(() => callback);
        },
        []
    );

    useEffect(() => {
        if (location.state && location.state.action === 'add') {
            if (location.state.type === 'staff') {
                addStaff({ position: location.state.value });
                setCenterModal(true);
            } else if (location.state.type === 'asset') {
                addAsset();
                setCenterModal(true);
            }
        }
    }, [location.state]);

    const selectedAssets = watch('assets');
    const usedAssetIds = useMemo(() => {
        return selectedAssets?.map((asset: Asset) => asset.id);
    }, [selectedAssets]);
    const selectedCapacity = useWatch({ control, name: 'capacity' });

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'demoMenu',
    });
    const { data: capacities } = useProductCapacities(
        event.product.id,
        event.pax
    );

    const capacityChanged =
        selectedCapacity?.id && selectedCapacity?.id !== event.capacity?.id;

    return (
        <Box>
            <Divider>Route</Divider>

            <Route
                showTime
                color="grey"
                stops={routes}
                timelineProps={{
                    sx: { pl: '2px' },
                }}
            />

            <Divider>Capacity</Divider>

            {!preview && capacityChanged && (
                <PriceSync
                    value={watch('syncPricing')}
                    onChange={v => setValue('syncPricing', v)}
                />
            )}
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid transparent',
                }}
            >
                {selectedCapacity ? (
                    <Card
                        loadingOverlay={loadingCapacity}
                        buttons={
                            <IconButton
                                withShadow
                                icon={<DoNotDisturbOn fontSize="small" />}
                                onClick={() => {
                                    setValue('capacity', null);
                                }}
                                sx={{ opacity: preview ? 0.5 : 1 }}
                                hoverColor="error.main"
                                disabled={!!preview}
                                tooltip="Remove"
                            />
                        }
                    >
                        <Box
                            sx={{
                                p: 0.75,
                                color: '#9298ab',
                                bgcolor: '#f7f8fa',
                                height: '36px',
                                fontWeight: 600,
                                borderRadius: '12px 12px 0px 0px',
                                lineHeight: '10px',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Stack
                                direction="row"
                                sx={{ width: 1, pl: '10px' }}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box component="span">{capacity?.name}</Box>
                            </Stack>
                        </Box>
                        <Stack
                            direction="row"
                            gap={1}
                            alignItems="center"
                            sx={{ my: 1, mx: 2 }}
                        >
                            {capacity?.type === CapacityOptionTypes.FLAT ? (
                                <>
                                    <Tag
                                        label="Min Count"
                                        values={[
                                            `${
                                                (
                                                    capacity?.limit as FlatCapacity
                                                )?.min
                                            }`,
                                        ]}
                                    />
                                    <Tag
                                        label="Max Count"
                                        values={[
                                            `${
                                                (
                                                    capacity?.limit as FlatCapacity
                                                )?.max
                                            }`,
                                        ]}
                                    />
                                </>
                            ) : (
                                Object.values(AgeBands).map(
                                    name =>
                                        !!(
                                            capacity?.limit as AgebandCapacity
                                        )?.[name] && (
                                            <Tag
                                                label={t(name, {
                                                    ns: 'product',
                                                })}
                                                values={[
                                                    (
                                                        capacity?.limit as AgebandCapacity
                                                    )[name] || '',
                                                ]}
                                            />
                                        )
                                )
                            )}
                        </Stack>
                    </Card>
                ) : (
                    <IconTextButton
                        text="Set Capacity"
                        icon={<Add />}
                        className="add-button"
                        data-testid="add-card"
                        {...bindTrigger(popupState)}
                    />
                )}
                <Menu
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    MenuListProps={{
                        sx: { width: popupState.anchorEl?.clientWidth },
                    }}
                    {...bindMenu(popupState)}
                >
                    {capacities?.map(({ name, ...rest }, index) => (
                        <>
                            {!!index && (
                                <Divider sx={{ mx: 2, '&&&': { my: 0.5 } }} />
                            )}
                            <MenuItem
                                sx={{
                                    justifyContent: 'center',
                                    color: '#2B395B',
                                    fontSize: '12px',
                                    margin: '0 16px',
                                    borderRadius: '4px',
                                    '&:before': {
                                        borderTop: index
                                            ? '1px solid #DFE1ED'
                                            : 0,
                                    },
                                    '&:hover': {
                                        backgroundColor: '#D7E7EB',
                                    },
                                }}
                                onClick={() => {
                                    popupState.close();
                                    setValue(
                                        'capacity',
                                        {
                                            id: rest.capacityId,
                                            name,
                                        },
                                        {
                                            shouldDirty: true,
                                        }
                                    );
                                }}
                            >
                                {name}
                            </MenuItem>
                        </>
                    ))}
                </Menu>
            </Paper>
            {(!preview || !!watch('staff')?.length) && canHaveStaff && (
                <>
                    <Divider>Staff</Divider>
                    <CardList
                        renderContent={({ item }) => (
                            <Stack sx={{ px: 2, py: 2 }} gap={1.5}>
                                <Tag
                                    sx={{
                                        alignSelf: 'flex-start',
                                    }}
                                    value={capitalizeFirstLetter(
                                        item.position || ''
                                    )}
                                    valueProps={{
                                        sx: {
                                            py: 0.5,
                                            color: '#FFF',
                                            bgcolor: '#2B395B',
                                        },
                                    }}
                                />
                                <DataRow
                                    label="Name"
                                    darkValue
                                    value={item.fullName || '-'}
                                />
                                <DataRow
                                    label="Phone Number"
                                    darkValue
                                    tagValue
                                    value={item.phoneNumber || '-'}
                                />
                                <DataRow
                                    label="Email"
                                    darkValue
                                    tagValue
                                    value={item.email || '-'}
                                />
                            </Stack>
                        )}
                        disabled={!!preview}
                        name="staff"
                        addButtonText="Add Staff"
                        disableReorder
                        customAdd={customStaffAdd}
                        disableEdit
                    />
                </>
            )}
            <StaffModal
                onConfirm={onStaffConfirm}
                onClose={onStaffClose}
                productId={event.product.id}
                existingEmails={existingEmails}
                availablePositions={allowedStaffPositions}
                position={centerModal ? 'center' : 'end'}
            />
            {(!preview || !!watch('assets')?.length) && canHaveAssets && (
                <>
                    <Divider>Assets</Divider>
                    <CardList
                        renderContent={({ item }) => (
                            <Stack sx={{ px: 2, py: 2 }} gap={1.5}>
                                <Tag
                                    sx={{ alignSelf: 'flex-start' }}
                                    value={capitalizeFirstLetter('vehicle')}
                                    valueProps={{
                                        sx: {
                                            py: 0.5,
                                            color: '#FFF',
                                            bgcolor: '#2B395B',
                                        },
                                    }}
                                />
                                <DataRow
                                    label="License Plate"
                                    darkValue
                                    value={item.licensePlate || '-'}
                                />
                                <DataRow
                                    label="Make/Model"
                                    darkValue
                                    tagValue
                                    value={`${item.make}/${item.model}`}
                                />
                                <DataRow
                                    label="Year"
                                    darkValue
                                    tagValue
                                    value={item.year || '-'}
                                />
                                <DataRow
                                    label="Max Passengers"
                                    darkValue
                                    tagValue
                                    value={item.maxPassengers || '-'}
                                />
                                <DataRow
                                    label="Fuel Type"
                                    darkValue
                                    tagValue
                                    value={
                                        item.fuelType
                                            ? t(item.fuelType, { ns: 'common' })
                                            : '-'
                                    }
                                />
                            </Stack>
                        )}
                        disabled={!!preview}
                        name="assets"
                        addButtonText="Add Asset"
                        disableReorder
                        customAdd={customAssetAdd}
                        disableEdit
                    />
                </>
            )}
            <AssetModal
                onConfirm={onAssetConfirm}
                onClose={onAssetClose}
                position={centerModal ? 'center' : 'end'}
                usedAssetIds={usedAssetIds}
            />
        </Box>
    );
};
