import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Stack } from '@mui/material';
import { EventProduct } from '@travelity/api';
import { SingleLineKeyValue } from '../booking-participants/components/single-line-key-value';

export interface EventProductFormProps {
    product: EventProduct;
    preview?: boolean;
}

const EventProductForm: React.FC<EventProductFormProps> = ({
    preview,
    product,
}) => {
    const { t } = useTranslation('product');
    const { name, type } = product;

    // @ts-ignore
    return preview ? (
        <Stack spacing={2}>
            <SingleLineKeyValue dark label="Type" value={t(type)} />
            <Divider />
            <SingleLineKeyValue dark label="Name" value={name} />
        </Stack>
    ) : (
        <Stack spacing={3} />
    );
};

export default React.memo(EventProductForm);
