import React from 'react';
import {
    Divider,
    Stack,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material';

export interface ListTitleProps {
    children?: React.ReactNode;
    title?: string;
    filters?: React.ReactNode;
}

export const ListTitle: React.FC<ListTitleProps> = React.memo(
    (props: ListTitleProps) => {
        const { children, title, filters } = props;
        const isMobile = useMediaQuery((theme: Theme) =>
            theme.breakpoints.down('sm')
        );

        return (
            <>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                    sx={{
                        py: 1.5,
                        height: '72px',
                    }}
                >
                    {title && (
                        <Typography variant="h5" sx={{ color: '#C0C4CE' }}>
                            {title}
                        </Typography>
                    )}

                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        justifyContent="flex-end"
                    >
                        {children}
                        {isMobile && filters}
                    </Stack>
                </Stack>
                {filters && !isMobile && (
                    <>
                        <Divider
                            sx={{
                                mb: 0.25,
                                display: { xs: 'none', sm: 'block' },
                            }}
                        />
                        {filters}
                    </>
                )}
            </>
        );
    }
);
