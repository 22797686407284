/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ContentType } from './ContentType';
import type { FileType } from './FileType';

export type GetUserResProfileAttachmentsItemReference0Dto = {
    /**
     * Type of the resource
     */
    resource_type: GetUserResProfileAttachmentsItemReference0Dto.resource_type;
    /**
     * Type of the file content
     */
    type: FileType;
    /**
     * Type of the file content
     */
    content_type: ContentType;
    /**
     * name of the file
     */
    name: string;
    /**
     * Details about all activities happening to the record
     */
    path: string;
    /**
     * Size of the file in bytes
     */
    size: number;
    /**
     * Checksum of the file
     */
    checksum: string;
    /**
     * Url to the image
     */
    url?: string;
    /**
     * id of the file record
     */
    id: string;
    /**
     * Width of the image in pixels
     */
    width: number;
    /**
     * Height of the image in pixels
     */
    height: number;
};

export namespace GetUserResProfileAttachmentsItemReference0Dto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        FILE = 'file',
    }


}
