export interface DirtyFields {
    [k: string]: boolean | boolean[] | DirtyFields | DirtyFields[] | undefined;
}

export const hasDirtyFields = (fields: DirtyFields): boolean => {
    return Object.values(fields).some(value => {
        if (Array.isArray(value)) {
            return value.some(v =>
                typeof value === 'object'
                    ? hasDirtyFields(v as DirtyFields)
                    : v === true
            );
        }
        if (typeof value === 'object') {
            return hasDirtyFields(value);
        }

        return value === true;
    });
};

export const isEmail = (value: string): boolean =>
    !!value.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/);
