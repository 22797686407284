import { object, string } from 'yup';
import { SourceType } from '@travelity/api';

const sourceSchema = object({
    sourceName: string().when(['sourceType'], ([sourceType], sch) => {
        return sourceType === SourceType.DIRECT
            ? sch.required('Booking Source is a required field')
            : sch;
    }),
    referral: object().when(['sourceType'], ([sourceType], sch) => {
        return sourceType === SourceType.REFERRAL
            ? sch.required('Booking Source is a required field')
            : sch;
    }),
});

export default sourceSchema;
