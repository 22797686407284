import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import CampaignIcon from '@mui/icons-material/Campaign';
import { feedback } from '../../sentry';

export const FeedbackWidgetButton = () => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        if (buttonRef.current) {
            return feedback.attachTo(buttonRef.current, {
                showBranding: false,
                formTitle: 'Report a Problem!',
                submitButtonLabel: 'Send Report',
                messagePlaceholder: "What's the problem? What did you expect?",
            });
        }
        return () => {};
    }, []);
    return (
        <Button
            ref={buttonRef}
            startIcon={<CampaignIcon />}
            variant="text"
            color="secondary"
            sx={{
                color: '#7682A4',
            }}
        >
            Report a Problem
        </Button>
    );
};
